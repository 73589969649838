import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WhatsappTemplateService {

  constructor(private http: HttpClient) { }

  getLeadsRocord(data:any): Observable<any> {
    return this.http.post(environment.getLeadsRocord,data);
  }

  getAllTemplate(data:any): Observable<any> {
    return this.http.post(environment.getAllWhatsappTemplates,data);
  }

  getAppId(data:any): Observable<any> {
    return this.http.post(environment.getAppId,data);
  }

  createTemplate(data:any): Observable<any> {
    return this.http.post(environment.createWhatsappTemplate,data);
  }

  uploadFile(file: File,clientId: any) {
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('client_id', clientId);
    const url= "https://okxvsyvxq1.execute-api.us-east-1.amazonaws.com/prod/uploadMedia"
    return this.http.post(url, formData);
  }


  getAllLanguages(): Observable<any> {
    return this.http.post(environment.getAllLanguages,{});
  }

  updateTemplate(data:any): Observable<any> {
    return this.http.post(environment.updateWhatsappTemplate,data);
  }

  deleteTemplate(data:any): Observable<any> {
    return this.http.post(environment.deleteWhatsappTemplate,data);
  }
  
}
