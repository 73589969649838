import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { ContactGrouopService } from 'src/app/private/services/contact-groups.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-create-contact-group',
  templateUrl: './create-contact-group.component.html',
  styleUrls: ['./create-contact-group.component.scss']
})
export class CreateContactGroupComponent {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  @Input() type: string = 'Create';
  @Input() templateDataToEdit: any;

  constructor(
    private toast: ToastrService,
    private api: ContactGrouopService,
    private localStorage: LocalStorageService,
    private userApi: UsersService,
  ) {

  }

  ngOnInit(): void {
    if (this.type === 'Edit') {
      console.log('Template Data', this.templateDataToEdit);
      this.group_name = this.templateDataToEdit.name;
      this.limit = this.templateDataToEdit.total_contacts;
    }
  }



  isSubmitted = false;

  btnLoading = false;

  group_name: string = '';
  limit: any ='';


  
  onSubmit() {

    this.isSubmitted = true;

    if (!this.group_name.trim()) {
      return;
    }

    this.btnLoading = true;
    const payload = {
      name: this.group_name,
      status: "Active",
      total_contacts:100,
      "client_id": this.localStorage.getClientId(),
    }


    if(this.type == 'Edit'){

      const payload2 = {
        id : this.templateDataToEdit.id,
        ...payload
      }

      this.api.updateContact_group(payload2).subscribe(
        (res) => {
          this.formSubmit.emit();
          this.isSubmitted = false;
        },
        (err) => {
          console.error(err);
          this.toast.error('Error creating contact group');
        }
      )

    }else{
      this.api.createContact_group(payload).subscribe(
        (res) => {
          this.formSubmit.emit();
          this.isSubmitted = false;
        },
        (err) => {
          console.error(err);
          this.toast.error('Error creating contact group');
        }
      )
    }
  }


  closeModal() {
    this.close.emit();
  }

}
