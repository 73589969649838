import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { WooCommerceService } from '../../services/woocommerce.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Form, NgForm } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-woocommerce-inventory',
  templateUrl: './woocommerce-inventory.component.html',
  styleUrls: ['./woocommerce-inventory.component.scss'],
  providers:[CurrencyPipe]
})
export class WoocommerceInventoryComponent {
  amount: string | number | null = '';
  isCategoryLoading:boolean = false;
  btnLoading:boolean = false
  productData: any = {
    name: '',
    type: 'simple', // Product type: simple, grouped, external, or variable
    regular_price: String(),
    sale_price: String(),
    description: '',
    short_description: '',
    categories: [],
    tags:[],
    images: [],
    status:"",
    stock_status: 'instock', // Stock status: instock, outofstock, or onbackorder
    manage_stock: false,
    stock_quantity: null,
  };

  displayedColumns: string[] = ['productImage', 'name', 'regular','sale', 'stock','categories','tags','date','status','action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  totalItems: number = 0;
  perPage: number = 10;
  @ViewChild("productForm")
  productForm!: NgForm;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  searchTerm: string = ''; // Dynamic search term
  categoryId: number | null = null; // Dynamic category filter
  paginator!: MatPaginator;
  filterArray:any = []
  categoryArray:any = []
  tagArray:any = []
  items:any = []
   // Selected Values
   selectedCategories: any[] = [];
   selectedCategoryIds: number[] = [];
 
   selectedTags: any[] = [];
   selectedTagIds: number[] = [];
  selectedCat:any = [];
  selectedTag:any = [];
  dropdownSettings = {};
  isSubmitted:boolean = false;
  formMode:any = "add";
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

  }
  
  constructor(private currencyPipe: CurrencyPipe,private api:WooCommerceService,
    private cdr: ChangeDetectorRef, 
    private modalService: NgbModal,
    private el: ElementRef,
    private toast:ToastrService
    
  ){
  
    
  }
  // Format the input as currency when the user leaves the input field
  formatCurrency() {
    if (this.amount) {
      this.amount = this.currencyPipe.transform(this.amount, 'USD', 'symbol', '1.2-2') || this.amount;
    }
  }

  // Remove formatting when the user focuses on the input field
  removeFormatting() {
    if (this.amount) {
      this.amount = this.amount.toString().replace(/[^0-9.-]+/g, ''); // Remove currency symbols and commas
    }
  }
  closeModal(){

  }
  ngOnInit(){
    this.loadProducts(1);
    this.loadOrders(1)
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }
  // Add product
  async saveAppointment(isValid:boolean) {

    this.isSubmitted = true;
    if(isValid)
    {
      this.productData.categories = this.selectedCategories 
      this.productData.tags = this.selectedTag 
      this.productData.regular_price = String(this.productData.regular_price);
      this.productData.sale_price = String(this.productData.sale_price);
      this.btnLoading = true;
      if(this.formMode == 'add'){
      this.api.addProduct(this.productData).subscribe({
        next:(res)=>{
          if(res) {
           console.log(res)
           this.btnLoading = false;
           this.toast.success("Product Added successfully")
           this.modalService.dismissAll();
          }

        },
        error:(e)=>{
          this.btnLoading = false;
        }
      });      
      } else {
        this.api.updateProductById(this.productData.id,this.productData).subscribe({
          next:(res)=>{
            if(res) {
             console.log(res)
             this.btnLoading = false;
             this.toast.success("Product Updated successfully")
             this.modalService.dismissAll();
            }
  
          },
          error:(e)=>{
            this.btnLoading = false;
          }
        });  
      }
      //console.log(this.productData)
    }
    else {
      this.scrollToFirstInvalidControl()
    }
  
  }
  openEdit(content:any, element:any){

  }
  openDelete(content:any, element:any){

  }
  searchFilter(object:any){

  }
  
  open(content:any){
    this.formMode = "add";
    this.loadCategories()
    this.loadTags()
    this.modalService
    .open(content, { size: 'xl', scrollable: true })
    .result.then(
      (result) => {
       
      },
      (reason) => {
        
      }
    );

  }
  dateFilter(filter:any){

  }
  loadCategories() {
    let dynamicParams = {
   
      per_page: 100,
    }
    this.api.getCategories(dynamicParams).subscribe({
      next: (response) => {
        this.categoryArray = response.body || [];
      },
      error: (error) => console.error('Error fetching products:', error),
    });
  }
  loadOrders(page:any) {
    let dynamicParams = {
   
      page,
      per_page: this.perPage,
    }
    this.api.getOrders(dynamicParams).subscribe({
      next: (response) => {
        this.categoryArray = response.body || [];
      },
      error: (error) => console.error('Error fetching products:', error),
    });
  }
  loadTags() {
    let dynamicParams = {
   
      per_page: 100,
    }
    this.api.getTags(dynamicParams).subscribe({
      next: (response) => {
        this.tagArray = response.body || [];
      },
      error: (error) => console.error('Error fetching products:', error),
    });
  }

  getProductById(content:any,row:any) {
    this.isCategoryLoading = true
    this.loadCategories()
    this.loadTags()
   
      if(row.categories.length > 0 ) {
        row.categories.forEach((element:any) => {
          this.selectedCategories.push({id:element.id,name:element.name})
        });

      }
      if(row.tags.length > 0 ) {
        row.tags.forEach((element:any) => {
          this.selectedTag.push({id:element.id,name:element.name})
        });
      }
    
    this.isCategoryLoading = false
    //console.log(this.selectedCategories);
    this.formMode = "update";
   //console.log(row)
    this.productData = row;
    this.modalService
    .open(content, { size: 'xl', scrollable: true })
    .result.then(
      (result) => {
       
      },
      (reason) => {
        
      }
    );
    // const dynamicParams:any = {
    //   id: row.id,
    // };

    // this.api.getProductById(dynamicParams).subscribe({
    //   next: (response) => {
    //     console.log(response)
    //     // this.dataSource.data = response.body || [];
           
    //   },
    //   error: (error) => console.error('Error fetching products:', error),
    // });
  }
  loadProducts(page: number): void {
    const dynamicParams:any = {
      page,
      per_page: this.perPage,
      // search: this.searchTerm || undefined, // Include search only if defined
      // category: this.categoryId || undefined, // Include category filter only if defined
    };

    this.api.getProducts(dynamicParams).subscribe({
      next: (response) => {
        this.dataSource.data = response.body || [];
        this.totalItems = parseInt(response.headers.get('x-wp-total'));
           

        console.log("total",this.totalItems)
      },
      error: (error) => console.error('Error fetching products:', error),
    });
  }

  /**
   * On paginator change
   */
  onPageChange(event: any): void {
    const newPageIndex = event.pageIndex + 1; // MatPaginator uses 0-based index
    
    this.loadProducts(newPageIndex);
  }

  /**
   * Apply filters (e.g., search or category)
   */
  applyFilters(): void {
    this.loadProducts(1);
  }
  getStockClass(stockStatus: string): string {
    switch (stockStatus.toLowerCase()) {
      case 'instock':
        return 'in-stock';
      case 'outofstock':
        return 'out-of-stock';
      case 'onbackorder':
        return 'on-backorder';
      default:
        return 'unknown-status';
    }
  }
  
}
