import { Component } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { map, timer } from 'rxjs';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { TimerService } from 'src/app/utils/timer.service';

@Component({
  selector: 'app-emr-detail',
  templateUrl: './campaign-management.component.html',
  styleUrls: ['./campaign-management.component.scss']
})
export class CampaignManagementComponent {
   startDate = new Date();
   timer:any;
   active = "patient_details"
   patient_id = 0;
   current_app_id = 0;
   currentAppointment:any;
   currentDashabord:any;
   patient: Patient = new Patient();
   constructor(public timerService: TimerService,public route:ActivatedRoute , private localstorage:LocalStorageService)
   {
      //this.timerService.startTimer();
   }
   ngOnInit(){
    
      this.route.params.subscribe(params =>
          {
            this.currentDashabord = this.route.snapshot.data['dashabord'] || "";
            console.log(this.currentDashabord)
        
            this.patient_id = params['id']
            this.current_app_id = params['app_id']
          })
        
          let param = {
            where:{
                  id:this.patient_id,
                  app_id:this.current_app_id,
                  client_id:this.localstorage.getClientId(),
                  center_id:this.localstorage.getCenterId(),
            }
          }
  }
 
}

export class Patient {
   id!: Number;
   pId:string = "";
   fname: string = "";
   lname: string = "";
   prefix: string = "";
   gender: string = "";
   blood_group:string = "";
   age: Number = 0;
   email: string = "";
   mobile: string = "";
   address: string = "";
   photo: string = "";
 }
