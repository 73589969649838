import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent {
  submitted = false;
  isLoading = false;
  token: any;
  decodedToken:any;
  buttonTitle = "Submit"
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;

  password = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
    Validators.pattern(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
    ),
  ]);
  confirmPassword = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
    Validators.pattern(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
    ),
  ]);
  resetPasswordForm = this.formBuilder.group(
    {
      password: this.password,
      confirmPassword: this.confirmPassword,
    },
    {
      validator: this.ConfirmedValidator('password', 'confirmPassword'),
    }
  );



  constructor(private toast:ToastrService,private coreAuth:AuthService, private route:ActivatedRoute,private router:Router, private formBuilder: FormBuilder)
  {
    this.token =  this.route.snapshot.paramMap.get('token');
    this.decodedToken = jwt_decode(this.token);

  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  get formError(): any {
    console.log(this.resetPasswordForm.controls)
    return this.resetPasswordForm.controls;
  }
  onSubmit()
  {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    let param = {
      password: this.resetPasswordForm.get("confirmPassword")?.value,
      user_id:this.decodedToken.user_id,
      token: this.token
    }
    this.isLoading = true;
    this.buttonTitle = "Loading"
    this.coreAuth.resetPassword(param).subscribe({
     next: (res) => {
 
       this.buttonTitle = "Submit"
       this.isLoading = false;
       this.toast.success(res.message,'Success');
       this.router.navigate(['login']);
     },
     error: (e) => {
       this.buttonTitle = "Submit"
       this.isLoading = false;
     },
     complete: () => {
       this.buttonTitle = "Submit"
       this.isLoading = false;
     }
 })
 
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPasswordVisibility() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

}
