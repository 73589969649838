import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TicketService } from 'src/app/private/services/ticket.service';
import { UploadService } from 'src/app/private/services/upload.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent {

  @Input() users: any[] = [];
  @Input() formAction: string = 'Create';
  @Input() selectedTicket: any;
  @Output() close = new EventEmitter<void>();
  @Output() refresh = new EventEmitter<void>();
  @Input() temp: any[] = [];
  @Input() currentTab: any;
  btnLoading: boolean = false;
  btnLoading2: boolean = false;
  loading = false;
  imgTitle = 'Upload Files';
  imgUpload = false;
  files: any[] = [];
  totalFiles: any[] = [];
  uploadedUrls: string[] = [];
  previewFiles: any[] = [];
  hoverIndex: number = -1;

  ticketStatuses: any = [

    { "key": "New", "value": "New" },
    { "key": "Open", "value": "Open" },
    { "key": "In Progress", "value": "In Progress" },
    { "key": "On Hold", "value": "On Hold" },
    { "key": "Escalated", "value": "Escalated" },
    { "key": "Resolved", "value": "Resolved" },
    { "key": "Closed", "value": "Closed" },
    { "key": "Cancelled", "value": "Cancelled" },
    { "key": "Draft", "value": "Draft" },
    { "key": "Reopened", "value": "Reopened" }
  ]

  constructor(
    private api: TicketService,
    private toast: ToastrService,
    private localStorage: LocalStorageService,
    private uploadService: UploadService,
  ) { }

  ngOnInit() {
    if (this.formAction === 'Update') {
      this.ticket = {
        assigned_to: this.selectedTicket.assign_to,
        title: this.selectedTicket.title,
        status: this.selectedTicket.ticket_status,
        description: this.selectedTicket.ticket_desc,
        source: this.selectedTicket.source,
        priority: this.selectedTicket.priority,
        // product_id: this.selectedTicket.product_id,
        // service_id: this.selectedTicket.service_id,
      };
      this.previewFiles = this.selectedTicket.attachments;

    } else if (this.formAction === 'Create') {
      this.ticket = {
        assigned_to: '',
        title: '',
        status: "New",
        description: '',
        source: this.currentTab,
        priority: '',
        // product_id: '',
        // service_id: '',
      };
    } else if (this.formAction === 'Update Bulk') {
      this.ticket = {
        assigned_to: '',
        status: '',
        description: '',
        source: this.currentTab,
        priority: '',
      };
    }
  }

  ticket: any = {

    assigned_to: '',
    title: '',
    status: '',
    description: '',
    source: '',
    priority: '',
  };



  products = [
    { id: 1, name: 'Product 1' },
    { id: 2, name: 'Product 2' },
  ];

  services = [
    { id: 1, name: 'Service 1' },
    { id: 2, name: 'Service 2' },
  ];

  isSubmitted = false;

  // Helper: Format File Size for Display
  formatSize(size: number): string {
    if (size < 1024) return `${size} B`;
    else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1048576).toFixed(2)} MB`;
  }

  submitForm() {

    if (this.formAction == 'Update Bulk') {
      this.handleSubmitBulk()
      return;
    }

    if (this.loading) {
      this.toast.error('Please wait for the files to upload');
      return;
    }

    this.isSubmitted = true;
    if (this.isFormValid()) {

      if (this.formAction == 'Create') {
        const payload = {
          title: this.ticket.title,
          assign_to: this.ticket.assigned_to || null,
          ticket_status: this.ticket.status,
          ticket_desc: this.ticket.description,
          source: this.ticket.source,
          priority: this.ticket.priority,

          center_id: this.localStorage.getCenterId(),
          client_id: this.localStorage.getClientId(),
          attachments: [
            ...this.uploadedUrls,
            ...this.previewFiles
          ],
          created_by: {
            user_id: this.localStorage.getUserId(),
            name: this.localStorage.getProfileName(),
            email: this.localStorage.getEmail()
          },
          // assignee_detail:{"assignee_id": "", "email":"", "name":""}
          assignee_detail: {
            assignee_id: this.ticket.assigned_to || "",
            email: this.users.find(user => user?.user_id === this.ticket.assigned_to)?.email || "",
            name: this.users.find(user => user?.user_id === this.ticket.assigned_to)
              ? `${this.users.find(user => user?.user_id === this.ticket.assigned_to)?.fname || ""} ${this.users.find(user => user?.user_id === this.ticket.assigned_to)?.lname || ""}`.trim()
              : ""
          }
        };

        this.btnLoading = true;
        this.api.addTicketData(payload).subscribe((res) => {
          console.log(res);
          this.btnLoading = false;
          this.closeModal();
          this.refresh.emit();
          this.toast.success('Ticket created successfully');

        }, (err) => {
          console.log(err);
          this.btnLoading = false;
        });
      } else {
        const payload = {
          id: this.selectedTicket.id,
          title: this.ticket.title,
          assign_to: this.ticket.assigned_to || null,
          ticket_status: this.ticket.status,
          ticket_desc: this.ticket.description,
          source: this.ticket.source,
          priority: this.ticket.priority,
          ticket_id: this.selectedTicket.ticket_id,
          center_id: this.localStorage.getCenterId(),
          client_id: this.localStorage.getClientId(),
          attachments: [
            ...this.uploadedUrls,
            ...this.previewFiles
          ],
          updated_by: {
            user_id: this.localStorage.getUserId(),
            name: this.localStorage.getProfileName(),
            email: this.localStorage.getEmail()
          },
          created_by: this.selectedTicket.created_by,
          assignee_detail: {
            assignee_id: this.ticket.assigned_to || "",
            email: this.users.find(user => user?.user_id === this.ticket.assigned_to)?.email || "",
            name: this.users.find(user => user?.user_id === this.ticket.assigned_to)
              ? `${this.users.find(user => user?.user_id === this.ticket.assigned_to)?.fname || ""} ${this.users.find(user => user?.user_id === this.ticket.assigned_to)?.lname || ""}`.trim()
              : ""
          }
        };

        this.btnLoading = true;
        this.api.updateTicket(payload).subscribe((res) => {
          console.log(res);
          this.btnLoading = false;
          this.closeModal();
          this.refresh.emit();
          this.toast.success('Ticket Updated successfully');

        }, (err) => {
          console.log(err);
          this.btnLoading = false;
        });
      }

    } else {
      console.log('Form is invalid');
    }
  }

  updateTicketList(isValid: any) {

    // temp can not more than 10

    if (this.temp?.length > 10) {
      this.toast.error("You can't update more than 10 tickets at a time", "Error!!");
      return;
    }


    this.isSubmitted = true
    if (isValid) {
      this.btnLoading = true;

      console.log("this.temp", this.temp)
      // console.log(this.temp)
      this.temp.map((data: any) => {
        // console.log("this.ticket.ticketStatus", this.ticket.ticketStatus);
        if (!(this.ticket.status === "")) {
          data.ticket_status = this.ticket.status;
        }
        // console.log("this.ticket.priority", this.ticket.priority);
        if (!(this.ticket.priority === "")) {
          // console.log("this.ticket.priority", this.ticket.priority);
          data.priority = this.ticket.priority;
        }
        if (this.ticket.assigned_to) {
          // console.log("this.ticket.assign_to.length", this.ticket.assign_to)
          data.assign_to = this.ticket.assigned_to;

          data.assignee_detail = {
            assignee_id: this.ticket.assigned_to || "",
            email: this.users.find(user => user?.user_id === this.ticket.assigned_to)?.email || "",
            name: this.users.find(user => user?.user_id === this.ticket.assigned_to)
              ? `${this.users.find(user => user?.user_id === this.ticket.assigned_to)?.fname || ""} ${this.users.find(user => user?.user_id === this.ticket.assigned_to)?.lname || ""}`.trim()
              : ""
          }
        }
        data.ticket_desc = this.ticket.description;
        data.updated_by = {
          user_id: this.localStorage.getUserId(),
          name: this.localStorage.getProfileName(),
          email: this.localStorage.getEmail()
        }

      })

      this.api.updateBulkTicket(this.temp).subscribe({
        next: (c: any) => {

          this.btnLoading = false;
          this.isSubmitted = false;
          this.temp = [];
          this.close.emit();
          this.refresh.emit();
          this.toast.success("Ticket Updated Successfully", "Success!!");
        },
        error: (e: any) => {
          this.btnLoading = false;
          this.isSubmitted = false
        },
        complete: () => { }
      })
    }
  }

  handleSubmitBulk() {

    if (this.isFormValid2()) {


      this.updateTicketList(true);

    } else {
      this.toast.error('Please fill all required fields');
    }

  }

  isFormValid2(): boolean {
    this.isSubmitted = true;

    // except title all required
    const requiredFields = ['status', 'description', 'source', 'priority'];
    return requiredFields.every((field) => this.ticket[field] !== '');
  }

  isFormValid(): boolean {
    // except assign to is not requir
    const requiredFields = ['title', 'status', 'description', 'source', 'priority'];

    // Check if all required fields are non-empty
    return requiredFields.every((field) => this.ticket[field] !== '');
  }

  closeModal() {
    this.close.emit();
  }

  onFileSelected(event: any) {
    const selectedFiles: FileList = event.target.files;
    const totalFiles = selectedFiles.length; // Track the number of files
    let processedFiles = 0; // Counter to track how many files have been processed

    if (totalFiles > 0) {
      this.files = []; // Clear the existing files
      for (let i = 0; i < totalFiles; i++) {
        const file = selectedFiles[i];
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.files.push({ file, preview: e.target.result }); // Store file and its preview
          this.totalFiles.push({
            file: file,
            preview: e.target.result
          });
          processedFiles++; // Increment the counter

          // Call uploadImages once all files are processed
          if (processedFiles === totalFiles) {
            this.uploadImages();
          }
        };

        reader.readAsDataURL(file); // Generate preview
      }
    } else {
      this.toast.error('No files selected for upload');
    }
  }

  uploadImages() {
    if (this.files.length > 0) {
      this.loading = true; // Set loading to true when uploading starts
      this.imgTitle = 'Uploading...';

      const uploadPromises = this.files.map((fileObj) => {
        const formData = new FormData();
        const folder = `public/hospital-001/$001/profile`;
        formData.append('file', fileObj.file);
        formData.append('folders', folder);
        formData.append('bucket', 'emruploads');

        return this.uploadService.uploadPatientProfile(formData).toPromise();
      });

      Promise.all(uploadPromises)
        .then((responses) => {
          responses.forEach((res: any) => {
            if (res) {
              this.uploadedUrls.push(res.url);
            }
          });
        })
        .catch((error) => {
          console.error('Error uploading images:', error);
        })
        .finally(() => {
          this.loading = false; // Set loading to false after all uploads are done
          this.imgTitle = 'Upload Images';
        });
    } else {
      this.toast.error('No files selected for upload');
    }
  }

  removePreviewFile(index: number) {
    this.previewFiles.splice(index, 1);
  }




  removeFile(index: number) {
    this.totalFiles.splice(index, 1);

  }

  isImage(file: string): boolean {
    return /\.(jpg|jpeg|png|gif|webp)$/i.test(file);
  }

  // Check if the file is a video
  isVideo(file: string): boolean {
    return /\.(mp4|webm|ogg|mov)$/i.test(file);
  }

  isPDF(file: string): boolean {
    return /\.(pdf)$/i.test(file);
  }

  // Get file name from the URL (optional, for display)
  getFileName(file: any): string {
    return file.split('/').pop() || 'Unknown File';
  }

  onAssignedToChange(event: any) {
    console.log('Assigned To Changed:', event);
    console.log('formAction:', this.formAction);
    console.log('Current Ticket Status:', this.ticket.status);

    if (
     ( this.formAction === 'Create' ||
      this.formAction === 'Update' 
     ) && 
      this.ticket.status === 'New'
    ) {
      this.ticket.status = 'Open';
      console.log('Ticket Status Updated:', this.ticket.status);
    }else if (
      this.formAction === 'Create' &&
      this.ticket.status === 'Open' && this.ticket.assigned_to === ''
    ) {
      this.ticket.status = 'New';
      console.log('Ticket Status Updated:', this.ticket.status);
    }
  }

}
