import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyComponent } from './component/body/body.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { ToolbarComponent } from './component/toolbar/toolbar.component';
import { HeaderComponent } from './component/header/header.component';
import { RouterModule } from '@angular/router';
import { CustomRangePanelComponent, DateFilterComponent } from './ui/date-filter/date-filter.component';
import { HomeComponent } from '../modules/home/home.component';
import { AppointmentComponent } from '../modules/appointment/appointment.component';
import { LeadComponent } from '../modules/lead/lead.component';
import { UsersComponent } from '../modules/configuration/users/users.component';
import { materialModules } from './module';
import { ScheduleComponent } from '../modules/configuration/schedule/schedule.component';
import { CampaignManagementComponent } from '../modules/components/campaign-management/campaign-management.component';
import { SubcriberComponent } from '../modules/components/subcriber/subcriber.component';
import { CampaignComponent } from '../modules/components/campaign/campaign.component';
import { EmailSettingsComponent } from '../modules/configuration/email-settings/email-settings.component';
import { CreateAppointmentComponent } from '../modules/components/create-appointment/create-appointment.component';
import { AppointmentDetailComponent } from '../modules/components/appointment-detail/appointment-detail.component';
import { LeadFormComponent } from 'src/app/public/lead-form/lead-form.component';
import { NotFoundComponent } from 'src/app/public/not-found/not-found.component';
import { LeadsComponent } from '../modules/configuration/leads/leads.component';
import { RegistrationComponent } from '../modules/configuration/registration/registration.component';
import { WhatsappComponent } from '../modules/configuration/whatsapp/whatsapp.component';
import { ContactsComponent } from '../modules/contacts/contacts.component';
import { UserLogsComponent } from '../modules/user-logs/user-logs.component';
import { ExcelUploadComponent } from '../modules/components/excel-upload/excel-upload.component';
import { CommunicationModelComponent } from 'src/app/private/modules/components/communication-model/communication-model.component';
import { RingoverCallComponent } from 'src/app/private/modules/components/ringover-call/ringover-call.component';
import { LeadEmailComponent } from 'src/app/private/modules/components/lead-email/lead-email.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommunicationLogsComponent } from 'src/app/private/modules/components/communication-logs/communication-logs.component';
import { SalesMailHubComponent } from 'src/app/private/modules/configuration/sales-mail-hub/sales-mail-hub.component';
import { MailLeftActionsComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-left-actions/mail-left-actions.component';
import { MailRightTableComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-right-table/mail-right-table.component';
import { MailMiddleSectionComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-middle-section/mail-middle-section.component';
import { MailRightSectionComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-right-section/mail-right-section.component';
import { TicketsComponent } from 'src/app/private/modules/tickets/tickets.component';
import { CreateTicketComponent } from 'src/app/private/modules/components/ticket-module/create-ticket/create-ticket.component';
import { AddCommentComponent } from 'src/app/private/modules/components/ticket-module/add-comment/add-comment.component';
import { AddContactsGroupComponent } from '../modules/components/add-contacts-group/add-contacts-group.component';
import { ContactGroupListComponent } from '../modules/components/contact-group-list/contact-group-list.component';
import { CreataeWhatsappTemplateComponent } from '../modules/components/creatae-whatsapp-template/creatae-whatsapp-template.component';
import { CreateBroadcastCampaignComponent } from '../modules/components/create-broadcast-campaign/create-broadcast-campaign.component';
import { CreateContactGroupComponent } from '../modules/components/create-contact-group/create-contact-group.component';
import { CreateEmailTemplateComponent } from '../modules/components/create-email-template/create-email-template.component';
import { CreateEventComponent } from '../modules/components/create-event/create-event.component';
import { CsvDropzoneComponent } from '../modules/components/csv-dropzone/csv-dropzone.component';
import { PreviewEmailTemplateComponent } from '../modules/components/preview-email-template/preview-email-template.component';
import { PreviewWhatsappTemplateComponent } from '../modules/components/preview-whatsapp-template/preview-whatsapp-template.component';
import { RingOverComponent } from '../modules/components/ring-over/ring-over.component';
import { BroadcastCampaignComponent } from '../modules/configuration/broadcast-campaign/broadcast-campaign.component';
import { ContactGroupsComponent } from '../modules/configuration/contact-groups/contact-groups.component';
import { EmailTemplateComponent } from '../modules/configuration/email-template/email-template.component';
import { WhatsappTemplateComponent } from '../modules/configuration/whatsapp-template/whatsapp-template.component';
import { EventsComponent } from '../modules/events/events.component';
import { WoocommerceInventoryComponent } from '../modules/woocommerce-inventory/woocommerce-inventory.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ManageWooOrdersComponent } from '../modules/manage-woo-orders/manage-woo-orders.component';
import { EmailEditorModule } from 'angular-email-editor';
import { MatTooltip } from '@angular/material/tooltip';
import { SafeUrlPipe } from 'src/app/safe-url.pipe';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignatureModelComponent } from 'src/app/private/modules/components/signature-model/signature-model.component';
import { PreviewSignatureModelComponent } from 'src/app/private/modules/components/preview-signature-model/preview-signature-model.component';
import { CompanyComponent } from 'src/app/private/modules/configuration/company/company.component';
import { CreateCompanyComponent } from 'src/app/private/modules/components/company/create-company/create-company.component';
import { DealsComponent } from 'src/app/private/modules/deals/deals.component';
import { CreateDealsComponent } from 'src/app/private/modules/components/deals/create-deals/create-deals.component';
import { AddContactComponent } from 'src/app/private/modules/components/add-contact/add-contact.component';
import { AutoCompleteSearchComponent } from 'src/app/private/modules/components/auto-complete-search/auto-complete-search.component';
import { ConvertToAccountComponent } from 'src/app/private/modules/components/convert-to-account/convert-to-account.component';
import { AutoCompleteContactSearchComponent } from 'src/app/private/modules/components/auto-complete-contact-search/auto-complete-contact-search.component';
import { PipelineComponent } from 'src/app/private/modules/configuration/pipeline/pipeline.component';
import { CreateNewPipelineComponent } from 'src/app/private/modules/components/create-new-pipeline/create-new-pipeline.component';
import { AddStageComponent } from 'src/app/private/modules/components/add-stage/add-stage.component';
import { ContentInfoComponent } from 'src/app/private/modules/components/content-info/content-info.component';
import { ClickOutsideDirective } from 'src/app/click-outside.directive';
import { OutsideHoverDirective } from 'src/app/outside-hover.directive';


import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { KanbanBoardComponent } from '../modules/components/kanban-board/kanban-board.component';
import { DealsKanbanDashboardComponent } from 'src/app/private/modules/components/kanban-boards/deals-kanban-dashboard/deals-kanban-dashboard.component';
import { DropZoneForLeadsComponent } from 'src/app/private/modules/components/drop-zone-for-leads/drop-zone-for-leads.component';
import { ColumnInfoForLeadComponent } from 'src/app/private/modules/components/column-info-for-lead/column-info-for-lead.component';
import { ProductsComponent } from 'src/app/private/modules/products/products.component';
import { CreateProductComponent } from 'src/app/private/modules/components/product/create-product/create-product.component';
import { ProductInfoComponent } from 'src/app/private/modules/components/product-info/product-info.component';
import { CategoryComponent } from 'src/app/private/modules/configuration/category/category.component';
import { CreateCategoryComponent } from 'src/app/private/modules/components/category/create-category/create-category.component';
@NgModule({
  declarations: [
    HeaderComponent,
    BodyComponent,
    SafeUrlPipe,
    SidebarComponent,
    ToolbarComponent,
    DateFilterComponent,
    CustomRangePanelComponent,
    HomeComponent,
    LeadComponent,
    UsersComponent,
    CampaignManagementComponent,
    EmailSettingsComponent,
    SubcriberComponent,
    CampaignComponent,
    ScheduleComponent,
    AppointmentComponent,
    CreateAppointmentComponent,
    AppointmentDetailComponent,
    LeadsComponent,
    RegistrationComponent,
    LeadFormComponent,
    NotFoundComponent,
    WhatsappComponent,
    ContactsComponent,
    UserLogsComponent,
    ExcelUploadComponent,
    CommunicationModelComponent,
    RingoverCallComponent,
    LeadEmailComponent,
    CommunicationLogsComponent,
    SalesMailHubComponent,
    MailLeftActionsComponent,
    MailRightTableComponent,
    MailMiddleSectionComponent,
    MailRightSectionComponent,
    TicketsComponent,
    CreateTicketComponent,
    AddCommentComponent,
    WhatsappTemplateComponent,
    CreataeWhatsappTemplateComponent,
    EventsComponent,
    CreateEventComponent,
    EmailTemplateComponent,
    CreateEmailTemplateComponent,
    ContactGroupsComponent,
    CreateContactGroupComponent,
    AddContactsGroupComponent,
    BroadcastCampaignComponent,
    CreateBroadcastCampaignComponent,
    ContactGroupListComponent,
    CsvDropzoneComponent,
    PreviewWhatsappTemplateComponent,
    RingOverComponent,
    PreviewEmailTemplateComponent,
    WoocommerceInventoryComponent,
    ManageWooOrdersComponent,
    SignatureModelComponent,
    PreviewSignatureModelComponent,
    CompanyComponent,
    CreateCompanyComponent,
    DealsComponent,
    CreateDealsComponent,
    AddContactComponent,
    AutoCompleteSearchComponent,
    ConvertToAccountComponent,
    AutoCompleteContactSearchComponent,
    PipelineComponent,
    CreateNewPipelineComponent,
    AddStageComponent,
    ContentInfoComponent,
    KanbanBoardComponent,
    DealsKanbanDashboardComponent,
    DropZoneForLeadsComponent,
    ColumnInfoForLeadComponent,
    ProductsComponent,
    CreateProductComponent,
    ProductInfoComponent,
    CategoryComponent,
    CreateCategoryComponent
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    DragDropModule,
    RouterModule, 
    NgxIntlTelInputModule,
    CKEditorModule,
    EmailEditorModule,
    NgxDropzoneModule,
    ClickOutsideDirective,
    OutsideHoverDirective,
    ...materialModules,
   
    



  ],
  
  schemas: [NO_ERRORS_SCHEMA],
  exports:[
    HeaderComponent,
    BodyComponent,
    SidebarComponent,
    ToolbarComponent,
    ClickOutsideDirective,
    OutsideHoverDirective
  ]
  
})
export class SharedModule { }
