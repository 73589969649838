import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanActivateFn, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from './LocalStorageService';
import { AuthService } from '../public/services/auth.service';
import { JWTTokenServiceService } from '../public/services/token.service';


Injectable({
  providedIn: 'root'
})
// export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown> {


//   constructor( private authService:LocalStorageService,private router: Router) { }
//   canDeactivate(component: unknown, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot | undefined): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
//     throw new Error('Method not implemented.');
//   }
//   canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
//     throw new Error('Method not implemented.');
//   }

//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): boolean | Promise<boolean> {
//       let routeTitle:any = route.routeConfig;
//       console.log(routeTitle)
//       var isAuthenticated = this.authService.getDashboardAccess(routeTitle.title);
//       console.log(isAuthenticated)
//       if(this.authService.isLoggedIn())
//       {

//         if (!isAuthenticated) {
//         //  console.log("dsdfs"+isAuthenticated)
//           this.router.navigate(['/dashboard/access-restricted']);
//         }
//       }
//       else
//       {
//         this.router.navigate(['/login']);
//       }


//     return isAuthenticated;
// }

// }

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  const router: Router = inject(Router);
  var tokenService: JWTTokenServiceService = inject(JWTTokenServiceService);

  const localStorageService: LocalStorageService = inject(LocalStorageService);

  const routeName = route.routeConfig?.path || '';

  // Inline access control configuration
  const routeAccessConfig: { [key: string]: { feature: string; access: string, featureAccess?: string } } = {
    'contacts': { feature: 'contacts', access: 'contacts' },
    'leads': { feature: 'lead', access: 'lead' },
    'account': { feature: 'account', access: 'account' },
    'deals': { feature: 'deals', access: 'deals' },
    'broadcast-campaigns': { feature: 'campaign', access: 'campaign' },
    'sales-mail-hub': { feature: 'sales-mail-hub', access: 'sales-mail-hub' },
    'manage-tickets': { feature: 'tickets', access: 'tickets' },
    'events': { feature: 'event', access: 'event' },
    'user-logs': { feature: 'user_log', access: 'user_log' },
    'woocommerce-inventory': { feature: 'woocommerce', access: 'woocommerce' },
    'woocommerce-orders': { feature: 'woocommerce', access: 'woocommerce' },
    'configuration-leads': { feature: 'setting', access: 'setting', featureAccess: 'leads_field_setting' },
    'configuration-users' : { feature: 'setting', access: 'setting', featureAccess: 'user' },
    'configuration-email' : { feature: 'setting', access: 'setting', featureAccess: 'email_setting' },
    'configuration-whatsapp-template' : { feature: 'setting', access: 'setting', featureAccess: 'whatsapp_template' },
    'configuration-email-template' : { feature: 'setting', access: 'setting', featureAccess: 'email_template' },
    'contact-groups' : { feature: 'setting', access: 'setting', featureAccess: 'contact_group' },
    'pipeline' : { feature: 'setting', access: 'setting', featureAccess: 'pipeline' },
  };

  const safeParse = (value: string | null): any[] => {
    try {
      return JSON.parse(value || '[]');
    } catch (error) {
      console.error('Failed to parse JSON:', error, value);
      return [];
    }
  };

  // Get access details from local storage
  const permissions = safeParse(localStorageService.getPermissions());
  const modules = localStorageService.getFeatureModules();

  const getFeatureModuleAccess = (moduleName: string): boolean => {

    const clientId = localStorageService.getClientId();

    if (clientId == 70 && moduleName == 'sales-mail-hub') {
      return true
    }

    const result = modules.includes(moduleName);
    return result; // Return true if found
  };

  const getAccess = (moduleName: string): boolean => {

    const clientId = localStorageService.getClientId();

    if (clientId == 70 && moduleName == 'sales-mail-hub') {
      return true
    }

    const result = permissions.find(
      (item: any) => item.module_name === moduleName && item.active === true
    );
    return !!result; // Return true if found
  };

  const getfeatureAccess = (module_name: any, feature_name: any) => {
    //console.log(this.permissions)
    let feature = [];
    let module = [];
    module = permissions.filter((item: any) => {
      return item.module_name == module_name;
    })
    if (module.length > 0) {
      feature = module[0]['features'].filter((item: any) => {
        return item.name == feature_name && item.active == true;
      })
    }

    return (module[0] && feature[0]) ? true : false;

  }

  console.log('AuthGuard:', routeName);


  if (!tokenService.isTokenExpired() && tokenService.getToken()) {

    if (routeName === 'login') {
      router.navigate(['leads']); // Redirect to the dashboard or another route
      return false;
    }

    const accessConfig = routeAccessConfig[routeName];
    if (accessConfig) {
      const hasAccess =
        getFeatureModuleAccess(accessConfig.feature) && getAccess(accessConfig.access);

      let haseFeatureAccess = true;
      if (accessConfig.featureAccess) {
        haseFeatureAccess = getfeatureAccess(accessConfig.feature, accessConfig.featureAccess);
      }
      if (hasAccess && haseFeatureAccess) {
        return true;
      }
      router.navigate(['unauthorized']); // Redirect if access is denied
      return false;
    }
    return true; // Allow access if route doesn't have specific access control
  }
  router.navigate(['login'])
  return false;
}