import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { MainComponent } from './private/layout/main/main.component';
import { HomeComponent } from './private/modules/home/home.component';
import { ForgetComponent } from './public/forget/forget.component';
import { ResetComponent } from './public/reset/reset.component';
import { LeadComponent } from './private/modules/lead/lead.component';
import { UsersComponent } from './private/modules/configuration/users/users.component';
import { AuthGuard } from './utils/auth.guard';
import { ScheduleComponent } from './private/modules/configuration/schedule/schedule.component';
import { EmailSettingsComponent } from './private/modules/configuration/email-settings/email-settings.component';
import { CampaignManagementComponent } from './private/modules/components/campaign-management/campaign-management.component';
import { LeadsComponent } from './private/modules/configuration/leads/leads.component';
import { RegistrationComponent } from './private/modules/configuration/registration/registration.component';
import { LeadFormComponent } from './public/lead-form/lead-form.component';
import { NotFoundComponent } from './public/not-found/not-found.component';
import { WhatsappComponent } from './private/modules/configuration/whatsapp/whatsapp.component';
import { ContactsComponent } from './private/modules/contacts/contacts.component';
import { UserLogsComponent } from './private/modules/user-logs/user-logs.component';
import { EventsComponent } from './private/modules/events/events.component';
import { WhatsappTemplateComponent } from './private/modules/configuration/whatsapp-template/whatsapp-template.component';
import { EmailTemplateComponent } from './private/modules/configuration/email-template/email-template.component';
import { ContactGroupsComponent } from './private/modules/configuration/contact-groups/contact-groups.component';
import { BroadcastCampaignComponent } from './private/modules/configuration/broadcast-campaign/broadcast-campaign.component';
import { RingOverComponent } from 'src/app/private/modules/components/ring-over/ring-over.component';
import { SalesMailHubComponent } from 'src/app/private/modules/configuration/sales-mail-hub/sales-mail-hub.component';
import { TicketsComponent } from 'src/app/private/modules/tickets/tickets.component';
import { WoocommerceInventoryComponent } from './private/modules/woocommerce-inventory/woocommerce-inventory.component';
import { ManageWooOrdersComponent } from './private/modules/manage-woo-orders/manage-woo-orders.component';
import { CompanyComponent } from 'src/app/private/modules/configuration/company/company.component';
import { DealsComponent } from 'src/app/private/modules/deals/deals.component';
import { PipelineComponent } from 'src/app/private/modules/configuration/pipeline/pipeline.component';
import { PublicGuard } from 'src/app/utils/public.guard';
import { ProductsComponent } from 'src/app/private/modules/products/products.component';
import { CategoryComponent } from 'src/app/private/modules/configuration/category/category.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: "full",
    title: "TINA"
  },

  {
    path: 'login',
    component: LoginComponent,
    title: "TINA",
    canActivate: [PublicGuard]
  },
  {
    path: 'forget-password',
    component: ForgetComponent,
    title: "Forget Password",
    canActivate: [PublicGuard]
  },
  {
    path: 'reset-password/:type/:token',
    component: ResetComponent,
    title: "Reset Password",
    canActivate: [PublicGuard]
  },
  {
    path: 'capture-leads/:client/:center',
    component: LeadFormComponent,
    title: "TINA"
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [{
      path: "",
      redirectTo: 'leads',
      pathMatch: 'full' // Ensures an exact match to redirect
    }, {
      path: 'home',
      component: HomeComponent,
      title: "Home",
      data: {
        title: "Analytics Dashboard"
      },
      canActivate: [AuthGuard]
    }, {
      path: 'leads',
      component: LeadComponent,
      canActivate: [AuthGuard],
      title: "Manage Leads",
      data: {
        title: "Manage Leads"
      }
    },
    // events
    {
      path: 'events',
      component:EventsComponent,
      canActivate: [AuthGuard],
      title: "Events",
      data: {
        title: "Manage Events"
      }
    },
    {
      path: 'whatsapp-campaigns',
      component: WhatsappComponent,
      canActivate: [AuthGuard],
      title: "WhatsApp Campaigns",
      data: {
        title: "WhatsApp Campaigns"
      }
    },
    {
      path: 'contacts',
      component: ContactsComponent,
      canActivate: [AuthGuard],
      title: "Contacts",
      data: {
        title: "Contacts"
      }
    },
    {
      path: 'products',
      component: ProductsComponent,
      canActivate: [AuthGuard],
      title: "Products Management",
      data: {
        title: "Products Management"
      }
    },
    {
      path: 'configuration-users',
      component: UsersComponent,
      title: "Users",
      canActivate: [AuthGuard],
      data: {
        title: "Manage Users"
      }
    },
    {
      path: 'campaign',
      component: CampaignManagementComponent,
      title: "Campaign",
      data: {
        title: "Campaign Management"
      }
    },
    {
      path: 'user-logs',
      component: UserLogsComponent,
      title: "User Logs",
      data: {
        title: "User Logs"
      }
    },
    {
      path: 'configuration-email',
      component: EmailSettingsComponent,
      canActivate: [AuthGuard],
      title: "Email",
      data: {
        title: "Email Settings"
      }
    },
    {
      path: 'configuration-schedule',
      component: ScheduleComponent,
      canActivate: [AuthGuard],
      title: "Schedule",
      data: {
        title: "Schedule Settings"
      }
    },
    {
      path: 'configuration-leads',
      component: LeadsComponent,
      canActivate: [AuthGuard],
      title: "Leads",
      data: {
        title: "Leads Settings"
      },
    }, 
    {
      path: 'configuration-whatsapp-template',
      component: WhatsappTemplateComponent,
      canActivate: [AuthGuard],
      title: "WhatsApp Template",
      data: {
        title: "WhatsApp Template Settings"
      },
    }, {
      path: 'configuration-email-template',
      component: EmailTemplateComponent,
      canActivate: [AuthGuard],
      title: "WhatsApp Template",
      data: {
        title: "WhatsApp Template Settings"
      },
    },
    {
      path: 'contact-groups',
      component: ContactGroupsComponent,
      canActivate: [AuthGuard],
      title: "Contact Groups",
      data: {
        title: "Contact Groups"
      },
    },
    {
      path: 'pipeline',
      component: PipelineComponent,
      canActivate: [AuthGuard],
      title: "Pipeline",
      data: {
        title: "Pipeline"
      },
    },
    {
      path:'category',
      component: CategoryComponent,
      canActivate: [AuthGuard],
      title: "Category",
      data: {
        title: "Category"
      },
    },
    {
      path: 'account',
      component: CompanyComponent,
      canActivate: [AuthGuard],
      title: "Account",
      data: {
        title: "Account"
      },
    },
    {
      path: 'ringover',
      component: RingOverComponent,
      canActivate: [AuthGuard],
      title: "Ring Over",
      data: {
        title: "Ring Over"
      },
    },
    {
      path: 'broadcast-campaigns',
      component: BroadcastCampaignComponent,
      canActivate: [AuthGuard],
      title: "Broadcast Campaigns",
      data: {
        title: "Broadcast Campaigns"
      },
    },
    {
      path: 'sales-mail-hub',
      component: SalesMailHubComponent,
      canActivate: [AuthGuard],
      title: "Sales Mail Hub",
      data: {
        title: "Sales Mail Hub"
      },
    },
    {
      path: 'deals',
      component: DealsComponent,
      canActivate: [AuthGuard],
      title: "Deals",
      data: {
        title: "Deals"
      },
    },
    {
      path: 'manage-tickets',
      component: TicketsComponent,
      canActivate: [AuthGuard],
      title: "Manage Tickets",
      data: {
        title: "Manage Tickets"
      },
    },
    {
      path: 'configuration-registration',
      component: RegistrationComponent,
      canActivate: [AuthGuard],
      title: "Registrations",
      data: {
        title: "Registrations Settings"
      },
    },
    {
      path: 'woocommerce-inventory',
      component: WoocommerceInventoryComponent,
      canActivate: [AuthGuard],
      title: "Woocommerce Inventory",
      data: {
        title: "Woocommerce Inventory"
      },
    },
    {
      path: 'woocommerce-orders',
      component: ManageWooOrdersComponent,
      canActivate: [AuthGuard],
      title: "Woocommerce Orders",
      data: {
        title: "Woocommerce Orders"
      },
    },
    {
      path: 'appointments',
      loadChildren: () => import('./private/modules/appointment/appointment.module').then(m => m.AppointmentModule),
      canActivate: [AuthGuard],
      title: "Appointments",
      data: {
        title: "Manage Appointments"
      }
    },
    ]

  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
