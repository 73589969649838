import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'src/app/private/services/event.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {

  @Output() close:EventEmitter<any> = new EventEmitter();
  @Input() formAction: string = 'Add';
  @Input() updateData: any;

  event: any = {
    event_name: '',
    client_id: '',
    event_description: '',
    location: '',
    event_url: '',
    start_date: '',
    end_date: '',
    speakers: [
      {
        name: '',
        img_url: '',
        link:'',
        description: '',
        company:'',
        experience:'',
        topic:'',
      }
    ],
    sponsers: [
      {
        title: '',
        img_url: '',
        link_url: '',
        description: ''
      }
    ],
    attachment_url: '',
    social_media: []
  };
  
  socialMediaInvalid = false;
  clientList: any[] = []; // Populate this list with your client data
  btnLoading = false;
  isSubmitted = false;

  constructor(
    private toast: ToastrService,
    private api: EventService,
    private localStorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    // Fetch initial data if needed, like clientList
    if (this.formAction === 'Update') {
      this.event = this.updateData;

      // update envet start_date and end_date to date format

      this.event.start_date = new Date(this.event.start_date).toISOString().split('T')[0];
      this.event.end_date = new Date(this.event.end_date).toISOString().split('T')[0];

    }
  }

  addSpeaker() {
    this.event.speakers.push({ name: '', img_url: '', description: '' });
    // const lastSpeaker = this.event.speakers[this.event.speakers.length - 1];
    // if (lastSpeaker.name && lastSpeaker.img_url && lastSpeaker.description) {
    //   // If valid, add a new speaker
    //   this.event.speakers.push({ name: '', img_url: '', description: '' });
    // } else {
    //   // Show an alert or handle the error in a user-friendly way toast

    //     this.toast.error('Please fill the current speaker details before adding a new one');
    // }
  }

  addSponsor() {
    this.event.sponsers.push({ name: '', img_url: '', description: '' });
    // console.log("hello 123");
    // const lastSponsor = this.event.sponsers[this.event.sponsers.length - 1];
    // if (lastSponsor.name && lastSponsor.img_url && lastSponsor.description) {
    //   // If valid, add a new sponsor
    //   this.event.sponsers.push({ name: '', img_url: '', description: '' });
    // } else {
    //  this.toast.error('Please fill the current sponsor details before adding a new one');
    // }
  }

  saveEvent(isValid: boolean) {
    this.isSubmitted = true;
    this.socialMediaInvalid = this.event?.social_media?.some((social:any) => !/^https?:\/\/.+/.test(social));
    if (!isValid || !this.validateEventURL() || this.socialMediaInvalid) {
      console.log("is valid",isValid);
      console.log("is valid",this.validateEventURL());
      console.log("is valid",this.socialMediaInvalid);
      console.log('Invalid form data');
      this.toast.error('Please fill all required fields');
      return;
    }

    this.btnLoading = true;
    this.event.client_id = this.localStorage.getClientId();
    console.log('Event data:', this.event);

    
    if(
      this.formAction === 'Update'
    ){

      this.api.updateEvent(this.event).subscribe(
        (response:any) => {
          console.log('API response:', response);
          this.toast.success('Event updated successfully!');
          this.closeModal();
        },
        (error:any) => {
          console.error('API error:', error);
          this.toast.error('Failed to update event.');
        }
      )
      .add(() => {
        this.btnLoading = false;
      });

    }else{
      this.api.createEvent(this.event).subscribe(
        (response:any) => {
          console.log('API response:', response);
          this.toast.success('Event saved successfully!');
          this.closeModal();
        },
        (error:any) => {
          console.error('API error:', error);
          this.toast.error('Failed to save event.');
        }
      )
      .add(() => {
        this.btnLoading = false;
      });
    }
  }

  validateEventURL(): boolean {
    const urlPattern = new RegExp('https?://.+');
    if (!urlPattern.test(this.event.event_url)) {
      this.toast.error('Invalid URL format');
      return false;
    }
    return true;
  }
  closeModal() {
    this.close.emit();
  }

  addSocialMedia() {

    // check if the last social media input is not empty

    if (this.event.social_media[this.event.social_media.length - 1] !== '') {
      this.event.social_media.push('');
    }else{
      this.toast.error('Please fill the current social media details before adding a new one');
    }
  }

  removeSocialMedia(index: number) {
    if (this.event.social_media.length > 1) {
      this.event.social_media.splice(index, 1);
    }
  }

  removeSponser(index: number) {
    if (this.event.sponsers.length > 1) {
      this.event.sponsers.splice(index, 1);
    }
  }

  removeSpeaker(index: number) {
    if (this.event.speakers.length > 1) {
      this.event.speakers.splice(index, 1);
    }
  }
}
