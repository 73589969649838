import { CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';


export const PublicGuard: CanActivateFn = () => {
  const router: Router = inject(Router);
  const tokenService: JWTTokenServiceService = inject(JWTTokenServiceService);

  if (tokenService.getToken() && !tokenService.isTokenExpired()) {
    router.navigate(['main/leads']); // Redirect to the main route if the user is logged in
    return false;
  }
  return true; // Allow access if the user is not logged in
};
