// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../node_modules/ckeditor5/dist/ckeditor5.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../node_modules/ckeditor5-premium-features/dist/ckeditor5-premium-features.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* app.component.css */\n.ck.ck-editor__editable_inline > :last-chil {\n  min-height: 183px !important;\n}\n\n.ck.ck-editor__editable_inline {\n  min-height: 183px !important;\n}\n\n.ck-editor__editable_inline {\n  min-height: 183px !important;\n}", "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA,sBAAA;AAKA;EACI,4BAAA;AADJ;;AAGA;EACI,4BAAA;AAAJ;;AAGA;EACI,4BAAA;AAAJ","sourcesContent":["/* app.component.css */\r\n\r\n@import 'ckeditor5/ckeditor5.css';\r\n@import 'ckeditor5-premium-features/ckeditor5-premium-features.css';\r\n\r\n.ck.ck-editor__editable_inline>:last-chil{\r\n    min-height: 183px !important;\r\n}\r\n.ck.ck-editor__editable_inline{\r\n    min-height: 183px !important;\r\n}\r\n\r\n.ck-editor__editable_inline{\r\n    min-height: 183px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
