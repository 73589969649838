import { Component } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LeadService } from 'src/app/private/services/lead.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss'],
})
export class LeadsComponent {
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  leadsFeild: any = new LeadVal();
  formAction = 'add';
  btnLoading: boolean = false;
  isSubmitted: boolean = false;
  isSubFieldEditMode: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  filterArray: any = [];
  deleteParam: any = {};
  subFieldArray: any = [];
  uniqueNameContainer: any = [];
  uniqueName : any = '';
  isUniquefname: boolean = true;
  deleteSubFieldParam: any = null;
  displayedColumns: string[] = [
    'sequence',
    'fname',
    'ftype',
    'label',
    'minlen',
    'maxlen',
    'placeholder',
    'required',
    'isEmail',
    'action',
  ];
  showFullPath: any = {
    path: 'Configuration',
    subPath: 'Lead Fields',
  };
  editLeadsValue: any = new LeadVal();
  editsubfield: any = new LeadVal();
  selectedFieldForSub: any = {};
  initialLoad: boolean = true;

  constructor(
    private toast: ToastrService,
    private api: LeadService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getLeadsFields();
  }

  getLeadsFields() {
    // id = this.localStorage.getClientId()
    // test mode on
    let param = {
      client_id: this.localStorage.getClientId(),
    };
  
    this.api.getLeadsFields(param).subscribe(
      (data: any) => {
        this.initialLoad = false;
        this.dataSource = new MatTableDataSource<any>(data.fields);
        this.filterArray = [
          { key: 'field_name', value: 'Field Name' },
          { key: 'label', value: 'Label' },
          { key: 'type', value: 'Type' },
          { key: 'min-length', value: 'Min Length' },
          { key: 'max-length', value: 'Max Length' },
          { key: 'placeholder', value: 'Placeholder' },
          { key: 'required', value: 'Required' },
        ];
      },
      (error: any) => {
        this.initialLoad = false;
        this.toast.error('An error occurred');
      }
    );
  }
  

  searchFilter(query: any) {}

  createUniqueNameContainer() {
    this.uniqueNameContainer = [];
    this.dataSource.filteredData.forEach((element: any) => {
      this.uniqueNameContainer.push(element.field_name);
      if (element.sub_fields && element.sub_fields.length > 0) {
        element.sub_fields.forEach((sub: any) => {
          this.uniqueNameContainer.push(sub.field_name);
        });
      }
    });
    this.subFieldArray.forEach((element: any) => {
      this.uniqueNameContainer.push(element.field_name);
    });
  }

  editForm(content: any, element: any) {
    this.createUniqueNameContainer();
    this.editLeadsValue = element;
    this.uniqueName = element.field_name;
    this.formAction = 'edit';
    this.isUniquefname = true;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  editSubField(content: any, element: any) {
    this.createUniqueNameContainer();
    this.resetValues();
    this.uniqueName = element.field_name;
    this.isSubmitted = false;
    this.isUniquefname = true;
    this.subFieldArray = [];
    this.editsubfield = new LeadVal();
    this.selectedFieldForSub = element;
    if (element.sub_fields && element.sub_fields.length > 0) {
      this.subFieldArray = element.sub_fields;
    }
    this.modalService.open(content, {
      size: 'fullscreen',
      scrollable: true,
      centered: true,
    });
  }

  resetValues() {
    this.editLeadsValue = new LeadVal();
    this.leadsFeild = new LeadVal();
    this.selectedFieldForSub = {};
  }

  deleteFieldModal(content: any, element: any) {
    let param = {
      client_id: this.localStorage.getClientId(),
      field_id: element.field_id,
    };
    this.deleteParam = param;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  deleteSubFieldConfirm(content: any, element: any) {
    this.deleteSubFieldParam = element;
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }
  open(content: any) {
    this.resetValues();
    this.createUniqueNameContainer();
    this.modalService.open(content, {
      size: 'l',
      scrollable: true,
      centered: true,
    });
  }

  SaveLead(isValid: any) {
    this.isSubmitted = true;
    if (this.isUniquefname == false) {
      this.toast.error('Field name already exists');
      return;
    }
    if (isValid) {
      if (this.leadsFeild.type == 'select') {
        this.leadsFeild['max-length'] = 0;
        this.leadsFeild['min-length'] = 0;
      }
      this.leadsFeild.field_name = this.leadsFeild.field_name.toLowerCase();
      let param = {
        client_id: this.localStorage.getClientId(),
        fields: [this.leadsFeild],
      };

      this.btnLoading = true;
      this.api.addLeadField(param).subscribe(
        (data: any) => {
          this.btnLoading = false;

          this.modalService.dismissAll();
          this.getLeadsFields();
          this.toast.success("Fields Saved Successfully");
          this.resetValues();
        },
        (error: any) => {
          this.btnLoading = false;
          this.toast.error('An error occured');
        }
      );
    } else {
      return;
    }
  }

  deleteLeadField() {
    this.btnLoading = true;
    this.api.deleteLeadField(this.deleteParam).subscribe(
      (data: any) => {
        this.modalService.dismissAll();
        this.btnLoading = false;
        this.toast.success(data.message);
        this.getLeadsFields();
      },
      (error: any) => {
        this.btnLoading = false;
        this.toast.error('An error occured');
      }
    );
  }
  EditLead(isValid: any) {
    this.isSubmitted = true;
    this.formAction = 'edit';
    if (this.isUniquefname == false) {
      this.toast.error('Field name already exists');
      return;
    }
    if (isValid) {
      if (this.editLeadsValue.type == 'select') {
        this.editLeadsValue['max-length'] = 0;
        this.editLeadsValue['min-length'] = 0;
      }
      let param = {
        client_id: this.localStorage.getClientId(),
        fields: [this.editLeadsValue],
      };

      this.btnLoading = true;
      this.editLeadsValue.field_name =
        this.editLeadsValue.field_name.toLowerCase();
      this.api.updateLeadField(param).subscribe(
        (data: any) => {
          this.btnLoading = false;
          this.modalService.dismissAll();
          this.getLeadsFields();
          this.toast.success(data.message);
          this.resetValues();
        },
        (error: any) => {
          this.btnLoading = false;
          this.toast.error('An error occured');
        }
      );
    }
  }

  addSubField(isValid: any) {
    this.isSubmitted = true;
    this.uniqueNameContainer = [...this.uniqueNameContainer, this.editsubfield.field_name];
    if (isValid) {
      this.subFieldArray.push(this.editsubfield);
      this.editsubfield = new LeadVal();
      this.isSubmitted = false;
    }
  }

  editSubFieldModal(element: any) {
    this.uniqueName = element.field_name;
    this.editsubfield = element;
    this.isSubFieldEditMode = true;
  }

  deleteSubField() {
    this.subFieldArray.splice(this.deleteSubFieldParam, 1);
    this.deleteSubFieldParam = null;
    this.selectedFieldForSub['sub_fields'] = this.subFieldArray;
    const param = {
      client_id: this.localStorage.getClientId(),
      fields: [this.selectedFieldForSub],
    };
    this.btnLoading = true;
    this.api.updateLeadField(param).subscribe(
      (data: any) => {
        this.btnLoading = false;
        this.modalService.dismissAll();
        this.getLeadsFields();
        this.toast.success(data.message);
        this.resetValues();
        this.subFieldArray = [];
      },
      (error: any) => {
        this.btnLoading = false;
        this.toast.error('An error occured');
      }
    );
  }

  EditSubLead(isValid: any) {
    this.isSubmitted = true;
    if (this.isUniquefname == false) {
      this.toast.error('Field name already exists');
      return;
    }
    if (this.subFieldArray.length > 0) {
      isValid = true;
    }
    if (isValid) {
      this.isSubFieldEditMode = false;
      this.editsubfield = new LeadVal();
      if (this.subFieldArray.length == 0) {
        this.subFieldArray.push(this.editsubfield);
      }
      this.selectedFieldForSub['sub_fields'] = this.subFieldArray;
      let param = {
        client_id: this.localStorage.getClientId(),
        fields: [this.selectedFieldForSub],
      };

      this.btnLoading = true;
      this.editsubfield.field_name = this.editsubfield.field_name.toLowerCase();
      this.api.updateLeadField(param).subscribe(
        (data: any) => {
          this.btnLoading = false;
          this.modalService.dismissAll();
          this.getLeadsFields();
          this.toast.success("Fields Saved Successfully");
          this.resetValues();
          this.subFieldArray = [];
        },
        (error: any) => {
          this.btnLoading = false;
          this.toast.error('An error occured');
        }
      );
    }
  }

  addOption(opt: any, form: any) {
    if (opt.trim().length == 0) return;
    else {
      if (form == 'add') {
        console.log(this.leadsFeild);
        this.leadsFeild.data.push(opt);
        this.leadsFeild.options = '';
      } else if(form == 'edit') {
        this.editLeadsValue.data.push(opt);
        this.editLeadsValue.options = '';
      }else{
        this.editsubfield.data.push(opt);
        this.editsubfield.options = ''; 
      }
    }
  }

  removeOption(index: any, form: any) {
    if (form == 'add') {
      this.leadsFeild.data.splice(index, 1);
    } else if(form == 'edit') {
      this.editLeadsValue.data.splice(index, 1);
    }else{
      this.editsubfield.data.splice(index, 1);
    }
  }

  isUniqueName(name: any, form: any) {
    let isUnique = true;
    if (!name) return false;
    
    this.uniqueNameContainer.forEach((element: any) => {
      if((form == 'edit' || form == 'sub') && (name == this.uniqueName)){
        isUnique = true;
      }else if(element == name) {
        isUnique = false;
      }
    });
    this.isUniquefname = isUnique;
    return isUnique;
}

}

export class LeadVal {
  'field_id': number = 0;
  'field_name': string = '';
  'label': string = '';
  'type': string = '';
  'max-length': number = 25;
  'min-length': number = 2;
  'placeholder': string = '';
  'required': boolean = true;
  'sequence': number = 0;
  'data': string[] = [];
  'options': string = '';
  'isEmail': boolean = false;
  'isMany': boolean = false;
  'isPublic': boolean = false;
  'isVisibleTable': boolean = false;
}
