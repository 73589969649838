import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-communication-model',
  templateUrl: './communication-model.component.html',
  styleUrls: ['./communication-model.component.scss']
})
export class CommunicationModelComponent {

  @Output() close:EventEmitter<any> = new EventEmitter();
  @Input() communicationData: any;

  constructor(
    private modalService: NgbModal
  ) { }

  btnLoading = false;
  selectedOption: string | null = null;  // Stores the selected option

  selectOption(option: string) {
    this.selectedOption = option; // Update selected option
  }

  getButtonStyles(option: string) {
    // Set styles based on the selected option
    const isSelected = this.selectedOption === option;
    return {
      background: isSelected ? '#114A63' : 'white',
      color: isSelected ? 'white' : 'black',
      border: '1px solid #114A63',
      padding: '10px 20px',
      marginRight: '10px',
      cursor: 'pointer',
      flex: 1,
      borderRadius: '10px'
    };
  }

  onSubmit(content1:any, content2:any) {
    console.log('Selected Option:', this.selectedOption); // Log selected option on submit
    if(
      this.selectedOption === 'email'
    ) {

        this.btnLoading = false;
        this.modalService
      .open(content1, { size: 'lg', scrollable: true, centered: true,
        backdrop: 'static', keyboard: false
       })
   
    }else if(
      this.selectedOption === 'call'
    ) {
     
        this.btnLoading = false;
        this.modalService
      .open(content2, { size: 'md', scrollable: true, centered: true,
        backdrop: 'static', keyboard: false
       })
    
    }
  }


  closeModal() {
    this.close.emit();
  }

}
