import { Component } from '@angular/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-drop-zone-for-leads',
  templateUrl: './drop-zone-for-leads.component.html',
  styleUrls: ['./drop-zone-for-leads.component.scss']
})
export class DropZoneForLeadsComponent {
  sheetNames: string[] = [];
  columns: string[] = [];
  fields: string[] = ['Field1', 'Field2', 'Field3']; // Add your desired fields here
  workbook: XLSX.WorkBook | null = null;

  onFileUpload(event: any): void {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      this.workbook = XLSX.read(bstr, { type: 'binary' });

      // Extract sheet names
      this.sheetNames = this.workbook.SheetNames;
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onSheetSelect(event: any): void {
    const selectedSheet = event.target.value;

    if (this.workbook && selectedSheet) {
      const worksheet = this.workbook.Sheets[selectedSheet];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Extract the first row as column names
      this.columns = (jsonData[0] as string[]) || [];
    }
  }
}
