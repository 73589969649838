import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { CategoryService } from 'src/app/private/services/category.service';
import { CompanyService } from 'src/app/private/services/company.service';
import { DealService } from 'src/app/private/services/deals.service';
import { PipeLineService } from 'src/app/private/services/pipeline.service';
import { ProductService } from 'src/app/private/services/product.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {

  
    searchQuery = new FormControl();
    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
  
    @ViewChild(MatSort)
    sort!: MatSort;
  
    dateQuery = new FormControl();
    center_id: any;
    totalRows = 0;
    temp: any = [];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    formAction = 'Add';
    currentPage = 0;
    pageSize = 25;
    pageSizeOptions: number[] = [10, 25, 100];
    btnLoading: boolean = false;
    reloading: boolean = false;
    initialLoad: boolean = true;
    closeResult = '';
    editData: any;
    deleteData: any;
    currencyCode: any;
    users: any;
    productCategories: any;
    selectedImage: any;
   
  
    current_id_hover = '';
    infoArray: any = [];
    isSaving: boolean = false;


    
  
    displayedColumns: string[] = [
      "product_image",
      "product_name",
      "info",
      "manufacturer",
      "unit_price",
      "quantity_in_stock",
      "sales_start_date",
      "sales_end_date",
      "handler",
      "action",
    ];
    
  
  
  
  
    defaultFilter = [
      'product_name',
      'product_code',
      'product_active',
      'manufacturer',
      'taxable',
    ]
  
    info: any = [
      // { key: 'cat_id', value: 'Category ID' },
      // { key: 'product_owner', value: 'Product Owner' },
      { key: 'productOwner', value: 'Product Owner Details' },
      { key: 'product_code', value: 'Product Code' },
      { key: 'product_image', value: 'Product Image' },
      { key: 'product_active', value: 'Active Status' },
      { key: 'sales_end_date', value: 'Sales End Date' },
      { key: 'support_end_date', value: 'Support End Date' },
      { key: 'product_name', value: 'Product Name' },
      // { key: 'vendor_id', value: 'Vendor ID' },
      { key: 'manufacturer', value: 'Manufacturer' },
      { key: 'sales_start_date', value: 'Sales Start Date' },
      { key: 'support_start_date', value: 'Support Start Date' },
      { key: 'unit_price', value: 'Unit Price' },
      { key: 'tax', value: 'Tax' },
      { key: 'taxable', value: 'Taxable' },
      { key: 'commission_rate', value: 'Commission Rate' },
      { key: 'usage_unit', value: 'Usage Unit' },
      { key: 'quantity_in_stock', value: 'Quantity in Stock' },
      // { key: 'handler_id', value: 'Handler ID' },
      // { key: 'qty_ordered', value: 'Quantity Ordered' },
      // { key: 'reorder_level', value: 'Reorder Level' },
      // { key: 'quantity_in_demand', value: 'Quantity in Demand' },
      { key: 'description', value: 'Description' },
      { key: 'createdAt', value: 'Created At' },
      { key: 'updatedAt', value: 'Updated At' },
      { key: 'product_other_images', value: 'Other Images' },

      // { key: 'handler', value: 'Handler Details' },
    ];
  
    constructor(
      private localStorage: LocalStorageService,
      private api: ProductService,
      // private userApi: UsersService,
      private modalService: NgbModal,
      private toast: ToastrService,
      private userApi: UsersService,
      private categoryApi: CategoryService,
  
    ) { }
  

    ngAfterViewInit() {
      this.loadData();
      this.currencyCode = this.localStorage.getClientCurrency();
    }
  
    ngOnInit(): void {    
      this.getAllUsers();
      this.getProductCategories();

      for (let index = 0; index < this.info.length; index++) {
        this.infoArray.push({key:this.info[index]?.key,value:this.info[index]?.value});
      }
    }

    getAllUsers() {
      let param = {
        client_id: this.localStorage.getClientId(),
      };
      this.userApi.findAllUsers(param).subscribe({
        next: (res: any) => {
          this.users = res || [];
        },
        error: (e: any) => {
          console.error(e);
        },
      });
    }

    getProductCategories() {
      let param = {
        client_id: this.localStorage.getClientId(),
      };
      this.categoryApi.getAllCategory(param).subscribe({
        next: (res: any) => {
          this.productCategories = res || [];
        },
        error: (e: any) => {
          console.error(e);
        },
      });
    }
  
  

  
  
    open(content: any) {
      this.formAction = 'Add';
  
      this.modalService.open(content, {
        size: 'lg', scrollable: true,
        centered: true,
        backdrop: 'static', keyboard: false 
      })
    }
  
    loadData() {
  
      try {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        merge(
          this.dateQuery.valueChanges,
          this.searchQuery.valueChanges,
          this.sort.sortChange,
          this.paginator.page
        )
          .pipe(
            startWith({}),
            switchMap(() => {
              this.reloading = true;
             
              let param = {
                "page": this.paginator.pageIndex * this.paginator.pageSize,
                "limit": this.paginator.pageSize,
                where: {
                  client_id: this.localStorage.getClientId(),
                },
                "order": {
                  "col": "\"product\".\"updatedAt\"",
                  "dir": "DESC"
                }
              }
  
              if (this.searchQuery.value) {
  
                for (let [key, value] of Object.entries(this.searchQuery.value)) {
  
                  if (this.defaultFilter.includes(key)) {
                    key = (key == 'assign_to') ? 'assign_to' : key
                    const obj = {
                      ...param.where,
                      [key]: value,
                    };
                    param.where = obj;
                  }
                }
  
              }
  
              return this.api
                .getAllProduct(param)
                .pipe(catchError(() => observableOf(null)));
            }),
            map((response: any) => {
              this.initialLoad = false;
              this.reloading = false;
              if (response === null) {
                return [];
              }
              this.totalRows = response.count;
              console.log("response", response.length)
              return response?.rows;
            })
          )
          .subscribe({
            next: (data) => {
              this.dataSource = new MatTableDataSource<any>(data);
            },
            error: (e) => {
              this.initialLoad = false;
              this.reloading = false;
              console.error(e);
            },
            complete: () => {
              this.reloading = false;
              this.initialLoad = false;
            },
          });
      } catch (e) {
        this.initialLoad = false;
        this.reloading = false;
        console.error(e);
      }
    }
  
    reloadData() {
      this.reloading = true;
      try {
        merge()
          .pipe(
            startWith({}),
            switchMap(() => {
              this.reloading = true;
  
              console.log("client_id", this.localStorage.getClientId())
              let param = {
                "page": this.paginator.pageIndex * this.paginator.pageSize,
                "limit": this.paginator.pageSize,
                where: {
                  client_id: this.localStorage.getClientId(),
                },
                "order": {
                  "col": "\"product\".\"updatedAt\"",
                  "dir": "DESC"
                }
              }
  
              return this.api
                .getAllProduct(param)
                .pipe(catchError(() => observableOf(null)));
            }),
            map((response: any) => {
              this.initialLoad = false;
              if (response === null) {
                return [];
              }
              this.totalRows = response.count;
              console.log("response", response.length)
              return response?.rows;
            })
          )
          .subscribe({
            next: (data) => {
              this.dataSource = new MatTableDataSource<any>(data);
            },
            error: (e) => {
              this.initialLoad = false;
              this.reloading = false;
              console.error(e);
            },
            complete: () => {
              this.initialLoad = false;
              this.reloading = false;
            },
          });
      } catch (e) {
        this.initialLoad = false;
        this.reloading = false;
        console.error(e);
      }
    }
  
    openEdit(content: any, data: any) {
      this.formAction = 'Update';
      this.editData = data;
      this.modalService.open(content, {
        size: 'lg', scrollable: true,
        centered: true,
        backdrop: 'static', keyboard: false 
      })
    }
  
    openDeleteEvent(content: any, data: any) {
      this.deleteData = data;
      this.modalService.open(content, {
        size: 'md', scrollable: true,
        centered: true,
        backdrop: 'static', keyboard: false 
      })
    }
  
    searchFilter(query: any) {
      console.log('Search Query:', query);
      this.searchQuery.setValue(query);
    }
  
    handleDeleteProduct() {
  
      const parmas = {
        "product_id": this.deleteData.product_id
      }
      this.btnLoading = true;
      this.api.deleteProduct(parmas).subscribe((res: any) => {
        console.log('Response:', res);
        this.toast.success('Company Deleted Successfully');
        this.reloadData();
        this.btnLoading = false;
        this.modalService.dismissAll();
      })
    }
  
    updateHoverState(id: string): void {
      this.current_id_hover = id;
    }
  
    updateStatus(data: any) {
      console.log('Update Status:', data);
      this.isSaving = true;
    
      this.api.updateProduct(data).subscribe({
        next: (res: any) => {
          console.log('Response:', res);
          // this.toast.success('Deal Updated Successfully');
        },
        error: (err: any) => {
          console.error('Error updating deal:', err);
          this.toast.error('Failed to update the deal. Please try again.');
        },
        complete: () => {
          this.isSaving = false; // Ensure it's set to false regardless of success or failure
        }
      });
    }

    showImage(content:any,image: any) {
      this.selectedImage = image;
      this.modalService.open(content, {
        size: 'lg', scrollable: true,
        centered: true
      })
    }

    
  

}


function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}
