import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component';
import { ForgetComponent } from './public/forget/forget.component';
import { ResetComponent } from './public/reset/reset.component';
import { MainComponent } from './private/layout/main/main.component';
import { SharedModule } from './private/shared/shared.module';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { HttpResponseInterceptor } from './utils/HttpResponseInterceptor';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from './utils/GlobalErrorHandler';
import { ButtonComponent } from './components/button/button.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerModule, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';
import { AsyncPipe } from '@angular/common';
import { EventsComponent } from './private/modules/events/events.component';
import { CreateEventComponent } from './private/modules/components/create-event/create-event.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClickOutsideDirective } from './click-outside.directive';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { WhatsappTemplateComponent } from './private/modules/configuration/whatsapp-template/whatsapp-template.component';
import { CreataeWhatsappTemplateComponent } from './private/modules/components/creatae-whatsapp-template/creatae-whatsapp-template.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SafeUrlPipe } from './safe-url.pipe';

import { MatTooltipModule } from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { EmailTemplateComponent } from './private/modules/configuration/email-template/email-template.component';
import { EmailEditorModule } from 'angular-email-editor';
import { CreateEmailTemplateComponent } from './private/modules/components/create-email-template/create-email-template.component';
import { ContactGroupsComponent } from './private/modules/configuration/contact-groups/contact-groups.component';
import { CreateContactGroupComponent } from './private/modules/components/create-contact-group/create-contact-group.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddContactsGroupComponent } from './private/modules/components/add-contacts-group/add-contacts-group.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BroadcastCampaignComponent } from './private/modules/configuration/broadcast-campaign/broadcast-campaign.component';
import { CreateBroadcastCampaignComponent } from './private/modules/components/create-broadcast-campaign/create-broadcast-campaign.component';
import { MatRadioModule } from '@angular/material/radio';
import { ContactGroupListComponent } from './private/modules/components/contact-group-list/contact-group-list.component';

import { CsvDropzoneComponent } from './private/modules/components/csv-dropzone/csv-dropzone.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PreviewWhatsappTemplateComponent } from './private/modules/components/preview-whatsapp-template/preview-whatsapp-template.component';
import { RingOverComponent } from './private/modules/components/ring-over/ring-over.component';
import { PreviewEmailTemplateComponent } from 'src/app/private/modules/components/preview-email-template/preview-email-template.component';
import { WoocommerceInventoryComponent } from './private/modules/woocommerce-inventory/woocommerce-inventory.component';


const materialModules = [
  FormsModule,
  ReactiveFormsModule,
  MatButtonModule,
  MatCheckboxModule,
  NgbModule,
  ToastrModule.forRoot({ timeOut: 1500,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,}),
  MatFormFieldModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatInputModule,
  MatSidenavModule,
  MatDividerModule,
  MatToolbarModule,
  MatIconModule,
  MatMenuModule,
  MatListModule,
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule ,
  MatSortModule,
  AsyncPipe,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatTabsModule,
  MatRadioModule,

];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetComponent,
    ResetComponent,
    MainComponent,
    ButtonComponent,
    SafeUrlPipe,
    ClickOutsideDirective,
    ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    materialModules,
    CKEditorModule,
    EmailEditorModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDropzoneModule
  

   
    
  
  ],
  providers: [MatPaginatorModule,NgbModule,
    [{provide: ErrorHandler,useClass: GlobalErrorHandler},
      { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi:true}
    ],
    // if deployed on subdomain
    // {provide: LocationStrategy, useClass: HashLocationStrategy},

    MatRangeDateSelectionModel,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
