import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  LocalStorageService } from 'src/app/utils/LocalStorageService';
import { AuthService } from '../services/auth.service';
import { JWTTokenServiceService } from '../services/token.service';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/private/services/users.service';
import { TrackingService } from 'src/app/private/services/tracking.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userName: any;
  btnLoading:boolean = false;
  isPasswordVisible = false;

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  submitted = false;
  isLoading = false;
  buttonTitle = "Login"
  otpForm:boolean = false;
  userOTP:any;
  loginData:any;
  constructor(private tracker: TrackingService,private toastr: ToastrService,private localStorageService:LocalStorageService, private jwt:JWTTokenServiceService, private coreAuth:AuthService,private router: Router, private formBuilder: FormBuilder,private userApi : UsersService) { 
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onEnter();
    }
  }
  
  onEnter() {
    // Handle Enter key press here
    console.log('Enter key pressed');
    this.onSubmit()
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        username: ['',[Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
        password: ['',[Validators.required,Validators.minLength(6),Validators.maxLength(40)]],
      }
    );
  }

  getAllCenter(){
    const param = {
      where:{
        client_id: this.localStorageService.getClientId()
      }
    }
    this.userApi.getAllCenter(param).subscribe({
      next:(res)=>{
        localStorage.setItem('center_id',res[0]['center_id'])
      },
      error:(e)=>{

      }
    })
  }
   forgetPassword(){
    this.router.navigate(['forget-password']);
  }
  goToDashboard(){
    this.router.navigate(['dashboard/home']);
  }


  get formError(): any {
    return this.form.controls;
  }
  onOTPLogin()
  {
    this.submitted = true;
    this.isLoading = true;
    this.buttonTitle = "Loading"
    let param = {
      user_id:this.loginData.user_id,
      otp:this.userOTP,
      token:this.loginData.token
  
    }
    this.coreAuth.verifyOtp(param).subscribe({
      next: (res) => {
        if(res)
        {
          this.buttonTitle = "Login"
          this.isLoading = false;
          this.jwt.setToken(res.token);
          this.toastr.success('Login Successful', 'Welcome');
          this.localStorageService.login(res);
          this.router.navigate(['main/leads']);
        
        }
  
      },
      error: (e) => {
        this.buttonTitle = "Login"
        this.isLoading = false;
      },
      complete: () => {
        this.buttonTitle = "Login"
        this.isLoading = false;
      }
  }) 
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
   this.isLoading = true;
   this.buttonTitle = "Loading"
   this.coreAuth.loginUser(this.form.value).subscribe({
    next: (res) => {
      if(res)
      {

        if(res.twoFactorAuthentication)
        {
          this.otpForm = true;
          this.loginData = res;
        }
        else
        {
          this.buttonTitle = "Login"
          this.isLoading = false;
          this.jwt.setToken(res.token);
          this.toastr.success('Login Successful', 'Welcome');
          this.localStorageService.login(res);
          this.tracker.track("Login","Authentication","User Login",null)
          //this.getAllCenter();
          this.router.navigate(['main/leads']);
        }
      
      }

    },
    error: (e) => {
      this.buttonTitle = "Login"
      this.isLoading = false;
    },
    complete: () => {
      this.buttonTitle = "Login"
      this.isLoading = false;
    }
}) 
  }
  forgetPass()
  {
     this.router.navigate(['forget']);
  }


  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
