import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Papa from 'papaparse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactGrouopService } from 'src/app/private/services/contact-groups.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-csv-dropzone',
  templateUrl: './csv-dropzone.component.html',
  styleUrls: ['./csv-dropzone.component.scss']
})
export class CsvDropzoneComponent {
  csvUploaded = false;
  columns: string[] = [];
  csvData: any[] = [];
  mappedData: any[] = [];
  form: FormGroup;
  btnLoading = false;

  @Output() formSubmit: EventEmitter<any> = new EventEmitter();
  @Input() selectedGroupId: any;

  constructor(private fb: FormBuilder,
    private api: ContactGrouopService,
    private toast: ToastrService
  ) {
    // Create form group with required validators
    this.form = this.fb.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }

  // Method called when a file is selected
  onFileSelect(event: any) {
    const file = event.addedFiles[0];

    if (file) {
      this.parseCSV(file);
      this.csvUploaded = true;
    }
  }

  // Parse the CSV file
  parseCSV(file: File) {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        this.csvData = result.data;
        // Extract columns from the first row
        this.columns = Object.keys(this.csvData[0]);
      }
    });
  }

  // Method to map data based on user selection
  mapData() {
    if (this.form.invalid) {
      // Mark all fields as touched to show validation errors
      this.form.markAllAsTouched();
      return;
    }

    
    const selectedUsername = this.form.get('username')?.value;
    const selectedEmail = this.form.get('email')?.value;
    const selectedPhone = this.form.get('phone')?.value;

    this.mappedData = this.csvData.map((row) => ({
      name: row[selectedUsername],
      source: 'csv',
      email: row[selectedEmail],
      phone: row[selectedPhone]
    }));

    const payload = {
      client_id: 1,
      contact_group_id: this.selectedGroupId || 1,
      contacts: this.mappedData
    };
    this.btnLoading = true;
    this.api.createContactGroupList(payload).subscribe(
      (response:any) => {
        this.formSubmit.emit();
      },
      (error:any) => {
        console.log(error);
        this.toast.error("Error while creating contact group");
        this.btnLoading = false;
      }
    );


  }

  // Helper method to check if a form control is invalid and touched
  isFieldInvalid(fieldName: string): boolean {
    const field = this.form.get(fieldName);
    return field!.invalid && field!.touched;
  }
}
