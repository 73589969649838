import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { EmailHubService } from 'src/app/private/services/email-hub.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-mail-right-section',
  templateUrl: './mail-right-section.component.html',
  styleUrls: ['./mail-right-section.component.scss']
})
export class MailRightSectionComponent {
  @Input() selectedRow!: any;
  @Input() sanitizedContent: SafeHtml | null = null;
  @Input() isComposeOpen = false;
  @Output() refresh = new EventEmitter<any>();
  @Input() loadingEmailData = false;

  @Output() closeCompose = new EventEmitter<any>();

  openBCC = false;
  openCC = false;
  public Editor = ClassicEditor as unknown as any;
  editorData: string = ''; // CKEditor data
  files: any[] = [];
  to: string = '';
  cc: string = '';
  bcc: string = '';
  subject: string = '';
  btnLoading = false;
  emailInput: string = '';
  emails: string[] = [];
  ccInput: string = '';  // Holds the current input value for CC
  ccEmails: string[] = []; // Array to store CC emails as chips

  constructor(
    private toast: ToastrService,
    private api: EmailHubService,
    private localStorage: LocalStorageService
  ) { }


  selectAction(action: string): void {
    // this.bottomSheetRef.dismiss(action);
  }

  openLink(event: MouseEvent): void {
    // this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  handleOpenBCC() {
    this.openBCC = !this.openBCC;
  }

  handleOpenCC() {
    this.openCC = !this.openCC;
  }

  onFileSelected(event: any) {
    const selectedFiles: FileList = event.target.files;
    console.log('Selected Files:', selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      console.log('File:', file);
      reader.onload = (e: any) => {
        this.files.push({ file, preview: e.target.result });
        console.log('Files:', this.files);
      };

      reader.readAsDataURL(file);  // Generate preview
    }
  }

  formatSize(size: number): string {
    if (size < 1024) return `${size} B`;
    else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1048576).toFixed(2)} MB`;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }


  closeBottomSheet() {
    // this.bottomSheetRef.dismiss();
    // this.isComposeOpen = false;
    this.closeCompose.emit();
  }

  sendMail() {
    // if (!this.to) {
    //   this.toast.error('Please enter a recipient email address');
    //   return;
    // }'

    if (this.emails.length === 0 && !this.emailInput) {
      this.toast.error('Please enter a recipient email address');
      return;
    }

    if (!this.subject) {
      this.toast.error('Please enter a subject');
      return;
    }

    if (!this.editorData) {
      this.toast.error('Please enter a message');
      return;
    }

    console.log('Sending email...');
    this.btnLoading = true;

    let attachments: any[] = [];

    this.files.forEach((file) => {
      attachments.push({
        "@odata.type": "#microsoft.graph.fileAttachment",
        "name": file.file.name,
        "contentType": file.file.type,
        "contentBytes": file.preview
      });
    });

    const param = {
      subject: this.subject,
      contentType: 'HTML',
      bodyContent: this.editorData,
      recipientEmails: this.emails?.length > 0 ? this.emails : [this.emailInput],
      fromEmail: this.localStorage.getEmail(),
      ccRecipients: this.ccEmails?.length > 0 ? this.ccEmails : this.ccInput ? [this.ccInput] : [],
      attachments: attachments,
      client_id:this.localStorage.getClientId()
    }

    this.api.sendEmailUsingOutlook(param).subscribe({
      next: (res) => {

       
        this.toast.success('Email sent successfully');

        setTimeout(() => {
          this.btnLoading = false;
          this.refresh.emit();
          this.openBCC = false;
          this.openCC = false;
          this.subject = '';
          this.editorData = '';
          this.files = [];
          this.emails = [];
          this.ccEmails = [];
          this.emailInput = '';
          this.ccInput = '';
          
        }, 1000);



      },
      error: (e) => {
        console.error(e);
        this.toast.error('Error sending email');
        this.btnLoading = false;
      }
    })
  }

  addEmail(): void {
    if (this.emailInput && this.isValidEmail(this.emailInput)) {
      this.emails.push(this.emailInput);
      this.emailInput = '';  // Clear the input field
    } else {
      // alert('Please enter a valid email address.');
      this.toast.error('Please enter a valid email address.');
    }
  }

  // Remove email from the chip list
  removeEmail(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  addCc(): void {
    if (this.ccInput && this.isValidEmail(this.ccInput)) {
      this.ccEmails.push(this.ccInput);
      this.ccInput = '';  // Clear the input field
    } else {
      // alert('Please enter a valid CC email address.');
      this.toast.error('Please enter a valid CC email address.');
    }
  }

  // Remove CC email from the chip list
  removeCc(ccEmail: string): void {
    const index = this.ccEmails.indexOf(ccEmail);
    if (index >= 0) {
      this.ccEmails.splice(index, 1);
    }
  }


  // Validate email format using a simple regex (optional)
  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

}
