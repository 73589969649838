import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getAllProduct(data:any): Observable<any> {
    return this.http.post(environment.getAllProduct,data);
  }

  addProduct(data:any): Observable<any> {
    return this.http.post(environment.addProduct,data);
  }

  updateProduct(data:any): Observable<any> {
    return this.http.post(environment.updateProduct,data);
  }

  deleteProduct(data:any): Observable<any> {
    return this.http.post(environment.deleteProduct,data);
  }

}
