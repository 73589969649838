import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { ContactGrouopService } from 'src/app/private/services/contact-groups.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { ContactGroupListComponent } from '../../components/contact-group-list/contact-group-list.component';

@Component({
  selector: 'app-contact-groups',
  templateUrl: './contact-groups.component.html',
  styleUrls: ['./contact-groups.component.scss']
})
export class ContactGroupsComponent {


  initalLoadGroup = true;
  selectedGroupId: any;
  isRefresh = true;
  GroupListData: any;
  
  currentSelectedContactGroupID: any;

  @Output() reload: EventEmitter<any> = new EventEmitter();
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(ContactGroupListComponent) contactGroupListComponent!: ContactGroupListComponent;

  GroupToEdit: any;

  allContactGroups: any;
  btnLoading = false;
  initialLoad = true;

  totalRows = 0;
  currentPage = 0;
  pageSize = 10;
  GroupToDelete: any;


  constructor(
    private toast: ToastrService,
    private api: ContactGrouopService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private userApi: UsersService,
  ) {

  }


  ngAfterViewInit() {
    this.getAllContactGroups();
  }

  getAllContactGroups(type?: string ) {

    const payload = {
      "where": {
        "client_id": this.localStorage.getClientId()
      },
      "filter": null,
      "page": 0,
      "limit": 10,
      "order": {
        "col": "\"contact_group\".\"createdAt\"",
        "dir": "DESC"
      }
    }

    this.api.getAllContactGroups(payload).subscribe(
      (res) => {
        this.allContactGroups = res;

        if(this.initialLoad){
          this.currentSelectedContactGroupID = this.allContactGroups[0].id;
        }

        this.initalLoadGroup = false;

        this.loadData();

        if(type=== 'Create') {
          this.modalService.dismissAll();
          this.toast.success("Contact Group Created Successfully");
        }
      },
      (err) => {
        console.error(err);
      }
    )
  }

  getAllContactGroupsForDelete(type?: string ) {

    const payload = {
      "where": {
        "client_id": this.localStorage.getClientId()
      },
      "filter": null,
      "page": 0,
      "limit": 10,
      "order": {
        "col": "\"contact_group\".\"createdAt\"",
        "dir": "DESC"
      }
    }

    this.api.getAllContactGroups(payload).subscribe(
      (res) => {
        this.allContactGroups = res;

       
          this.currentSelectedContactGroupID = this.allContactGroups[0].id;
        

        this.initalLoadGroup = false;

        this.loadData();

       this.toast.success("Contact Group Deleted Successfully");
       this.modalService.dismissAll();
       this.btnLoading = false;
      },
      (err) => {
        console.error(err);
      }
    )
  }

  loadData(type?: string) {

    try {
      // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            console.log("client_id", this.localStorage.getClientId())

            const payload = {
              "where": {
                "client_id": this.localStorage.getClientId(),
                  "contact_group_id": this.currentSelectedContactGroupID
              },
              "filter": null,
              "page": this.currentPage * this.pageSize,
              "limit": this.pageSize,
              "order": {
                  "col": "\"contact_group_list\".\"updatedAt\"",
                  "dir": "DESC"
              }
          }
        

            return this.api
              .getAllContactGroupList(payload)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            this.isRefresh = false;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.initialLoad = false;
            this.dataSource = new MatTableDataSource<any>(data);
            this.GroupListData = data;

            if (type === 'Create') {
              

              this.modalService.dismissAll();
              this.btnLoading = false;
            }
          },
          error: (e) => {
            this.initialLoad = false;
            console.error(e);
          },
          complete: () => {
          },
        });
    } catch (e) {
      console.error(e);
    }
  }

  open(content: any) {
    this.modalService
      .open(content, { size: 'md', scrollable: true, centered: true })
  }


  addContacts(element: any, id:any) {
    console.log('Add Contacts clicked');

    this.selectedGroupId = id;

    this.modalService
      .open(element, { size: 'xl', scrollable: true, centered: true })
    // Add your logic here
  }

  importContacts(element: any, id:any) {
    this.selectedGroupId = id;
    this.modalService
      .open(element, { size: 'md', scrollable: true, centered: true })
  }


  handleChangeGroup(id: any) {

    if(this.currentSelectedContactGroupID === id) {
      return;
    }

    this.isRefresh = true;
    if (this.contactGroupListComponent) {
      this.contactGroupListComponent.resetPaginator();
    }
    this.currentPage = 0;
    this.pageSize = 10;
    this.currentSelectedContactGroupID = id;
    this.loadData();
  }

  openEditGroup(content: any, event: any) {
    this.modalService
      .open(content, { size: 'md', scrollable: true, centered: true })

    this.GroupToEdit = event;
  }

  openDeleteGroup(content: any, event: any) {
    this.modalService
      .open(content, { size: 'md', scrollable: true, centered: true })

    this.GroupToDelete = event;
  }

  handleDeleteEvent() {

    const payload = {
      "id": this.GroupToDelete.id
    }

    this.btnLoading = true;

    this.api.deleteContactGroup(payload).subscribe(
      (res:any) => {
        
        this.getAllContactGroupsForDelete();

        
      },
      (err:any) => {
        console.error(err);
      }
    )
  }

  
}


function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}