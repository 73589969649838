import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TINA';

  ngOnInit() {
    if (environment.production) {
      this.disableRightClick();
      // this.preventScreenshot();
      this.preventConsoleAccess();
    }
  }

  preventConsoleAccess(): void {
    // Prevent opening the developer console
    const blockConsole = () => {
      const element = document.createElement('div');
      Object.defineProperty(element, 'id', {
        get: function () {
          throw new Error('Console access is disabled');
        },
      });
      console.log(element);
    };
  
    setInterval(blockConsole, 1000);
  
    // Clear console in production
    console.log = function () {};
    console.warn = function () {};
    console.error = function () {};
    console.info = function () {};
  }

  disableRightClick() {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }

  preventScreenshot() {
    // Block common screenshot shortcuts
    document.addEventListener('keydown', (event) => {
      const screenshotKeys = ['PrintScreen', 'Meta', 'Shift', 'Control'];
      if (screenshotKeys.includes(event.key)) {
        event.preventDefault();
      }
    });

    // Attempt to clear clipboard data (for some screen capture methods)
    document.addEventListener('keyup', (event) => {
      if (event.key === 'PrintScreen') {
        navigator.clipboard.writeText('');
      }
    });
  }

  
}
