import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/private/services/category.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent {
  @Input() formAction: string = 'Add';
  btnLoading: boolean = false;
  @Input() editData: any;
  @Output() close = new EventEmitter<void>();
  @Output() reload = new EventEmitter<void>();
  category: any = { cat_id: '', cat_name: '', description: '', is_active: true };

  constructor(
      private localStorage: LocalStorageService,
      private api: CategoryService,
      // private userApi: UsersService,
      private modalService: NgbModal,
      private toast: ToastrService  
    ) { }

  ngOnInit() {
    if (this.formAction === 'Update') {
      this.category = this.editData;
    }
  }

  saveCategory(isValid: any) {
    if (isValid) {
      if(this.formAction === 'Add') {

        const payload = {
          client_id: this.localStorage.getClientId(),
          cat_name: this.category.cat_name,
          description: this.category.description,
          is_active: this.category.is_active
        }
        this.btnLoading = true;
        this.api.createCategory(payload).subscribe({
          next: (response: any) => {
            this.btnLoading = false;
            this.toast.success("Category created successfully");
            this.reload.emit();
            this.closeModal();
          },
          error: (error: any) => {
            this.btnLoading = false;
            this.toast.error(error.error.message);
          }
        }
        );


      }else if(this.formAction === 'Update') {
        this.btnLoading = true;
        const payload = {
          client_id: this.localStorage.getClientId(),
          cat_id: this.category.cat_id,
          cat_name: this.category.cat_name,
          description: this.category.description,
          is_active: this.category.is_active
        }

        this.api.updateCategory(payload).subscribe({
          next: (response: any) => {
            this.btnLoading = false;
            this.toast.success("Category updated successfully");
            this.reload.emit();
            this.closeModal();
          },
          error: (error: any) => {
            this.btnLoading = false;
            this.toast.error(error.error.message);
          }
        }
        );
      }
    }
  }

  closeModal() {
    this.close.emit();
  }
}
