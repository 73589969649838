import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

    getAllCategory(data:any): Observable<any> {
        return this.http.post(environment.getAllCategory,data);
    }

    deleteCategory(data:any): Observable<any> {
        return this.http.post(environment.deleteCategory,data);
    }

    createCategory(data:any): Observable<any> {
        return this.http.post(environment.createCategory,data);
    }

    updateCategory(data:any): Observable<any> {
        return this.http.post(environment.updateCategory,data);
    }

}

  