import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { EmailHubService } from 'src/app/private/services/email-hub.service';
import { EventService } from 'src/app/private/services/event.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-mail-right-table',
  templateUrl: './mail-right-table.component.html',
  styleUrls: ['./mail-right-table.component.scss']
})
export class MailRightTableComponent {

  loading: boolean = true;
  searchQuery = new FormControl();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  dateQuery = new FormControl();
  center_id: any;
  totalRows = 0;
  temp: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  emailToDelete: any;
  btnLoading: boolean = false;
  selectedRow: any;
  toasters: any;
  skipToken: any = "";
  loadingData: boolean = true;
  sanitizedContent: SafeHtml | null = null;

  displayedColumns: string[] = [
    "select",
    "sender",
    "preview-body",
    // "action"
  ];

  @Input() activeAction: string = 'inbox';

  selectedIds: number[] = []; // To store selected IDs

  // ngOnInit() {
  //   this.dataSource = new MatTableDataSource( [
  //     {
  //       sender: 'Gaurav Singh Rathore',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'Jane Doe',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'John Smith',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'Jane Smith',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     } ,{
  //       sender: 'John Doe',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'Jane Doe',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'John Smith',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'Jane Smith',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },{
  //       sender: 'John Doe',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'Jane Doe',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'John Smith',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     },
  //     {
  //       sender: 'Jane Smith',
  //       previewBody: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       action: 'View'
  //     }
  //   ]);
  // }

  constructor(
    private api: EmailHubService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private localStorage: LocalStorageService,
    private sanitizer: DomSanitizer

  ) { }

  ngAfterViewInit() {
    console.log("tabl column : ", this.displayedColumns);
    this.loadData();
  }

  getSkipToken(url: any) {
    const params = new URL(url).searchParams; // Parse the query parameters
    return params.get('$skiptoken'); // Extract the skiptoken parameter
  }

  loadData() {

    console.log("activeAction", this.activeAction);

    try {
      this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort?.sortChange,
        this.paginator?.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            // console.log("client_id", this.localStorage.getClientId())
            // "client_id":4,
            // "user_email": "xyz@gravitas.ai",
            // "searchKeyword":"test",
            // "requestType":"search",
            // "top":2,
            // "skip":0,
            // "skiptoken":""
            let param = {
              "client_id": this.localStorage.getClientId(),
              "user_email": this.localStorage.getEmail(),
              "searchKeyword": this.searchQuery.value,
              "requestType": this.searchQuery.value ? "search" : "normal",
              "top": this.paginator.pageSize,
              "skip": this.paginator.pageIndex * this.paginator.pageSize,
              "skiptoken": this.paginator.pageIndex > 0 ? this.skipToken : ""
            }

            this.loadingData = true;

            if (this.activeAction == 'inbox') {
              return this.api
                .getOutlookInboxEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'sent') {
              return this.api
                .getOutlookSentEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'drafts') {
              return this.api
                .getOutlookDraftEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'trash') {
              return this.api
                .getOutlookTrashEmail(param)
                .pipe(catchError(() => observableOf(null
                )));
            }
            else {
              return this.api
                .getOutlookDeletedEmail(param)
                .pipe(catchError(() => observableOf(null)));
            }

          }),
          map((response: any) => {
            this.loading = false;
            if (response?.value === null) {
              this.totalRows = 0;
              return [];
            }
            this.skipToken = response['@odata.nextLink'];
            if (this.searchQuery.value && this.skipToken) {
              const skipToken = this.getSkipToken(this.skipToken);;
              this.skipToken = skipToken;
            } else {
              this.skipToken = "";
            }
            if (this.activeAction == 'inbox') {
              this.totalRows = response?.totalInboxEmailCount
            } else if (this.activeAction == 'sent') {
              this.totalRows = response?.totalSentEmailCount
            } else if (this.activeAction == 'drafts') {
              this.totalRows = response?.totalDraftEmailCount
            } else if (this.activeAction == 'trash') {
              this.totalRows = response?.totalTrashEmailCount
            } else if (this.activeAction == 'deleted') {
              this.totalRows = response?.totalDeletedEmailCount
            }
            return response?.value;
          })
        )
        .subscribe({
          next: (data) => {
            this.loadingData = false;
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            this.loading = false;
            this.loadingData = false;
            console.error(e);
          },
          complete: () => {
            this.loading = false;
          },
        });
    } catch (e) {
      this.loading = false;
      console.error(e);
    }
  }

  loadNewData() {


    try {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            this.loadingData = true;
            // console.log("client_id", this.localStorage.getClientId())
            let param = {
              "client_id": this.localStorage.getClientId(),
              "user_email": this.localStorage.getEmail(),
              "top": this.paginator.pageSize,
              "skip": this.paginator.pageIndex * this.paginator.pageSize,
            }

            if (this.activeAction == 'inbox') {
              return this.api
                .getOutlookInboxEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'sent') {
              return this.api
                .getOutlookSentEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'drafts') {
              return this.api
                .getOutlookDraftEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'trash') {
              return this.api
                .getOutlookTrashEmail(param)
                .pipe(catchError(() => observableOf(null
                )));
            }
            else {
              return this.api
                .getOutlookDeletedEmail(param)
                .pipe(catchError(() => observableOf(null)));
            }

          }),
          map((response: any) => {
            this.loading = false;
            if (response?.value === null) {
              this.totalRows = 0;
              return [];
            }
            this.totalRows = response?.value?.length;
            return response?.value;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
            this.btnLoading = false;
            this.modalService.dismissAll();
            this.loadingData = false;
          },
          error: (e) => {
            this.loading = false;
            this.btnLoading = false;
            this.loadingData = false;
            console.error(e);
          },
          complete: () => {
            this.loading = false;
            this.loading = false;
          },
        });
    } catch (e) {
      this.loadingData = false;
      this.loading = false;
      console.error(e);
    }
  }

  refetch() {


    try {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            // console.log("client_id", this.localStorage.getClientId())
            this.toasters = this.toast.warning('Loading...', '', {
              disableTimeOut: true, // Keep the toast open indefinitely
            });

            this.loadingData = true;
            let param = {
              "client_id": this.localStorage.getClientId(),
              "user_email": this.localStorage.getEmail(),
              "top": this.paginator.pageSize,
              "skip": this.paginator.pageIndex * this.paginator.pageSize,
            }

            if (this.activeAction == 'inbox') {
              return this.api
                .getOutlookInboxEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'sent') {
              return this.api
                .getOutlookSentEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'drafts') {
              return this.api
                .getOutlookDraftEmail(param)
                .pipe(catchError(() => observableOf(null)));
            } else if (this.activeAction == 'trash') {
              return this.api
                .getOutlookTrashEmail(param)
                .pipe(catchError(() => observableOf(null
                )));
            }
            else {
              return this.api
                .getOutlookDeletedEmail(param)
                .pipe(catchError(() => observableOf(null)));
            }

          }),
          map((response: any) => {
            this.loading = false;
            if (response?.value === null) {
              this.totalRows = 0;
              return [];
            }
            this.totalRows = response?.value?.length;
            return response?.value;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
            this.btnLoading = false;
            this.toast.clear(this.toasters.toastId);
            this.loadingData = false;
            this.modalService.dismissAll();

          },
          error: (e) => {
            this.loading = false;
            this.btnLoading = false;
            this.loadingData = false;

            console.error(e);
          },
          complete: () => {
            this.loadingData = false;

            this.loading = false;
          },
        });
    } catch (e) {
      this.loading = false;
      this.loadingData = false;

      console.error(e);
    }
  }




  openDeleteGroup(content: any) {
    this.modalService
      .open(content, { size: 'md', scrollable: true, centered: true })
  }

  handleDeleteEvent() {
    console.log("emailToDelete", this.emailToDelete);
    this.btnLoading = true;
    let param = {
      "client_id": this.localStorage.getClientId(),
      "user": this.localStorage.getEmail(),
      "messageids": this.selectedIds
    }

    if (this.activeAction == 'deleted') {
      this.api.deleteOutlookEmailPermanently(param).subscribe({
        next: (res) => {
          setTimeout(() => {
            this.btnLoading = false;

            this.modalService.dismissAll();
            this.toast.success('Email deleted successfully');
          }, 1500);
          this.loadNewData();
          this.selectedIds = [];
        },
        error: (e) => {
          console.error(e);
          this.toast.error('Error while deleting email');
          this.btnLoading = false;
        }
      })
    } else {
      this.api.deleteOutlookEmail(param).subscribe({
        next: (res) => {
          setTimeout(() => {
            this.btnLoading = false;
            this.modalService.dismissAll();
            this.toast.success('Email deleted successfully');
          }, 1500);
          this.loadNewData();
          this.selectedIds = [];
        },
        error: (e) => {
          console.error(e);
          this.toast.error('Error while deleting email');
          this.btnLoading = false;
        }
      })
    }




  }

  openPreview(content: any, event: any) {
    console.log("event", event);
    this.selectedRow = event;
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this.selectedRow?.body?.content);
    this.modalService
      .open(content, { size: 'lg', scrollable: true, centered: true })
  }




  // Toggle selection for all rows


  // Update the selectedIds array when a row is selected/deselected
  updateSelection(element: any): void {
    console.log('Element:', element);

    const index = this.selectedIds.indexOf(element.id);

    if (index === -1) {
      this.selectedIds.push(element.id);
    } else {
      this.selectedIds.splice(index, 1);
    }

    console.log('Selected IDs:', this.selectedIds);
  }

  // Example function to delete selected group (you can replace it with your actual delete function)
  // openDeleteGroup(deleteGroup: any, id: number): void {
  //   console.log('Delete item with ID:', id);
  //   // Your delete logic here
  // }

}


function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}