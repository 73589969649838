import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyComponent } from './component/body/body.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { ToolbarComponent } from './component/toolbar/toolbar.component';
import { HeaderComponent } from './component/header/header.component';
import { RouterModule } from '@angular/router';
import { CustomRangePanelComponent, DateFilterComponent } from './ui/date-filter/date-filter.component';
import { HomeComponent } from '../modules/home/home.component';
import { AppointmentComponent } from '../modules/appointment/appointment.component';
import { LeadComponent } from '../modules/lead/lead.component';
import { UsersComponent } from '../modules/configuration/users/users.component';
import { materialModules } from './module';
import { ScheduleComponent } from '../modules/configuration/schedule/schedule.component';
import { CampaignManagementComponent } from '../modules/components/campaign-management/campaign-management.component';
import { SubcriberComponent } from '../modules/components/subcriber/subcriber.component';
import { CampaignComponent } from '../modules/components/campaign/campaign.component';
import { EmailSettingsComponent } from '../modules/configuration/email-settings/email-settings.component';
import { CreateAppointmentComponent } from '../modules/components/create-appointment/create-appointment.component';
import { AppointmentDetailComponent } from '../modules/components/appointment-detail/appointment-detail.component';
import { LeadFormComponent } from 'src/app/public/lead-form/lead-form.component';
import { NotFoundComponent } from 'src/app/public/not-found/not-found.component';
import { LeadsComponent } from '../modules/configuration/leads/leads.component';
import { RegistrationComponent } from '../modules/configuration/registration/registration.component';
import { WhatsappComponent } from '../modules/configuration/whatsapp/whatsapp.component';
import { ContactsComponent } from '../modules/contacts/contacts.component';
import { UserLogsComponent } from '../modules/user-logs/user-logs.component';
import { ExcelUploadComponent } from '../modules/components/excel-upload/excel-upload.component';
import { CommunicationModelComponent } from 'src/app/private/modules/components/communication-model/communication-model.component';
import { RingoverCallComponent } from 'src/app/private/modules/components/ringover-call/ringover-call.component';
import { LeadEmailComponent } from 'src/app/private/modules/components/lead-email/lead-email.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommunicationLogsComponent } from 'src/app/private/modules/components/communication-logs/communication-logs.component';
import { SalesMailHubComponent } from 'src/app/private/modules/configuration/sales-mail-hub/sales-mail-hub.component';
import { MailLeftActionsComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-left-actions/mail-left-actions.component';
import { MailRightTableComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-right-table/mail-right-table.component';
import { MailMiddleSectionComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-middle-section/mail-middle-section.component';
import { MailRightSectionComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-right-section/mail-right-section.component';
import { TicketsComponent } from 'src/app/private/modules/tickets/tickets.component';
import { CreateTicketComponent } from 'src/app/private/modules/components/ticket-module/create-ticket/create-ticket.component';
import { AddCommentComponent } from 'src/app/private/modules/components/ticket-module/add-comment/add-comment.component';
import { ClickOutsideDirective } from 'ng-multiselect-dropdown/click-outside.directive';
import { AddContactsGroupComponent } from '../modules/components/add-contacts-group/add-contacts-group.component';
import { ContactGroupListComponent } from '../modules/components/contact-group-list/contact-group-list.component';
import { CreataeWhatsappTemplateComponent } from '../modules/components/creatae-whatsapp-template/creatae-whatsapp-template.component';
import { CreateBroadcastCampaignComponent } from '../modules/components/create-broadcast-campaign/create-broadcast-campaign.component';
import { CreateContactGroupComponent } from '../modules/components/create-contact-group/create-contact-group.component';
import { CreateEmailTemplateComponent } from '../modules/components/create-email-template/create-email-template.component';
import { CreateEventComponent } from '../modules/components/create-event/create-event.component';
import { CsvDropzoneComponent } from '../modules/components/csv-dropzone/csv-dropzone.component';
import { PreviewEmailTemplateComponent } from '../modules/components/preview-email-template/preview-email-template.component';
import { PreviewWhatsappTemplateComponent } from '../modules/components/preview-whatsapp-template/preview-whatsapp-template.component';
import { RingOverComponent } from '../modules/components/ring-over/ring-over.component';
import { BroadcastCampaignComponent } from '../modules/configuration/broadcast-campaign/broadcast-campaign.component';
import { ContactGroupsComponent } from '../modules/configuration/contact-groups/contact-groups.component';
import { EmailTemplateComponent } from '../modules/configuration/email-template/email-template.component';
import { WhatsappTemplateComponent } from '../modules/configuration/whatsapp-template/whatsapp-template.component';
import { EventsComponent } from '../modules/events/events.component';
import { WoocommerceInventoryComponent } from '../modules/woocommerce-inventory/woocommerce-inventory.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ManageWooOrdersComponent } from '../modules/manage-woo-orders/manage-woo-orders.component';
import { EmailEditorModule } from 'angular-email-editor';


@NgModule({
  declarations: [
    HeaderComponent,
    BodyComponent,
    SidebarComponent,
    ToolbarComponent,
    DateFilterComponent,
    CustomRangePanelComponent,
    HomeComponent,
    LeadComponent,
    UsersComponent,
    CampaignManagementComponent,
    EmailSettingsComponent,
    SubcriberComponent,
    CampaignComponent,
    ScheduleComponent,
    AppointmentComponent,
    CreateAppointmentComponent,
    AppointmentDetailComponent,
    LeadsComponent,
    RegistrationComponent,
    LeadFormComponent,
    NotFoundComponent,
    WhatsappComponent,
    ContactsComponent,
    UserLogsComponent,
    ExcelUploadComponent,
    CommunicationModelComponent,
    RingoverCallComponent,
    LeadEmailComponent,
    CommunicationLogsComponent,
    SalesMailHubComponent,
    MailLeftActionsComponent,
    MailRightTableComponent,
    MailMiddleSectionComponent,
    MailRightSectionComponent,
    TicketsComponent,
    CreateTicketComponent,
    AddCommentComponent,
    WhatsappTemplateComponent,
    CreataeWhatsappTemplateComponent,
    EventsComponent,
    CreateEventComponent,
    EmailTemplateComponent,
    CreateEmailTemplateComponent,
    ContactGroupsComponent,
    CreateContactGroupComponent,
    AddContactsGroupComponent,
    BroadcastCampaignComponent,
    CreateBroadcastCampaignComponent,
    ContactGroupListComponent,
    CsvDropzoneComponent,
    PreviewWhatsappTemplateComponent,
    RingOverComponent,
    PreviewEmailTemplateComponent,
    WoocommerceInventoryComponent,
    ManageWooOrdersComponent

  ],
  imports: [
    CommonModule,
    RouterModule, 
    NgxIntlTelInputModule,
    CKEditorModule,
    NgMultiSelectDropDownModule,
    EmailEditorModule,
    ...materialModules

  ]
  ,
  exports:[
    HeaderComponent,
    BodyComponent,
    SidebarComponent,
    ToolbarComponent,
    
  ]
})
export class SharedModule { }
