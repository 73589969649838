import { Component } from '@angular/core';
declare var RingoverSDK: any;

@Component({
  selector: 'app-ring-over',
  templateUrl: './ring-over.component.html',
  styleUrls: ['./ring-over.component.scss']
})
export class RingOverComponent {
  simpleSDK: any;
  dialNumber: string = '';
  toNumber: string = '';
  message: string = '';
  fromNumber: string = '';
  callId: string = '';

  ngOnInit() {
    // Initialize Ringover SDK
    this.simpleSDK = new RingoverSDK();
    this.simpleSDK.on('changePage', this.logEvent);
    this.simpleSDK.on('ringingCall', this.logEvent);
    this.simpleSDK.on('answeredCall', this.logEvent);
    this.simpleSDK.on('hangupCall', this.logEvent);
    this.simpleSDK.on('login', this.logEvent);
    this.simpleSDK.on('logout', this.logEvent);
    this.simpleSDK.on('dialerReady', this.logEvent);
    this.simpleSDK.on('smsSent', this.logEvent);
    this.simpleSDK.on('smsReceived', this.logEvent);
    this.simpleSDK.on('callLogOpened', this.logEvent);

    // on call log opened

  }

  logEvent = (e: any) => {
    console.log(e);
    const p = document.createElement('pre');
    p.innerText = `${e.action} => ${JSON.stringify(e.data)}`;
    document.getElementById('get-events')?.appendChild(p);
  };

  generate() { this.simpleSDK.generate(); }
  destroy() { this.simpleSDK.destroy(); }
  show() { this.simpleSDK.show(); }
  hide() { this.simpleSDK.hide(); }
  toggle() { this.simpleSDK.toggle(); }
  reload() { this.simpleSDK.reload(); }
  logout() { this.simpleSDK.logout(); }
  dial() { this.simpleSDK.dial(this.dialNumber); }
  sendSMS() { this.simpleSDK.sendSMS(this.toNumber, this.message, this.fromNumber || null); }
  openCallLog() { 
    this.simpleSDK.openCallLog(this.callId); }
  changePage(event: any) { 
    console.log("xyz",event.target.value);
    this.simpleSDK.changePage(event.target.value); }
}
