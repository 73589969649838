import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EmailEditorComponent } from 'angular-email-editor';
import { ToastrService } from 'ngx-toastr';
import { EmailTemplateService } from 'src/app/private/services/email-tempate.service';
import { LeadService } from 'src/app/private/services/lead.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

declare var unlayer: any;
@Component({
  selector: 'app-create-email-template',
  templateUrl: './create-email-template.component.html',
  styleUrls: ['./create-email-template.component.scss']
})
export class CreateEmailTemplateComponent implements OnInit {

  @Input() type: any;
  @Input() templateDataToEdit: any;
  title = 'angular-email-editor';
  pageTitle = 'Create';

  htmlData = '';
  design = '';
  options = {
    // setting previw to mobile
    
  }

  btnLoading = false;

  formData = {
    templateName: '',
    templateCategory: '',
    status: ''
  };

  dynamicFields = ['firstName', 'lastName', 'email']; 
  editorLoadedFlag = false;
  allLeadFeilds: any = [];

  

  dataLoading = false;


  @ViewChild(EmailEditorComponent)
  private emailEditor!: EmailEditorComponent;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<any> = new EventEmitter();

  constructor(
    private toast: ToastrService,
    private api: EmailTemplateService,
    private localStorage: LocalStorageService,
    private api2: LeadService


  ) {

  }

  closeModal() {
    this.close.emit();
  }

  ngOnInit() {
    if (this.type === 'edit') {
      this.pageTitle = 'Edit';
      this.formData.templateName = this.templateDataToEdit.template_name;
      this.formData.templateCategory = this.templateDataToEdit.template_category;
      this.formData.status = this.templateDataToEdit.status;
      this.htmlData = this.templateDataToEdit.template_body;
    }
    this.getLeadsFields();
  }

  async onSubmit(form: any) {
    if (form.valid) {


      try {

        this.btnLoading = true;
        await this.exportHtml();

        const payload = {
          client_id: this.localStorage.getClientId(),
          template_body: this.htmlData,
          template_name: this.formData.templateName,
          template_category: this.formData.templateCategory,
          template_design: JSON.stringify(this.design),
          status: this.formData.status
        }

        if (this.type === 'edit') {

          const payload2 = {
            client_id: this.localStorage.getClientId(),
            template_id: this.templateDataToEdit.template_id,
            template_body: this.htmlData,
            template_design: JSON.stringify(this.design),
            template_name: this.formData.templateName,
            template_category: this.formData.templateCategory,
            status: this.formData.status
          }

          this.api.updateTemplate(payload2).subscribe(
            (res: any) => {
              this.reload.emit();
            },
            (error: any) => {
              console.log('Error:', error);
              this.btnLoading = false;
              this.toast.error('Error updating template');
            }
          );
        } else {
          this.api.createTemplate(payload).subscribe(
            (res: any) => {
              console.log('Response:', res);
              this.reload.emit();
            },
            (error: any) => {
              console.log('Error:', error);
              this.btnLoading = false;
              this.toast.error('Error creating template');
            }
          );
        }

      } catch (e) {
        console.log('Error:', e);
        this.btnLoading = false;
        this.toast.error('Error creating template');
      }

    } else {
      // If form is invalid, mark all fields as touched to show validation errors
      Object.keys(form.controls).forEach(field => {
        const control = form.controls[field];
        control.markAsTouched({ onlySelf: true });
      });
    }
  }


  // called when the editor is created
  editorLoaded() {
  }



  // called when the editor has finished loading
  editorReady() {

    setTimeout(() => {
      if (this.type === 'edit') {

        try {
          this.emailEditor.editor.loadDesign(
            JSON.parse(this.templateDataToEdit.template_design)
          );
        } catch (error) {
          console.error('Error loading design:', error);
        }
      }
    }, 500);
  }

  async exportHtml(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.emailEditor.editor.exportHtml((data: any) => {
        if (data && data.html) {
          console.log('Exported HTML:', data.design);
          this.htmlData = data.html;
          this.design = data.design;
          resolve(); // Resolve the promise once data is available
        } else {
          reject('Error exporting HTML');
        }
      });
    });
  }


  insertPlaceholder(field: string) {
    const placeholder = `{{${field}}}`;

    this.emailEditor.editor.exportHtml((data: any) => {
      const design = data.design;

      // Ensure the `rows` array exists in the design body
      if (!design.body.rows) {
        design.body.rows = [];
      }

      if (design.body.rows.length === 0) {
        // If no rows exist, add a new row
        const newRow = {
          id: `row_${Date.now()}`, // Generate a unique ID for the row
          cells: [1], // Define the cell count
          columns: [
            {
              id: `col_${Date.now()}`, // Generate a unique ID for the column
              contents: [
                {
                  type: 'text', // Text content type
                  values: {
                    text: `<span>${placeholder}</span>`, // The placeholder text wrapped in <p>
                  }
                }
              ],
              values: {
                backgroundColor: "",
                padding: "0px",
                border: {},
                borderRadius: "0px",
                _meta: {
                  htmlID: `u_column_${Date.now()}`, // Unique HTML ID
                  htmlClassNames: "u_column"
                }
              }
            }
          ],
          values: {
            displayCondition: null,
            columns: false,
            _styleGuide: null,
            backgroundColor: "",
            columnsBackgroundColor: "",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: "no-repeat",
              size: "custom",
              position: "center",
              customPosition: ["50%", "50%"]
            },
            padding: "0px",
            anchor: "",
            hideDesktop: false,
            _meta: {
              htmlID: `u_row_${Date.now()}`, // Unique HTML ID
              htmlClassNames: "u_row"
            },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true
          }
        };

        // Append the new row to the rows array
        design.body.rows.push(newRow);
      } else {
        console.log('i am here');
        // If rows exist, append the placeholder to the last column in the first row
        const firstRow = design.body.rows[0];
        const lastColumn = firstRow.columns[firstRow.columns.length - 1]; // Get the last column

        console.log('firstRow:', firstRow);
        console.log('lastColumn:', lastColumn);

        if (lastColumn.contents && lastColumn.contents.length > 0) {
          // Append the placeholder to the `text` of the last content item in the last column
          const lastContent = lastColumn.contents[lastColumn.contents.length - 1];
          if (lastContent.type === 'text' && lastContent.values && lastContent.values.text) {
            // lastContent.values.text += `<span>${placeholder}</span>`; // Append the placeholder wrapped in <p>
            const currentText = lastContent.values.text.replace(/<\/?p>/g, ''); // Remove any existing <p> tags if present
            lastContent.values.text = `<p>${currentText}<span>${placeholder}</span></p>`; // Append the placeholder inside the <p> tag
          } else {
            // If the last content is not text, add a new text content with the placeholder
            lastColumn.contents.push({
              type: 'text',
              values: {
                text: `<span>${placeholder}</span>`
              }
            });
          }
        } else {
          // If the last column has no contents, initialize it with the placeholder
          lastColumn.contents = [
            {
              type: 'text',
              values: {
                text: `<span>${placeholder}</span>`
              }
            }
          ];
        }
      }


      console.log('Modified Design:', design);

      this.emailEditor.editor.loadDesign(
        JSON.parse(JSON.stringify(design ? design : ''))
      );
    });
  }



  onDragStart(event: DragEvent, field: string) {
    console.log('Drag start event:', event);
    // Store the field value in the dataTransfer object
    event.dataTransfer?.setData('text', field);
    this.editorLoadedFlag = true;
  }

  // This method allows the drop by preventing the default behavior
  onDragOver(event: DragEvent) {
    console.log('Drag over event:', event);
    event.preventDefault();
   
  }

  onDragEnd(event: DragEvent) {
    console.log('Drag end event:', event);
    event.preventDefault();
    this.editorLoadedFlag = false;
  }

  // This method handles the drop and calls insertPlaceholder
  onDrop(event: DragEvent) {
    console.log('Drop event:', event);
    event.preventDefault();
    const field = event.dataTransfer?.getData('text'); // Retrieve the dragged field
    this.editorLoadedFlag = false;
    if (field) {
      this.insertPlaceholder(field); // Call the method to insert the placeholder
    }
  }

  getLeadsFields() {
    // id = this.localStorage.getClientId()
    let param = {
      client_id:  this.localStorage.getClientId(),
    };
    this.dataLoading = true;

    this.allLeadFeilds.push({key: 'Cotact Name', value: 'contact_name'});
    this.allLeadFeilds.push({key: 'Contact Email', value: 'contact_email'});
    this.allLeadFeilds.push({key: 'Contact Phone', value: 'contact_phone'});
    this.allLeadFeilds.push({key: 'Company Name', value: 'company_name'});
    this.allLeadFeilds.push({key: 'Lead Source', value: 'lead_source'});


    this.api2.getLeadsFields(param).subscribe({
      next: (data) => {
        this.dataLoading = false;

        const newFiled = data.fields?.map((element: any) => {
          return {
            key : element.label,
            value :  element.field_name
          }}
        );

        this.allLeadFeilds = [...this.allLeadFeilds, ...newFiled];
       

      },
      error: (e) => {
        this.dataLoading = false;
        console.error(e);
      },
    });
  }




}
