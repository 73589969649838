import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ContactGrouopService {

  constructor(private http: HttpClient) { }

  createContact_group(data:any): Observable<any> {
    return this.http.post(environment.createContactGroup,data);
  }

  updateContact_group(data:any): Observable<any> {
    return this.http.post(environment.updateContactGroup,data);
  }

  getLeadsRocord(data:any): Observable<any> {
    return this.http.post(environment.getLeadsRocord,data);
  }

  getAllContactGroups(data:any): Observable<any> {
    return this.http.post(environment.getAllContactGroups,data);
  }

  getAllTemplate(data:any): Observable<any> {
    return this.http.post(environment.getAllWhatsappTemplates,data);
  }

  getAppId(data:any): Observable<any> {
    return this.http.post(environment.getAppId,data);
  }

  createTemplate(data:any): Observable<any> {
    return this.http.post(environment.createWhatsappTemplate,data);
  }


  getAllLanguages(): Observable<any> {
    return this.http.post(environment.getAllLanguages,{});
  }

  updateTemplate(data:any): Observable<any> {
    return this.http.post(environment.updateWhatsappTemplate,data);
  }

  deleteTemplate(data:any): Observable<any> {
    return this.http.post(environment.deleteWhatsappTemplate,data);
  }


  getAllLeads(data:any): Observable<any> {
    return this.http.post(environment.getAllLeads,data);
  }
  
  getAllContacts(data:any): Observable<any> {
    return this.http.post(environment.getAllContactWithCount,data);
  }

  createContactGroupList(data:any): Observable<any> {
    return this.http.post(environment.createContactGroupList,data);
  }

  getAllContactGroupList(data:any): Observable<any> {
    return this.http.post(environment.getAllContactGroupList,data);
  }

  getLeadAndContactId(data:any): Observable<any> {
    return this.http.post(environment.getLeadAndContactId,data);
  }

  deleteContactGroup(data:any): Observable<any> {
    return this.http.post(environment.deleteContactGroup,data);
  }
}
