import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { ContactGrouopService } from 'src/app/private/services/contact-groups.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

export interface DataElement {
  id: number;
  name: string;
}

const LEADS_DATA: DataElement[] = [
  { id: 1, name: 'Lead 1' },
  { id: 2, name: 'Lead 2' },
  { id: 3, name: 'Lead 3' },
];

const CONTACTS_DATA: DataElement[] = [
  { id: 1, name: 'Contact 1' },
  { id: 2, name: 'Contact 2' },
  { id: 3, name: 'Contact 3' },
];


@Component({
  selector: 'app-add-contacts-group',
  templateUrl: './add-contacts-group.component.html',
  styleUrls: ['./add-contacts-group.component.scss']
})


export class AddContactsGroupComponent {


  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  @Input() selectedGroupId: any;
  

  totalRows = 0;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentData = [];
  currentContactData = [];


  totalContactRows = 0;
  currentContactPage = 0;
  pageContactSize = 10;
  @ViewChild(MatPaginator)
  paginatorContact!: MatPaginator;

  initialLoad = true;
  initialContactLoad = true;

  constructor(
    private toast: ToastrService,
    private api: ContactGrouopService,
    private localStorage: LocalStorageService,
    private userApi: UsersService,
    private cdr: ChangeDetectorRef
  ) {

  }

  displayedColumns = ['select', 'name', 'email', 'phone'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  selectedLeadIds: number[] = [];
  isAllLeadsSelected = false;

  dataSource2: MatTableDataSource<any> = new MatTableDataSource();
  selection2 = new SelectionModel<any>(true, []);
  selectedContactIds: number[] = [];
  isAllContactsSelected = false;


  GroupListData: any = [];

  ngOnInit() {
    this.GroupListData = [];
    this.getLeadAndContactId();
  }

  getLeadAndContactId() {
    try {
      const payload = {
        contact_group_id: this.selectedGroupId
      }
      this.api.getLeadAndContactId(payload).subscribe(
        (response) => {
          this.GroupListData = response;
          this.GroupListData.forEach((element: any) => {
            if (element.lead_id) {
              this.selectedLeadIds.push(element.lead_id);
            }
          });
          this.isAllSelected();
        },
        (error) => {
          console.log(error);
          this.toast.error("Error while fetching contact group");
        }
      );

    } catch (e) {
      console.error(e);
    }
  }

  ngAfterViewInit() {
    console.log("tabl column : ", this.displayedColumns);
    this.getLeads();
    this.getAllContacts();
  }

  getAllContacts() {
    try {
      // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.paginatorContact.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            const param = {
              "where": {
                "client_id": this.localStorage.getClientId()
              },
              "page": this.paginatorContact.pageIndex,
              "limit": this.paginatorContact.pageSize,

            }

            return this.api
              .getAllContacts(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalContactRows = response.count;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
            console.log("isAllContactsSelected", data);
            this.dataSource2 = new MatTableDataSource<any>(data);
            this.initialContactLoad = false;
            this.isAllSelected2();
            this.currentContactData = data;
            
           
          },
          error: (e) => {
            console.error(e);
            this.toast.error("Error while fetching leads");
            this.initialContactLoad = false;
          },
          complete: () => {
          },
        });
    } catch (e) {
      console.error(e);
    }
  }

  getLeads() {
    try {
      // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {

            const param = {
              "where": {
                "client_id":  this.localStorage.getClientId(),
        
              },
              "filter": null,
              "page": this.paginator.pageIndex,
              "limit": this.paginator.pageSize,
              "order": {
                "col": "\"leadsrecord\".\"updatedAt\"",
                "dir": "DESC"
              }
            }

            return this.api
              .getAllLeads(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
            this.currentData = data;
            this.initialLoad = false;

            this.isAllSelected();

            console.log("gaurav", this.selectedLeadIds)

          },
          error: (e) => {
            console.error(e);
            this.toast.error("Error while fetching leads");
            this.initialLoad = false;
          },
          complete: () => {
          },
        });
    } catch (e) {
      console.error(e);
    }
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {

    if (this.initialLoad) {
      return;
    }

    // if selectedLeadIds is contain by currentData then return true
    console.log("selectedLeadIds", this.selectedLeadIds);
    console.log("currentData", this.currentData);
    const result = this.currentData.every((x: any) => this.selectedLeadIds.includes(
      parseInt(x.lead_id, 10)
    ) || this.selection.isSelected(x) || !!this.GroupListData.find((y: any) => y.lead_id == x.lead_id));

    if (result) {
      this.isAllLeadsSelected = true;
    }else{
      this.isAllLeadsSelected = false;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(event: any) {

    // this.isAllLeadsSelected ?
    // this.selection.clear() :
    // this.dataSource.data.forEach(row => this.selection.select(row));

    if(this.isAllLeadsSelected){
      this.selection.clear();

      this.dataSource.data.forEach(row => {
        // remove from selectedLeadIds
        this.selectedLeadIds = this.selectedLeadIds.filter((x) => x != row.lead_id);
      }
      );

      console.log("selectedLeadIds", this.selectedLeadIds);
      
      this.isAllLeadsSelected = false;
    }else{
      this.dataSource.data.forEach(row => {
        if (this.selectedLeadIds.includes(
          parseInt(row.lead_id, 10)
        )) {
          return;
        }
        this.selection.select(row);
        this.addToLeadsIds(row);
      });
      this.isAllLeadsSelected = true;
    }

    
  }

  isAllSelected2() {
    console.log("isAllContactsSelected", this.isAllContactsSelected);
    if (this.initialContactLoad) {
      return;
    }

   try{
    const result = this.currentContactData.every((x: any) => this.selectedContactIds.includes(
      parseInt(x.contact_id, 10)
    ) || this.selection2.isSelected(x) || !!this.GroupListData.find((y: any) => y.contact_id == x.contact_id));

    if (result) {
      this.isAllContactsSelected = true;
    }else{
      this.isAllContactsSelected = false;
    }
   }catch(e){
      console.error("isAllContactsSelected",e);
   }

    console.log("isAllContactsSelected", this.isAllContactsSelected);
  }



  masterToggle2() {
    if(this.isAllContactsSelected){
      this.selection2.clear();

      this.dataSource2.data.forEach(row => {
        // remove from selectedLeadIds
        this.selectedContactIds = this.selectedContactIds.filter((x) => x != row.contact_id);
      }
      );

      console.log("selectedContactIds", this.selectedContactIds);
      
      this.isAllContactsSelected = false;
    }else{
      this.dataSource2.data.forEach(row => {
        if (this.selectedContactIds.includes(
          parseInt(row.contact_id, 10)
        )) {
          return;
        }
        this.selection2.select(row);
        this.addToContactIds(row);
      });
      this.isAllContactsSelected = true;
    }
  }




  isSubmitted = false;

  btnLoading = false;

  closeModal() {
    this.close.emit();
  }

  selectedIds: number[] = [];



  onSubmit() {

    let contacts: any = []

    this.selection.selected.forEach((element) => {

      if (this.GroupListData.find((x: any) => x.lead_id == element.lead_id)) {
        return;
      }

      contacts.push({
        name: element.contact_name,
        source: element.lead_source,
        phone: element.contact_phone,
        email: element.contact_email,
        lead_id: element.lead_id
      })
    })

    console.log("selection", this.selection2.selected);
    console.log("GroupListData", this.GroupListData);
    this.selection2.selected.forEach((element) => {
      
      if (this.GroupListData.find((x: any) => x.contact_id == element.contact_id)) {
        return;
      }

      contacts.push({
        name: element.first_name + " " + element.last_name,
        source: element.source,
        phone: element.phone,
        email: element.email,
        contact_id: element.contact_id
      })
    })

    if (contacts.length === 0) {
      this.toast.error("Please select atleast one contact");
      return;
    }

    this.btnLoading = true;
    const payload = {
      client_id: this.localStorage.getClientId(),
      contact_group_id: this.selectedGroupId || 1,
      contacts: contacts
    }


    console.log("payload", payload);
    this.api.createContactGroupList(payload).subscribe(
      (response) => {
        this.formSubmit.emit();
      },
      (error) => {
        console.log(error);
        this.toast.error("Error while creating contact group");
        this.btnLoading = false;
      }
    );
  }


  // add-contacts-group.component.ts

  isRowSelected(row: any): boolean {
   const result = this.selectedLeadIds.includes(
      parseInt(row.lead_id, 10)
   ) || this.selection.isSelected(row) || !!this.GroupListData.find((x: any) => x.lead_id == row.lead_id);

    return result;
  }

  isRowSelected2(row: any): boolean {
    const result = this.selectedContactIds.includes(
      parseInt(row.contact_id, 10)
    ) || this.selection2.isSelected(row
    ) || !!this.GroupListData.find((x: any) => x.contact_id == row.contact_id);
    return result;
  }

  isDisabled(row: any): boolean {
    return !!this.GroupListData.find((x: any) => x.lead_id == row.lead_id);
  }

  isDisabled2(row: any): boolean {
    return !!this.GroupListData.find((x: any) => x.contact_id == row.contact_id);

  }


  addToLeadsIds(row: any) {
  
    if (this.selectedLeadIds.includes(row.lead_id)) {
      this.selectedLeadIds = this.selectedLeadIds.filter((x) => x !== row.lead_id);
      return;
    } else {
      this.selectedLeadIds.push(parseInt(row.lead_id, 10));
    }

    console.log("selectedLeadIds", this.selectedLeadIds);
    this.isAllSelected();
  }

  addToContactIds(row: any) {
    if (this.selectedContactIds.includes(row.contact_id)) {
      this.selectedContactIds = this.selectedContactIds.filter((x) => x !== row.contact_id);
      return;
    } else {
      this.selectedContactIds.push(parseInt(row.contact_id, 10));
    }
    this.isAllSelected2();
  }
}



function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}