import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { LeadService } from 'src/app/private/services/lead.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-communication-logs',
  templateUrl: './communication-logs.component.html',
  styleUrls: ['./communication-logs.component.scss']
})
export class CommunicationLogsComponent {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() communicationData: any;
  initialLoad: boolean = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();

  @ViewChild('noteDialog')
  noteDialog!: ModalDirective;


  btnLoading = false;

  LeadLogs: any[] = [];
  totalRows = 0;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  curentNote: any;

  btnLoading3: boolean = false;

  cuurentSelectedData: any;

  displayedColumns: string[] = [
    "communication_type",
    "date_of_communication",
    "time_of_communication",
    "status",
    "callDuration",
    "note"  ];


  constructor(
    private toast: ToastrService,
    private api: LeadService,
    private localStorage: LocalStorageService,
    private modalService: NgbModal

  ) { }

  ngAfterViewInit() {
    this.loadData();
  }

 

  loadData() {

    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            console.log("client_id", this.localStorage.getClientId())
            const data = {
              "where": {
                "client_id": this.localStorage.getClientId(),
                "lead_id": this.communicationData.lead_id
              },
              "filter": null,
            "page": this.paginator.pageIndex * this.paginator.pageSize,
        "limit": this.paginator.pageSize,
              "order": {
                "col": "\"communication_history_log\".\"createdAt\"",
                "dir": "DESC"
              }
            }

            return this.api.getCommunicationHisLogs(data)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.initialLoad = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            console.log("data communication log", data)
            this.dataSource = new MatTableDataSource<any>(data?.rows || []);
          },
          error: (e) => {
            this.initialLoad = false;
            console.error(e);
          },
          complete: () => { 
            this.initialLoad = false;
          },
        });
    } catch (e) {
      this.initialLoad = false;
      console.error(e);
    }
  }

  updateData() {
    try {
      console.log("client_id", this.localStorage.getClientId());
      const data = {
        "where": {
          "client_id": this.localStorage.getClientId(),
          "lead_id": this.communicationData.lead_id
        },
        "filter": null,
        "page": this.paginator.pageIndex * this.paginator.pageSize,
        "limit": this.paginator.pageSize,
        "order": {
          "col": "\"communication_history_log\".\"createdAt\"",
          "dir": "DESC"
        }
      };
  
      this.api.getCommunicationHisLogs(data)
        .pipe(
          catchError(() => observableOf(null)),
          map((response: any) => {
            this.initialLoad = false;
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            console.log("data communication log", data);
            this.dataSource = new MatTableDataSource<any>(data?.rows || []);
            this.btnLoading3 = false;

            // close only note dialog

            this.noteDialog.hide();

            this.toast.success('Note updated successfully');
          },
          error: (e) => {
            this.initialLoad = false;
            console.error(e);
          },
          complete: () => { 
            this.initialLoad = false;
          }
        });
    } catch (e) {
      this.initialLoad = false;
      console.error(e);
    }
  }
  



  formatDate(time: string): string {
    const date = new Date(time);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',  // "long" for full month name (e.g., "August"), "short" for abbreviated (e.g., "Aug")
      day: 'numeric'  // "numeric" for day of the month as a number
    };
    return date.toLocaleDateString('en-US', options);

  }

  closeModal() {
    this.close.emit();
  }


  openNoteDialog(element:any, note: any, data: any) {
    this.curentNote = note;
    this.cuurentSelectedData = data;
    this.modalService.open(element, { size: 'md', scrollable: true, centered: true });
  }

  updateNote(){

    console.log("update note", this.curentNote)
    console.log("update note", this.cuurentSelectedData)
    this.btnLoading3 = true;
    const payload = {
      "note": this.curentNote,
      "id": this.cuurentSelectedData.id
    }
    this.api.UpdateCommunicationHisLog(payload).subscribe({
      next: (res: any) => {
        this.updateData();        
      },
      error: (err: any) => {

        this.toast.error('Failed to update note');
        this.modalService.dismissAll();
        this.btnLoading3 = false;
      }
    });
  }


}

function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}

