import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { WooCommerceService } from '../../services/woocommerce.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manage-woo-orders',
  templateUrl: './manage-woo-orders.component.html',
  styleUrls: ['./manage-woo-orders.component.scss'],
  providers:[CurrencyPipe]
})
export class ManageWooOrdersComponent {
  displayedColumns: string[] = ['id','order', 'date', 'status','total','payment_method','invoice','action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  totalItems: number = 0;
  perPage: number = 10;
  @ViewChild("productForm")
  productForm!: NgForm;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  searchTerm: string = ''; // Dynamic search term
  categoryId: number | null = null; // Dynamic category filter
  paginator!: MatPaginator;
  filterArray:any = []
  ordersArray:any = []
  orderData:any = {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

  }
  searchFilter(event:any){

  }
  dateFilter(event:any) {

  }
  openPdfInvoice(order_id:any){
   
    const url = this.api.pdfInvoice(order_id)
    window.open(url, "_blank");

  }
  openDelete(content:any,row:any){

  }
  getOrderById(content:any,row:any){
    this.orderData = row
    this.modalService
    .open(content, { size: 'xl', scrollable: true })
    .result.then(
      (result) => {
       
      },
      (reason) => {
        
      }
    );
  }
  constructor(private currencyPipe: CurrencyPipe,private api:WooCommerceService,
    private cdr: ChangeDetectorRef, 
    private modalService: NgbModal,
    private el: ElementRef,
    private toast:ToastrService
    
  ){
  
    
  }
  ngOnInit(){
  this.loadOrders(1)
  }
  onPageChange(event: any): void {
    const newPageIndex = event.pageIndex + 1; // MatPaginator uses 0-based index
    this.loadOrders(newPageIndex);
  }
  updateOrder(){
   let order = {
    status:this.orderData.status
   } 
    this.api.updateOrders(this.orderData.id,order).subscribe({
      next:(res)=>{
        if(res){
          this.toast.success("Order Updated Successfully")
        }
    
      },
      error:(e)=>{}
    })
  }
  loadOrders(page:any) {
    let dynamicParams = {
   
      page,
      per_page: this.perPage,
    }
    this.api.getOrders(dynamicParams).subscribe({
      next: (response) => {
        this.dataSource.data = response.body || [];
        this.totalItems = parseInt(response.headers.get('x-wp-total'));
      },
      error: (error) => console.error('Error fetching products:', error),
    });
  }
}
