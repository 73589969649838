import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageCampaignService {

  constructor(private http: HttpClient) { }

  getAllManangeCampaign(data:any): Observable<any> {
    return this.http.post(environment.getAllManangeCampaign,data);
  }

  getAllWhatsappTemplatesGupshup(data:any): Observable<any> {
    return this.http.post(environment.getAllWhatsappTemplatesGupshup,data);
  }

  getAllEmailTemplates(data:any): Observable<any> {
    return this.http.post(environment.getAllEmailTemplates,data);
  }

  getAllContactGroups(data:any): Observable<any> {
    return this.http.post(environment.getAllContactGroups,data);
  }

  createCampaign(data:any): Observable<any> {
    return this.http.post(environment.createCampaign,data);
  }

  updateCampaign(data:any): Observable<any> {
    return this.http.post(environment.updateCampaign,data);
  }
  


  runCampaign(data:any): Observable<any> {
    return this.http.post(environment.runCampaign,data);
  }

  deleteCampaign(data:any): Observable<any> {
    return this.http.post(environment.deleteCampaign,data);
  }

}
