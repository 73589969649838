import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailEditorComponent } from 'angular-email-editor';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { EmailTemplateService } from 'src/app/private/services/email-tempate.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailTemplateComponent {


  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  totalRows = 0;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  btnLoading = false;
  initialLoad = true;
  processedData: any;
  templateToDelete: any;
  templateEdit: any;

  displayedColumns: string[] = [
    "Client_ID",
    "Template_Name",
    "Template_Category",
    "Template_Body",
    "Status",
    "Action"
  ];

  constructor(
    private toast: ToastrService,
    private api: EmailTemplateService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private userApi: UsersService,
    private sanitizer: DomSanitizer
  ) {

  }

  ngAfterViewInit() {
    console.log("tabl column : ", this.displayedColumns);
    this.loadData();
  }


  loadData(type?: string) {

    try {
      // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            console.log("client_id", this.localStorage.getClientId())
            let param = {
              // "client_id":  this.localStorage.getClientId(),
              where:{
                "client_id": this.localStorage.getClientId(),
              },
             
              "template_id":1,
              "pageNo": this.paginator.pageIndex + 1,
              "pageSize": this.paginator.pageSize,
            }

            return this.api
              .getAllTemplate(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.initialLoad = false;
            this.dataSource = new MatTableDataSource<any>(data);

            if(type === "delete") {
              
              this.modalService.dismissAll();
              this.btnLoading = false;
              this.toast.success("Template Deleted Successfully");

            }

            if(type === 'Create'){
              this.modalService.dismissAll();
              this.btnLoading = false;
              this.toast.success("Template Created Successfully");
            }

            if(type === 'Update'){
              this.modalService.dismissAll();
              this.btnLoading = false;
              this.toast.success("Template Updated Successfully");
            }
          },
          error: (e) => {
            this.initialLoad = false;
            console.error(e);
          },
          complete: () => {
          },
        });
    } catch (e) {
      console.error(e);
    }
  }


  open(content: any) {
    this.modalService
      .open(content, { size: 'xl', scrollable: true, centered: true })
  }

  handleOpenPreviewDialog(content: any,template: any) {
    this.modalService.open(content, {centered: true, size:'lg' });
    this.processedData =  this.sanitizer.bypassSecurityTrustHtml(template.template_body); 
  }
    

  handleOpenEditDialog(content: any, template: any) {
    console.log("template", template);
    this.templateEdit = template;
    this.modalService.open(content, { size: 'xl', scrollable: true, centered: true });
  }

  handleDeleteEvent() {

    this.btnLoading = true;
  
    const payload = {

      template_id: this.templateToDelete.template_id
    }
  
      this.api.deleteTemplate(payload).subscribe((response:any) => {
        if (response) {
          this.loadData("delete");
        }
      },
      (error:any) => {
        this.btnLoading = false;
        this.toast.error("Something went wrong");
      });
    
    }

  handleOpenDeleteTemplateDialog(content: any, element: any) {
    this.modalService
      .open(content, { size: 'md', centered: true })

    this.templateToDelete = element;
  }
  

}

function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}