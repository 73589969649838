import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/private/services/product.service';
import { UploadService } from 'src/app/private/services/upload.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Input() formAction: string = 'Add';
  @Input() users: any;
  @Input() productCategories: any;
  @Input() editData: any;
  dropdownSettings = {};
  tagArray: any[] = [
    "Sales Tax - 0.0 %",
    "Vat - 0.0 %"
  ];





  files: any[] = [];
  loading = false;
  imgTitle = 'Upload Product Images';
  uploadedUrls: string[] = [];
  totalFiles: any[] = [];
  hoverIndex: number = -1;
  hoverIndex2: number = -1;
  imageLoading = false;

  usageUnits = [
    'Box',
    'Carton',
    'Dozen',
    'Each',
    'Hour(s)',
    'Impressions',
    'Lb',
    'MPack',
    'Pages',
    'Pieces',
    'Quantity',
    'Reams',
    'Sheet',
    'Spiral Binder',
    'Square Feet'
  ]

  manufacturers = [
    "Gravitas AI",
  ]

  constructor(
    private api: ProductService,
    private toast: ToastrService,
    private localStorage: LocalStorageService,
    private uploadService: UploadService,
  ) { }


  product : any = {
    product_name: null,
    product_owner: this.localStorage.getUserId(),
    product_code: null,
    unit_price: null,
    tax: null,
    taxable: null,
    commission_rate: null,
    product_image: null,
    sales_start_date: null,
    sales_end_date: null,
    product_desc: null,
    product_other_images: [],
    quantity_in_stock: null,
    product_active: null,
    product_category: null,
    support_end_date: null,
    support_start_date: null,
    manufacturer: null,
    usage_unit: null,
    handler_id: this.localStorage.getUserId()
  };

  calculatedResult: number | null = null;

  calculateExpression() {
    const input = this.product.unit_price.trim();

    if (/^[\d+\-*/.() ]+$/.test(input)) {
      try {
        this.calculatedResult = eval(input); // Evaluate only safe mathematical expressions
      } catch {
        this.calculatedResult = null;
      }
    } else {
      this.calculatedResult = null;
    }
  }

  updateInputValue() {
    if (this.calculatedResult !== null) {
      this.product.unit_price = this.calculatedResult.toString();
      this.calculatedResult = null; // Hide result after updating input
    }
  }

  ngOnInit(): void {
    if (this.formAction === 'Update') {
      console.log('Edit data:', this.editData);
      const productData = {
        product_name: this.editData.product_name,
        product_owner: this.editData.product_owner,
        product_code: this.editData.product_code,
        unit_price: this.editData.unit_price,
        tax: this.editData.tax,
        taxable: this.editData.taxable,
        commission_rate: this.editData.commission_rate,
        product_image: this.editData.product_image,
        sales_start_date: this.editData.sales_start_date ? new Date(this.editData.sales_start_date)?.toISOString().split('T')[0] : null,
        sales_end_date: this.editData.sales_end_date ? new Date(this.editData.sales_end_date)?.toISOString().split('T')[0] : null,
        product_desc: this.editData.description,
        product_other_images: this.editData.product_other_images,
        quantity_in_stock: this.editData.quantity_in_stock,
        product_active: this.editData.product_active,
        product_category: this.editData.cat_id,
        support_end_date: this.editData.support_end_date ? new Date(this.editData.support_end_date)?.toISOString().split('T')[0] : null,
        support_start_date: this.editData.support_start_date ? new Date(this.editData.support_start_date)?.toISOString().split('T')[0] : null,
        manufacturer: this.editData.manufacturer,
        usage_unit: this.editData.usage_unit,
        handler_id: this.editData.handler_id
      }

      console.log('Product data:', productData);

      this.product = productData;
    }
  }

  handleOtherImagesUpload(event: any) {
    const files = event.target.files;
    this.product.product_other_images = files;
  }

  saveProduct(isValid: any) {
    if (isValid) {
      console.log('Product saved:', this.product);

      this.btnLoading = true;

      const commonPayload: any = {
        // product_id: this.formAction === 'Update' ? this.editData.product_id : '',
        client_id: this.localStorage.getClientId(),
        cat_id: Number.isInteger(this.product.product_category)
          ? this.product.product_category
          : parseInt(this.product.product_category, 10) || 0,
        product_name: this.product.product_name,
        product_other_images: this.formAction === 'Update'
          ? [...this.uploadedUrls, ...this.product.product_other_images]
          : this.uploadedUrls,
        product_owner: this.product.product_owner,
        product_code: this.product.product_code,
        unit_price: this.product.unit_price,
        tax: this.product.tax,
        taxable: this.product.taxable,
        commission_rate: this.product.commission_rate,
        product_image: this.product.product_image,
        sales_start_date: this.product.sales_start_date,
        sales_end_date: this.product.sales_end_date,
        quantity_in_stock: this.product.quantity_in_stock,
        product_active: this.product.product_active,
        product_category: this.product.product_category,
        support_end_date: this.product.support_end_date,
        support_start_date: this.product.support_start_date,
        manufacturer: this.product.manufacturer,
        handler_id: this.product.handler_id,
        description: this.product.product_desc,
        usage_unit: this.product.usage_unit
      };

      if (this.formAction === 'Update') {
        commonPayload['product_id'] = this.editData.product_id;
      }

      const apiCall = this.formAction === 'Update'
        ? this.api.updateProduct(commonPayload)
        : this.api.addProduct(commonPayload);

      apiCall.subscribe({
        next: (response: any) => {
          console.log('Response:', response);
          const message = this.formAction === 'Update'
            ? 'Product updated successfully'
            : 'Product added successfully';
          this.toast.success(message);
          this.reload.emit();
          this.btnLoading = false;
          this.closeModal();
        },
        error: (error: any) => {
          console.error('Error:', error);
          this.toast.error('An error occurred');
          this.btnLoading = false;
        }
      });
    } else {
      console.log('Form is invalid');
    }
  }

  onFileSelected(event: any) {
    const selectedFiles: FileList = event.target.files;
    const totalFiles = selectedFiles.length; // Track the number of files
    let processedFiles = 0; // Counter to track how many files have been processed

    if (totalFiles > 0) {
      this.files = []; // Clear the existing files
      for (let i = 0; i < totalFiles; i++) {
        const file = selectedFiles[i];
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.files.push({ file, preview: e.target.result }); // Store file and its preview
          this.totalFiles.push({
            file: file,
            preview: e.target.result
          });
          processedFiles++; // Increment the counter

          // Call uploadImages once all files are processed
          if (processedFiles === totalFiles) {
            this.uploadImages();
          }
        };

        reader.readAsDataURL(file); // Generate preview
      }
    } else {
      this.toast.error('No files selected for upload');
    }


  }

  isImage(file: string): boolean {
    return /\.(jpg|jpeg|png|gif|webp)$/i.test(file);
  }

  removeFile(index: number) {
    this.totalFiles.splice(index, 1);
    this.uploadedUrls.splice(index, 1);
  }

  handleUploadImagetoS3(event: any) {
    const files = event.target.files;
    // upload to s3 and set url to product_image
    const formData = new FormData();
    const folder = `public/hospital-001/$001/profile`;
    formData.append('file', files[0]);
    formData.append('folders', folder);
    formData.append('bucket', 'emruploads');
    this.imageLoading = true;

    this.uploadService.uploadPatientProfile(formData).subscribe((res: any) => {
      console.log('Response:', res);
      this.product.product_image = res.url;
      this.imageLoading = false;
    }, (err: any) => {
      this.imageLoading = false;
      console.error('Error:', err);
    });

  }


  uploadImages() {
    if (this.files.length > 0) {
      this.loading = true; // Set loading to true when uploading starts
      this.imgTitle = 'Uploading...';

      const uploadPromises = this.files.map((fileObj) => {
        const formData = new FormData();
        const folder = `public/hospital-001/$001/profile`;
        formData.append('file', fileObj.file);
        formData.append('folders', folder);
        formData.append('bucket', 'emruploads');

        return this.uploadService.uploadPatientProfile(formData).toPromise();
      });

      Promise.all(uploadPromises)
        .then((responses) => {
          responses.forEach((res: any) => {
            if (res) {
              this.uploadedUrls.push(res.url);
            }
          });
        })
        .catch((error) => {
          console.error('Error uploading images:', error);
        })
        .finally(() => {
          this.loading = false; // Set loading to false after all uploads are done
          this.imgTitle = 'Upload Product Images';
        });

      console.log('Files:', this.totalFiles);
    } else {
      this.toast.error('No files selected for upload');
    }
  }


  removeFilefromProdcutImage(index: number) {
    this.product.product_other_images.splice(index, 1);
  }


  btnLoading = false;

  closeModal(): void {
    this.close.emit();
  }

  getNameFromUrl(url: string): string {
    // Use the URL split by "/" and get the last part
    return url.split('/').pop() || '';
  }

}
