import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  openSidebar: boolean = true;
  expand: boolean = false;
  sidepanel: any = "hide_panel";
  role: any;
  permissions: any = [];
  modules: any = [];
  settingActive: boolean = false;
  pathsToCheck = [
    'configuration-leads',
    'configuration-users',
    'configuration-email',
    'configuration-whatsapp-template',
    'configuration-email-template',
    'contact-groups',
    'pipeline',
    'category',
  ];

  pathtoCheckWoo = [
    'woocommerce-orders',
    'woocommerce-products',
    'woocommerce-inventory'
  ];

  wooCommerce: boolean = false;
  wooCommerceActive: boolean = false;

  woo: boolean = false;
  set: boolean = false;
  constructor(private localStorage: LocalStorageService,
    private router: Router
  ) {

  }


  getfeatureAccess(module_name: any, feature_name: any) {
    //console.log(this.permissions)
    let feature = [];
    let module = [];
    module = this.permissions.filter((item: any) => {
      return item.module_name == module_name;
    })
    if (module.length > 0) {
      feature = module[0]['features'].filter((item: any) => {
        return item.name == feature_name && item.active == true;
      })
    }

    return (module[0] && feature[0]) ? true : false;

  }


  getAccess(module_name: any) {

    const clientId = this.localStorage.getClientId();

    if(clientId == 70 && module_name == 'sales-mail-hub'){
      return true}
      

    //console.log("chetan")
    //console.log(this.permissions)
    let result = this.permissions.filter((item: any) => {
      return item.module_name == module_name && item.active == true;
    })
    //console.log(result[0])
    return (result[0]) ? true : false;
    //return true;
  }

  ngOnInit() {

    this.role = this.localStorage.getRole();
    this.permissions = JSON.parse(this.localStorage.getPermissions());
    this.modules = JSON.parse(JSON.stringify(this.localStorage.getFeatureModules()));

    console.log("sda", this.permissions)
    console.log("sda", this.modules)


    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {


        this.settingActive = this.pathsToCheck.some(path => event.url.includes(path));
        this.wooCommerce = this.pathtoCheckWoo.some(path => event.url.includes(path));
      });

  }

  ngAfterViewInit() {

    console.log("Event 123", this.router.url);


    const urlToCheck = this.router.url;

    this.settingActive = this.pathsToCheck.some(path => urlToCheck.includes(path));
    this.wooCommerce = this.pathtoCheckWoo.some(path => urlToCheck.includes(path));

  }


  getFeatureModuleAccess(module_name: any) {

    const clientId = this.localStorage.getClientId();

    if(clientId == 70 && module_name == 'sales-mail-hub'){
      return true}

    return (this.modules.includes(module_name) ? true : false);
  }

  togglePanel() {
    this.settingActive = true;
    this.sidepanel = "show_panel";
    this.wooCommerceActive = false;
  }

  togglePanelWoo() {
    this.sidepanel = "show_panel";
    this.wooCommerceActive = true;
    this.settingActive = false;
  }


  activePanel() {

    if(this.wooCommerceActive){
      this.woo = true;
      this.wooCommerceActive = true;
      this.sidepanel = "show_panel";
    }else{
      this.set = true;
      this.sidepanel = "show_panel";
      this.settingActive = true;
    }

   
  }

  decactivePanel() {

   
    if(this.wooCommerceActive || this.settingActive){
      this.sidepanel = "hide_panel";

      const urlToCheck = this.router.url;
  
      this.settingActive = this.pathsToCheck.some(path => urlToCheck.includes(path));
  
      this.wooCommerceActive = this.pathtoCheckWoo.some(path => urlToCheck.includes(path));
    }

  }
  togglePanelOff() {
    if(this.settingActive){
    this.sidepanel = "hide_panel";
    }
  }

  togglePanelOffWoo() {
    if(this.wooCommerceActive){
    this.sidepanel = "hide_panel";
    }
  }

  openSubmenu() {
    this.expand = true;
  }
  closeSubmenu() {
    this.expand = false;
  }
  showSubmenu(itemEl: HTMLElement) {
    itemEl.classList.toggle("showMenu");
  }
}
