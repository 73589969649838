import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageCampaignService } from 'src/app/private/services/manage-campaign.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-create-broadcast-campaign',
  templateUrl: './create-broadcast-campaign.component.html',
  styleUrls: ['./create-broadcast-campaign.component.scss']
})
export class CreateBroadcastCampaignComponent {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  @Input() type: string = 'Create';
  btnLoading = false;

  campaignForm: FormGroup;
  broadcastType: string = '';
 
  @Input() emailTemplates: any[] = [];
  @Input() whatsappTemplates: any[] = [];
  @Input() contactGroups: any[] = [];
  @Input() campaignData: any;
  @Input() active: string = 'whatsapp';
  isSubmitted: boolean = false;
  isSchedule = false;

  constructor(private fb: FormBuilder, private http: HttpClient,

    private api: ManageCampaignService,
    private localStorage: LocalStorageService,

  ) {
    this.campaignForm = this.fb.group({
      event_name: ['', Validators.required],
      broadcast_type: ['', Validators.required],
      template_id: ['', Validators.required],
      contact_group_id: ['', Validators.required],
      status: ['', Validators.required],
      is_schedule: [false],
      schedule_date: [''],
      schedule_time: ['']
    });
  }


  ngOnInit(): void {
    
    if(this.type === 'Edit') {
      this.campaignForm.patchValue({
        event_name: this.campaignData.campaign_name,
        broadcast_type: this.campaignData.campaign_type,
        template_id: this.campaignData.template_id,
        contact_group_id: Number(this.campaignData.contact_group_id),
        status: this.campaignData.status,
        is_schedule: this.campaignData.is_schedule,
        schedule_date: this.campaignData.schedule_date,
        schedule_time: this.campaignData.schedule_time
      });
      this.broadcastType = this.campaignData.campaign_type;
      this.isSchedule = this.campaignData.is_schedule;

      this.active = this.campaignData.campaign_type;
    }

  }

  ngAfterViewInit() {
    if(this.active === 'whatsapp') {
      this.campaignForm.get('broadcast_type')?.setValue('whatsapp');
    } else if(this.active === 'email') {
      this.campaignForm.get('broadcast_type')?.setValue('email');
    }
  }

  onBroadcastTypeChange(event: any) {
    this.broadcastType = event.value;
    if (this.broadcastType === 'email') {
      
    } else if (this.broadcastType === 'whatsapp') {
  
    }
  }

  onSubmit() {
    this.isSubmitted = true;

    if (this.campaignForm.valid) {

      const payload = {
        campaign_name: this.campaignForm.get('event_name')?.value,
        campaign_type: this.campaignForm.get('broadcast_type')?.value,
        template_id: this.campaignForm.get('template_id')?.value,
        client_id: this.localStorage.getClientId(),
        contact_group_id: this.campaignForm.get('contact_group_id')?.value,
        is_schedule: this.isSchedule,
        schedule_date: this.campaignForm.get('schedule_date')?.value === '' ? null : this.campaignForm.get('schedule_date')?.value,
        schedule_time: this.campaignForm.get('schedule_time')?.value === '' ? null : this.campaignForm.get('schedule_time')?.value,
        status: this.campaignForm.get('status')?.value
      };

      this.btnLoading = true;

      if(this.type === 'Create'){
        this.api.createCampaign(payload).subscribe(
          (res:any) => {
            this.formSubmit.emit();
          },
          (error:any) => {
            console.error('Error creating campaign', error);
            this.btnLoading = false;
          }
        );
      }else{
       const payload2 = {
          id: this.campaignData.id,
          ...payload
        }
        this.api.updateCampaign(payload2).subscribe(
          (res:any) => {
            console.log('Campaign updated successfully', res);
            this.formSubmit.emit();
          },
          (error:any) => {
            console.error('Error updating campaign', error);
            this.btnLoading = false;
          }
        );
      }


      
    }
  }

  onScheduleChange(event: any) {
    this.isSchedule = event.checked;
    if (this.isSchedule) {
      this.campaignForm.get('schedule_date')?.setValidators(Validators.required);
      this.campaignForm.get('schedule_time')?.setValidators(Validators.required);
    } else {
      this.campaignForm.get('schedule_date')?.clearValidators();
      this.campaignForm.get('schedule_time')?.clearValidators();
    }
    this.campaignForm.get('schedule_date')?.updateValueAndValidity();
    this.campaignForm.get('schedule_time')?.updateValueAndValidity();
  }

  closeModal() {
    this.close.emit();
  }
}
