import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { CategoryService } from 'src/app/private/services/category.service';
import { CompanyService } from 'src/app/private/services/company.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent {

  searchQuery = new FormControl();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  dateQuery = new FormControl();
  center_id: any;
  totalRows = 0;
  temp: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  formAction = 'Add';
  currentPage = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [10, 25, 100];
  btnLoading: boolean = false;
  reloading: boolean = false;
  initialLoad: boolean = true;
  closeResult = '';
  editData: any;
  deleteData: any;
  current_id_hover = '';

  displayedColumns: string[] = [
    "cat_name",
    "description",
    "createdAt",
    "is_active",
    "action"
  ];

  defaultFilter = [
    "cat_name",
    "is_active",
  ]


  constructor(
    private localStorage: LocalStorageService,
    private api: CategoryService,
    // private userApi: UsersService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private userApi: UsersService


  ) { }



  ngAfterViewInit() {
    console.log("tabl column : ", this.displayedColumns);
    this.loadData();
  }


  open(content: any) {
    this.formAction = 'Add';

    this.modalService.open(content, {
      size: 'xl', scrollable: true,
      centered: true
    })
  }

  loadData() {

    try {
      merge(
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            this.reloading = true;
            let param = {
                client_id: this.localStorage.getClientId(),
            }
            return this.api
              .getAllCategory(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.initialLoad = false;
            this.reloading = false;
            this.totalRows = response.length;
            if (response === null) {
              return [];
            }
            this.totalRows = response.length;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            this.initialLoad = false;
            this.reloading = false;
            console.error(e);
          },
          complete: () => {
            this.initialLoad = false;
            this.reloading = false;
          },
        });
    } catch (e) {
      this.initialLoad = false;
      this.reloading = false;
      console.error(e);
    }
  }

  reloadData() {
    this.reloading = true;
    try {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            console.log("client_id", this.localStorage.getClientId())
            let param = {
                client_id: this.localStorage.getClientId(),
            }

            return this.api
              .getAllCategory(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            this.initialLoad = false;
            if (response === null) {
              this.totalRows = 0;
              this.reloading = false;
              return [];
            }
            this.totalRows = response.length;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
          },
          error: (e) => {
            this.initialLoad = false;
            this.reloading = false;
            console.error(e);
          },
          complete: () => {
            this.initialLoad = false;
            this.reloading = false;
          },
        });
    } catch (e) {
      this.initialLoad = false;
      this.reloading = false;
      console.error(e);
    }
  }

  openEdit(content: any, data: any) {
    this.formAction = 'Update';
    this.editData = data;
    this.modalService.open(content, {
      size: 'lg', scrollable: true,
      centered: true
    })
  }

  openDeleteEvent(content: any, data: any) {
    this.deleteData = data;
    this.modalService.open(content, {
      size: 'md', scrollable: true,
      centered: true
    })
  }

  searchFilter(query: any) {
    console.log('Search Query:', query);
    this.searchQuery.setValue(query);
  }

  handleDeleteCompany() {

    const parmas = {
      account_id: this.deleteData.account_id
    }
    this.btnLoading = true;
    this.api.deleteCategory(parmas).subscribe((res: any) => {
      console.log('Response:', res);
      this.toast.success('Company Deleted Successfully');
      this.reloadData();
      this.btnLoading = false;
      this.modalService.dismissAll();
    })
  }

}

function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}