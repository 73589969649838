import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { WhatsappTemplateService } from 'src/app/private/services/whatsapp-tempate.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-creatae-whatsapp-template',
  templateUrl: './creatae-whatsapp-template.component.html',
  styleUrls: ['./creatae-whatsapp-template.component.scss']
})
export class CreataeWhatsappTemplateComponent implements OnInit, OnDestroy {
  public Editor = ClassicEditor as unknown as any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  @Input() type: string = 'Create';
  @Input() templateDataToEdit: any;

  btnLoading = false;
  template: any = {
    template_name: '',
    header_text: '',
    category: '',
    language: '',
    labels: '',
    header: 'none',
    body: '',
    footer: '',
    media: '',
    mediaData: ''
  };
  languages: string[] = ['English', 'Hindi', 'Gujarati'];
  isSubmitted = false;
  editorData = '';
  currentTime!: string;
  public variableCounter: number = 0;
  private timerId: any;

  countQuickReplyButton: number = 0;
  countCTAButtonForURL: number = 0;
  countCTAButtonForCall: number = 0;

  quickReplyButtons: any[] = [];
  urlButtons: any[] = [];
  callButtons: any[] = [];
  languagesList: any[] = [];

  appId: any;
  token: any;

  variables: { [key: string]: string } = {}; // Object to hold variables and their sample values
  renderedData: any = '';

  showAllVariables = false;
  fileName: string = '';
  fileType: string = '';
  base64Data: string = '';
  initiaBody: boolean = false;

  templateIDToEdit: any;
  mediaId: string = '';
  suffix: string = '';
  showMediaThroughMediaID: boolean = false;
  variableError: boolean = false;
  public editorConfig = {
    toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'horizontalLine']
  };

  constructor(
    private toastr: ToastrService,
    private api: WhatsappTemplateService,
    private localStorage: LocalStorageService,

  ) { }


  ngOnInit() {
    this.updateTime();
    this.timerId = setInterval(() => this.updateTime(), 60000); // Update every minute

    this.storeAppId();
    this.getAllLanguages();

    setTimeout(() => {
      if (this.type === 'Edit') {

        const { header, body, footer } = this.splitString(this.templateDataToEdit.containerMeta);

        const { quickReplyButtons, urlButtons, callButtons } = this.parseButtons(this.templateDataToEdit.containerMeta);

        this.quickReplyButtons = quickReplyButtons.map((button: string) => ({ value: button }));

        this.urlButtons = urlButtons.map((button: any) => ({ title: button.text, urlType: button.urlType, value: button.url, suffix: button.suffix }));

        this.callButtons = callButtons.map((button: any) => ({ title: button.text, value: button.url }));

        this.countQuickReplyButton = this.quickReplyButtons.length;

        this.countCTAButtonForURL = this.urlButtons.length;

        this.countCTAButtonForCall = this.callButtons.length;




        this.template = {
          template_name: this.templateDataToEdit.elementName,
          header_text: header,
          category: this.templateDataToEdit.category,
          labels: this.templateDataToEdit.vertical,
          body: body,
          footer: footer,
          header: (header === '' || header === undefined) ? 'none' : 'text',
          language: this.templateDataToEdit.languageCode,


        };


        this.templateIDToEdit = this.templateDataToEdit.id;

        this.editorData = this.formateData(this.template.body);;
        this.initiaBody = true;
        this.renderedData = this.formateData(this.editorData);

        const metaData = JSON.parse(this.templateDataToEdit.containerMeta);


        if (this.templateDataToEdit.templateType === 'IMAGE' || this.templateDataToEdit.templateType === 'VIDEO' || this.templateDataToEdit.templateType === 'DOCUMENT') {

          this.template.header = 'media';
          this.template.media = this.templateDataToEdit.templateType === 'IMAGE' ? 'image' : this.templateDataToEdit.templateType === 'VIDEO' ? 'video' : 'document';
          const data = JSON.parse(this.templateDataToEdit.meta);
          console.log('Data: Meta', data);
          this.mediaId = data.mediaId;
          this.showMediaThroughMediaID = true;
        }

        if (metaData.sampleText) {

          const variablePattern = /\{\{\d+\}\}/g;
          const variablesInText = this.editorData.match(variablePattern);

          if (variablesInText) {
            const uniqueVariables = new Set(variablesInText);
            const variablesArray = Array.from(uniqueVariables);

            if (uniqueVariables.size < this.variableCounter) {
              let start = 1;

              // Loop through and replace all variables sequentially
              variablesArray.forEach((variable) => {
                const escapedVariable = variable.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special chars
                const variablePattern = new RegExp(escapedVariable, 'g');
                this.editorData = this.editorData.replace(variablePattern, `{{${start}}}`);
                start++;
              });

              // Update the variable counter
              this.variableCounter = start - 1;
            } else {
              this.variableCounter = uniqueVariables.size;
            }
          }

          this.extractVariables();

          this.variables = this.extractAndPopulate(metaData.sampleText);
          this.renderedData = this.removeBrackets(metaData.sampleText);
          console.log('Variables:', this.variables);
        }
      }
    }, 1000);
  }

  extractAndPopulate(inputString: string): { [key: string]: string } {
    const matches = inputString.match(/\[([^\]]+)\]/g);
    const result: { [key: string]: string } = {};

    if (matches) {
      matches.forEach((match, index) => {
        result[`{{${index + 1}}}`] = match.replace(/[\[\]]/g, '');  // Remove brackets and assign
      });
    }

    return result;
  }

  removeBrackets(input: string): string {
    let output = input.replace(/\*(.*?)\*/g, '<b>$1</b>');

    // Step 2: Remove square brackets [] but keep the content inside
    output = output.replace(/\[(.*?)\]/g, '$1');

    return output;
  }

  formateData(data: any) {
    return data
      .replace(/\n/g, '<br>') // Replace \n with <br>
      .replace(/\*(.*?)\*/g, '<b>$1</b>'); // Replace *text* with <b>text</b>
  }

  splitString(inputString: any) {

    const inputData = JSON.parse(inputString);

    const { header, data: body, footer } = inputData;

    return {
      header: header,
      body: body,
      footer: footer
    };
  }

  parseButtons(input: string): {
    quickReplyButtons: string[], urlButtons: { text: string, urlType: string, url: string }[],
    callButtons: { text: string, url: string }[]
  } {
    const quickReplyButtons: string[] = [];
    const urlButtons: {
      text: string, urlType: string, url: string,
      suffix?: string
    }[] = [];
    const callButtons: { text: string, url: string }[] = [];

    // Parse the input string as JSON
    const inputData = JSON.parse(input);

    // Assuming buttons field is present in the input JSON
    const { buttons } = inputData;


    if (buttons) {
      buttons.forEach((button: any) => {
        if (button.type === 'QUICK_REPLY') {
          // Add to quickReplyButtons if type is QUICK_REPLY
          quickReplyButtons.push(button.text.trim());
        } else if (button.type === 'URL') {
          // Add to urlButtons if type is URL

          if (button.url.includes('{{1}}')) {
            const url = button.url.split('{{1}}');
            const suffix = button.example[0].split("/")[3];
            console.log('Suffix:', suffix);
            urlButtons.push({ text: button.text.trim(), urlType: "dynamic", url: url[0].trim(), suffix: suffix });
          } else {
            urlButtons.push({ text: button.text.trim(), urlType: "static", url: button.url.trim() });
          }
        } else if (button.type === 'PHONE_NUMBER') {
          // Add to urlButtons if type is URL
          callButtons.push({ text: button.text, url: button.phone_number });
        }
      });
    }

    return { quickReplyButtons, urlButtons, callButtons };
  }



  getAllLanguages() {
    this.api.getAllLanguages().subscribe((res: any) => {
      this.languagesList = res;
    });
  }

  storeAppId() {
    const payload = {
      client_id: 1 || this.localStorage.getClientId()
    };
    this.api.getAppId(payload).subscribe((res: any) => {
      this.appId = res.app_id;
      this.token = res.app_token;

    });
  }


  ngOnDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  updateTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    this.currentTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  onSubmit() {
    this.isSubmitted = true;

    if (this.isValidForm()) {
      this.btnLoading = true;
      let modifyButtons: any = [];

      this.quickReplyButtons.forEach((button: any) => {
        modifyButtons.push({
          type: 'QUICK_REPLY',
          text: button.value
        });
      });

      this.urlButtons.forEach((button: any) => {


        if (button.urlType === 'static') {
          modifyButtons.push({
            type: 'URL',
            text: button.title,
            url: button.value
          });
        } else if (button.urlType === 'dynamic') {


          // {"type":"URL","text":"visit us","url":"https://gravitas.ai/{{1}}","example":["https://gravitas.ai/about"]}

          modifyButtons.push({
            type: 'URL',
            text: button.title,
            url: button.value + '{{1}}',
            example: [button.value + button.suffix]
          });
        }

      });

      this.callButtons.forEach((button: any) => {
        modifyButtons.push({
          type: 'PHONE_NUMBER',
          text: button.title,
          phone_number: button.value
        });
      });

      let exampleData = this.editorData;

      for (const variable in this.variables) {
        const value = this.variables[variable] || ''; // Use empty string if no value is provided

        console.log('Variable:', variable, 'Value:', value);
        const escapedVariable = variable.replace(/[{}]/g, '\\$&'); // Escape the curly braces
        const regex = new RegExp(escapedVariable, 'g'); // Create a global regular expression
        exampleData = this.renderedData.replace(regex, `[${value}]`);
      }

      console.log("header", this.template.header);


      const payload = {
        client_id: 1 || this.localStorage.getClientId(),
        elementName: this.template.template_name,
        languageCode: this.template.language,
        category: this.template.category,
        template_id: this.templateIDToEdit,
        // for media hve to pass IMage or Video or Document
        templateType: this.template.header === 'text' ? 'TEXT' : this.template.header === 'none' ? 'TEXT' : this.template.media === 'image' ? 'IMAGE' : this.template.media === 'video' ? 'VIDEO' : this.template.media === 'document' ? 'DOCUMENT' : 'LOCATION',
        vertical: this.template.labels,
        content: this.convertHtmlToText(this.editorData),
        header: this.template.header === 'text' ? this.template.header_text : '',
        exampleHeader: this.template.header_text,
        footer: this.template.footer,
        example: this.generateSample(this.editorData),
        enableSample: this.template.header === 'media' ? true : false,
        allowTemplateCategoryChange: false,
        buttons: modifyButtons,
        mediaId: this.template.header === 'media' ? this.mediaId : '',
      };

      if (this.type === 'Edit') {



        this.api.updateTemplate(payload).subscribe({
          next: (res: any) => {
            this.formSubmit.emit();
          },
          error: (err: any) => {
            this.btnLoading = false;
            this.toastr.error('Failed to create template.', 'Error');
          },
          complete: () => {
            console.log('Template creation process completed.');
          }
        });
      } else {
        this.api.createTemplate(payload).subscribe({
          next: (res: any) => {
            this.formSubmit.emit();
          },
          error: (err: any) => {
            console.error('Error creating template:', err);
            this.btnLoading = false;
            this.toastr.error(err?.error || 'Failed to create template.', 'Error');
          },
          complete: () => {
            console.log('Template creation process completed.');
          }
        });
      }



      // Handle form submission, e.g., send data to the server
    } else {
      console.error('Form submission failed. Please correct the errors.');
    }
  }


  convertHtmlToText(html: string): string {
    return html
      .replace(/<\/?p>/g, '') // Remove <p> and </p> tags
      .replace(/<strong>(.*?)<\/strong>/g, '*$1*') // Replace <strong>...</strong> with *...*
      .replace(/<br\s*\/?>/g, '\n') // Replace <br> with \n
      .replace(/&nbsp;/g, ' '); // Replace &nbsp; with a space
  }


  generateSample(html: string): string {
    const sample = html
      .replace(/<\/?p>/g, '') // Remove <p> and </p> tags
      .replace(/<strong>(.*?)<\/strong>/g, '*$1*') // Replace <strong>...</strong> with *...*
      .replace(/<br\s*\/?>/g, '\n')
      .replace(/&nbsp;/g, ' '); // Replace <br> with \n

    return sample.replace(/{{\d+}}/g, match => {
      // Return the corresponding value from the variables object, wrapped in square brackets
      return `[${this.variables[match] || match}]`; // If no match is found, return the original placeholder in brackets
    });
  }

  isValidForm(): boolean {
    // Template names can only contain small letters, numbers and underscores.
    const templateNameValid = this.template.template_name !== '' &&
      /^[a-z0-9_]+$/.test(this.template.template_name);
    const categoryValid = this.template.category !== '';
    const languageValid = this.template.language !== '';
    const labelsValid = this.template.labels !== '';
    const headerValid = this.template.header !== '';
    const bodyValid = this.editorData !== '';

    const header_textValid = this.template.header === 'text' ? this.template.header_text !== '' : true;
    const mediaIdValid = this.template.header === 'media' ? this.mediaId !== '' : true;

    // checking for any variable empty or not 

    for (const variable in this.variables) {
      if (this.variables[variable] === '') {
        this.variableError = true;
        return false;
      }
    }


    // checking for value in on quick reply button

    if (this.quickReplyButtons.length > 0) {
      for (let i = 0; i < this.quickReplyButtons.length; i++) {
        if (this.quickReplyButtons[i].value === '') {
          this.toastr.error('Please enter value in quick reply button.', 'Error');
          return false;
        }
      }
    }

    // checking for value in on url button

    if (this.urlButtons.length > 0) {
      for (let i = 0; i < this.urlButtons.length; i++) {
        if (this.urlButtons[i].value === '' || this.urlButtons[i].title === '' || this.urlButtons[i].urlType === '') {
          this.toastr.error('Please enter value in url button.', 'Error');
          return false;
        }

        if (this.urlButtons[i].urlType === 'dynamic' && this.urlButtons[i].suffix === '') {
          this.toastr.error('Please enter suffix in url button.', 'Error');
          return false;
        }

        // check for value must be url
        if (this.urlButtons[i].urlType === 'static' && !this.urlButtons[i].value.includes('http')) {
          this.toastr.error('Please enter valid url in url button.', 'Error');
          return false;
        }

        if (this.urlButtons[i].urlType === 'dynamic' && !this.urlButtons[i].value.includes('http')) {
          this.toastr.error('Please enter valid url in url button.', 'Error');
          return false;
        }
      }
    }

    // checking for value in on call button

    if (this.callButtons.length > 0) {
      for (let i = 0; i < this.callButtons.length; i++) {
        if (this.callButtons[i].value === '' || this.callButtons[i].title === '') {
          this.toastr.error('Please enter value in call button.', 'Error');
          return false;
        }
      }
    }

    return templateNameValid && categoryValid && languageValid &&
      labelsValid && headerValid && bodyValid && header_textValid && mediaIdValid;
  }


  onFileChange(event: any) {
    const file: File = event.target.files[0];

    // check file type

    const acceptedFileTypes = this.acceptedFileTypes();

    console.log('Accepted file types:', acceptedFileTypes);
    console.log('Selected file:', file.type);

    if (!acceptedFileTypes.includes(file.type)) {
      this.toastr.error('Invalid file type. Please select a valid file type.', 'Error');
      return;
    }

    if (file) {
      // Create a preview URL for the image
      const reader = new FileReader();

      reader.onload = (e: any) => {
        // Set the base64 or URL to the template.mediaData for preview
        this.template.mediaData = e.target.result;
        this.fileName = file.name;
      };

      // Read the file as a data URL (for image preview)
      reader.readAsDataURL(file);

      // Upload the file to the backend API
      this.api.uploadFile(file).subscribe(
        (response: any) => {
          console.log('API Response file:', response);
          this.mediaId = response.mediaId;
        },
        (error) => {
          console.error('Error:', error);
        }
      );
    }
  }




  removeMedia() {
    this.showMediaThroughMediaID = false;
    this.template.mediaData = '';
    this.mediaId = '';
    this.fileName = '';
  }

  closeModal() {
    this.close.emit();
  }


  maxFileSize(): number {
    switch (this.template.media) {
      case 'image':
        return 2 * 1024 * 1024; // 2 MB in bytes
      case 'video':
        return 20 * 1024 * 1024; // 20 MB in bytes
      case 'document':
        return 2 * 1024 * 1024; // 2 MB in bytes
      default:
        return 0;
    }
  }

  acceptedFileTypes(): string[] {
    switch (this.template.media) {
      case 'image':
        return ['image/jpeg', 'image/png']; // Specific MIME types for images
      case 'video':
        return ['video/mp4']; // Specific MIME types for videos
      case 'document':
        return ['application/pdf']; // MIME type for PDFs
      default:
        return [];
    }
  }


  selectMedia(mediaType: string) {
    this.template.media = mediaType;
    this.base64Data = '';
    this.template.mediaData = '';
  }

  addVariable(event: any): void {
    event.preventDefault();
    this.variableCounter++;
    const newVariable = `{{${this.variableCounter}}}`;

    // Remove trailing whitespace, including newlines, from the editor data
    this.editorData = this.editorData.trimEnd();

    // Find the last occurrence of a closing </p> tag
    const closingPTagsIndex = this.editorData.lastIndexOf('</p>');

    // If a closing </p> tag is found, insert the variable before it
    if (closingPTagsIndex !== -1) {
      this.editorData =
        this.editorData.slice(0, closingPTagsIndex) +
        newVariable +
        this.editorData.slice(closingPTagsIndex);
    } else {
      // If no </p> tag is found, just append the variable at the end of the data
      this.editorData += newVariable;
    }

    console.log('Editor data:', this.editorData);

    this.extractVariables();
  }


  onEditorChange(): void {

    this.initiaBody = false;
    console.log('Editor data changed:', this.initiaBody);
    if (this.editorData.length == 0) {
      this.variableCounter = 0;
      this.extractVariables();
      return;
    }

    console.log('Editor data:', this.editorData);
    const variablePattern = /\{\{\d+\}\}/g;
    const variablesInText = this.editorData.match(variablePattern);

    if (variablesInText) {
      const uniqueVariables = new Set(variablesInText);
      const variablesArray = Array.from(uniqueVariables);

      if (uniqueVariables.size < this.variableCounter) {
        let start = 1;

        // Loop through and replace all variables sequentially
        variablesArray.forEach((variable) => {
          const escapedVariable = variable.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special chars
          const variablePattern = new RegExp(escapedVariable, 'g');
          this.editorData = this.editorData.replace(variablePattern, `{{${start}}}`);
          start++;
        });

        // Update the variable counter
        this.variableCounter = start - 1;
      } else {
        this.variableCounter = uniqueVariables.size;
      }
    }

    this.extractVariables();
  }


  extractVariables(): void {
    const variablePattern = /\{\{\d+\}\}/g;
    const variablesInText = this.editorData.match(variablePattern);
    const uniqueVariables = new Set(variablesInText);

    console.log('Unique variables:', uniqueVariables);
    console.log('Variables:', this.variables);

    // have to trim extra vratibles key which is not in uniqueVariables
    for (const variable in this.variables) {
      if (!uniqueVariables.has(variable)) {
        delete this.variables[variable];
      }
    }
    uniqueVariables.forEach(variable => {
      this.variables[variable] = this.variables[variable] || ''; // Use empty string if no value is provided
    });

    this.updateRenderedData();


  }

  // Update the rendered data when the sample value is changed
  updateRenderedData(): void {
    this.renderedData = this.editorData;
    console.log('Variables:', this.variables);
    for (const variable in this.variables) {
      const value = this.variables[variable] || ''; // Use empty string if no value is provided

      console.log('Variable:', variable, 'Value:', value);
      const escapedVariable = variable.replace(/[{}]/g, '\\$&'); // Escape the curly braces
      const regex = new RegExp(escapedVariable, 'g'); // Create a global regular expression
      this.renderedData = this.renderedData.replace(regex, value);
      console.log('Rendered data:', this.renderedData);
    }

    for (const variable in this.variables) {
      if (this.variables[variable] === '') {
        this.variableError = true;
        return;
      }
    }

    this.variableError = false;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }


  addQuickReplyButton(event: any): void {
    event.preventDefault();

    if (this.countQuickReplyButton + this.countCTAButtonForCall + this.countCTAButtonForURL > 10) {
      this.toastr.error('You can add maximum 10 buttons in a template.', 'Error');
      return
    }

    this.countQuickReplyButton++;

    this.quickReplyButtons.push({
      value: ''
    });

    console.log('Quick reply buttons:', this.quickReplyButtons);
  }

  addUrlButton(event: any): void {

    if (this.countCTAButtonForURL > 1) {
      this.toastr.error('You can add maximum 2 Url Buttons in template', 'Error');
      return
    }

    event.preventDefault();
    this.countCTAButtonForURL++;
    this.urlButtons.push({
      value: '',
      title: '',
      urlType: '',
      suffix: '',
    });

  }

  addCallButton(event: any): void {
    event.preventDefault();


    if (this.countCTAButtonForCall > 0) {
      this.toastr.error('You can add maximum 1 CTA button in a template.', 'Error');
      return
    }

    this.countCTAButtonForCall++;
    this.callButtons.push({
      value: ''
    });
  }

  removeQuickReplyButton(event: any, index: any): void {
    event.preventDefault();
    this.countQuickReplyButton--;

    // remover perticular index from array
    this.quickReplyButtons.splice(index, 1);

  }

  removeUrlButton(event: any, index: any): void {
    event.preventDefault();
    this.countCTAButtonForURL--;
    this.urlButtons.splice(index, 1);
  }

  removeCallButton(event: any, index: any): void {
    event.preventDefault();
    this.countCTAButtonForCall--;
    this.callButtons.splice(index, 1);
  }

  showAllOptions() {
    this.showAllVariables = !this.showAllVariables;
  }

}
