import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-column-info-for-lead',
  templateUrl: './column-info-for-lead.component.html',
  styleUrls: ['./column-info-for-lead.component.scss']
})
export class ColumnInfoForLeadComponent {

  @Output() close = new EventEmitter();
  @Input() allLeadFeilds: any = [];
  selectedFields: any = {
    "Lead Source": [
      "Channel Partner",
      "Conference",
      "Field Visit",
      "Google",
      "Instagram",
      "Lead Gen Tools",
      "Live Session",
      "Personal",
      "Referral",
      "LinkedIn",
      "Website",
      "WhatsApp",
      "Tina",
      "Other",
    ],
    "Lead Status": [
      "New",
      "Active",
      "Pipeline /Budgetary",
      "Contacted",
      "Qualified",
      "Converted",
      "Closed - Lost",
      "Closed - Won",
      "Follow-up",
      "Not Interested",
      "Duplicate",
      "Disqualified",
      "Marketing Qualified Lead (MQL)",
      "Sales Qualified Lead (SQL)",
    ],
    "Lead Type": [
      "Cold Lead",
      "Warm Lead",
      "Hot Lead",
    ],
  };
  dynamicData: Record<string, string[]> = {}; // Store the data
  isLoading = true; // Show a loading spinner while data is fetched

  closeModal() {
    this.close.emit();
  }

  constructor() { }

  ngOnInit(): void {
    const newFields = this.getSelectFields();
    this.selectedFields = { ...this.selectedFields, ...newFields };
    console.log(this.selectedFields);
    this.isLoading = false;
    this.dynamicData = this.selectedFields;
  }

  getSelectFields() {
    const selectFields = this.allLeadFeilds.filter(
      (field: any) => field.type === 'select'
    );
  
    const result = selectFields.reduce((acc: any, field: any) => {
      if (field.label && field.data) {
        acc[field.label] = field.data;
      }
      return acc;
    }, {});
  
    return result;
  }

  getKeys(obj: Record<string, string[]>): string[] {
    return Object.keys(obj);
  }
  

}
