import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-email-template',
  templateUrl: './preview-email-template.component.html',
  styleUrls: ['./preview-email-template.component.scss']
})
export class PreviewEmailTemplateComponent {

  sanitizedData: SafeHtml = '';  // SafeHtml for srcdoc binding

  constructor(private sanitizer: DomSanitizer) {}

    @Input() processedData: any;

    ngOnInit() {
      this.sanitizedData = this.processedData;
    }
}
