import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MailMiddleSectionComponent } from 'src/app/private/modules/components/sales-mail-hub/mail-middle-section/mail-middle-section.component';
import { EmailHubService } from 'src/app/private/services/email-hub.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-sales-mail-hub',
  templateUrl: './sales-mail-hub.component.html',
  styleUrls: ['./sales-mail-hub.component.scss']
})
export class SalesMailHubComponent {
  @ViewChild(MailMiddleSectionComponent) childComponent!: MailMiddleSectionComponent;
  selectedRow: any;
  activeAction: string = 'inbox';
  sanitizedContent: any;
  isComposeOpen = false;
  loadingEmailData = false;

  constructor(
    private sanitizer: DomSanitizer,
    private api : EmailHubService,
    private localStorage: LocalStorageService
  ) { }

  onActionChange(action: string) {
    this.activeAction = action;
  }

  handleUpdateData() {
    console.log('Refetching data...');
    this.childComponent.refetch();
  }

  handleUpdateData2() {
    console.log('Refetching data...');
    this.childComponent.refetch();
  }

  
   

  handleSelectRow(row: any) {
    this.isComposeOpen = false;
    console.log('Selected row:', row);
    this.selectedRow = row;
  
    try {
      const payload = {
        client_id: this.localStorage.getClientId(),
        user_email: this.localStorage.getEmail(),
        messageId: this.selectedRow?.id
      };

      this.loadingEmailData = true;
  
      // Fetch email with attachment
      this.api.getOutlookEmailWithAttachment(payload).subscribe({
        next: (res: any) => {
          // Sanitize and trust the email content
          console.log('Email content:', res);
          this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(res);
          console.log('Email content sanitized and set successfully.');
          this.loadingEmailData = false
        },
        error: (err: any) => {
          // Log and handle API errors
          this.loadingEmailData = false;
          console.error('Error fetching email with attachment:', err);
        }
      });
    } catch (e) {
      console.error('Error in handleSelectRow:', e);
    }
  }
  
  handleCompose() {
    this.isComposeOpen = true;
  }

  handleComposeClose() {
    this.isComposeOpen = false;
  }
}
