import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { ToastrService } from 'ngx-toastr';
import { LeadService } from 'src/app/private/services/lead.service';
declare var RingoverSDK: any;

@Component({
  selector: 'app-ringover-call',
  templateUrl: './ringover-call.component.html',
  styleUrls: ['./ringover-call.component.scss']
})
export class RingoverCallComponent {

  @Input() communicationData: any;
  @Output() close: EventEmitter<any> = new EventEmitter();

  @ViewChild('Content1') content1: any;

  btnLoading: boolean = false;
  isDropdownOpen: boolean = false;

  constructor(
    private toast: ToastrService,
    private api: LeadService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ){

  }

  
  phoneForm!: FormGroup;
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  timeWhenCallStarted: any;
  timeWhenCallEnded: any;
  hangUpCallData: any;

  simpleSDK: any;
  notes: string = ''; 
  isDialerReady: boolean = false;
  btnLoading3: boolean = false;


  ngOnInit(): void {

    this.simpleSDK = new RingoverSDK();
    this.simpleSDK.on('changePage', this.logEvent);
    this.simpleSDK.on('ringingCall', this.logEvent);
    this.simpleSDK.on('answeredCall', this.logEvent);
    this.simpleSDK.on('hangupCall', this.logEvent);
    this.simpleSDK.on('login', this.logEvent);
    this.simpleSDK.on('logout', this.logEvent);
    this.simpleSDK.on('dialerReady', this.logEvent);
    this.simpleSDK.on('smsSent', this.logEvent);
    this.simpleSDK.on('smsReceived', this.logEvent);
    this.simpleSDK.on('callLogOpened', this.logEvent);

    this.phoneForm = this.fb.group({
      phone: ['', [Validators.required]]
    });
    
    // Set default phone value if available
    if (this.communicationData?.contact_phone) {
    console.log('Communication Data:', this.communicationData);

    if(this.communicationData.country_code === null || this.communicationData.country_code === undefined){
      this.setPhoneValue(this.communicationData.contact_phone);
    }else{
      this.setPhoneValue('+' + this.communicationData.country_code + this.communicationData.contact_phone);
    }
    }
  }

  // Helper function to set phone value dynamically
  private setPhoneValue(phone: string): void {
    try {
      const parsedNumber = this.phoneNumberUtil.parseAndKeepRawInput(phone);
      const internationalNumber = this.phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
      console.log('Parsed phone number:', parsedNumber);
      console.log("internationalNumber", internationalNumber);
      this.phoneForm.get('phone')?.setValue(internationalNumber);
    } catch (error) {
      console.warn('Invalid phone number format:', error);
      this.phoneForm.get('phone')?.setValue(phone); // Set raw value if parsing fails
    }
  }

  // Custom validator to ensure phone number matches country code
  isPhoneNumberValid(phoneNumber: any): boolean {
    // this.phone_number = phoneNumber.internationalNumber;
    // console.log("phone_number",this.phone_number)
    try {
      const parsedNumber = this.phoneNumberUtil.parseAndKeepRawInput(
        phoneNumber.number,
        phoneNumber.countryCode
      );
      return this.phoneNumberUtil.isValidNumber(parsedNumber);
    } catch (error) {
      console.error('Error validating phone number:', error);
      return false;
    }
  }

  // Close the modal
   closeModal() {
    this.destroy();
    this.modalService.dismissAll();
    // this.close.emit();
  }

  // Handle Proceed button click
  onProceed(): void {
    if (this.phoneForm.valid) {
      const phoneNumber = this.phoneForm.get('phone')?.value;

      if (this.isPhoneNumberValid(phoneNumber)) {
        console.log('Phone number is valid:', phoneNumber);
        this.generate();
        this.dial(phoneNumber);
        
      } else {
        console.error('Invalid phone number');
      }
    }
  }

  generate() { this.simpleSDK.generate(); }
  dial(number:any) { this.simpleSDK.dial(number?.e164Number); }
  logEvent = (e: any) => {
    console.log("Action",e?.action);
    console.log("Data",e?.data);

    if(e?.action === "dialerReady"){
      this.isDialerReady = true;
    }

    if(e?.action === "ringingCall"){
      this.timeWhenCallStarted = new Date();
    }

    if(e?.action === "hangupCall"){
      this.timeWhenCallEnded = new Date();
      console.log("Call Duration",this.timeWhenCallEnded - this.timeWhenCallStarted);

      this.hangUpCallData = e?.data;

      this.destroy();
      this.modalService
      .open(this.content1, { size: 'lg', scrollable: true, centered: true,
        backdrop: 'static', keyboard: false
       })

    

    }

    // const p = document.createElement('pre');
    // p.innerText = `${e.action} => ${JSON.stringify(e.data)}`;
    // document.getElementById('get-events')?.appendChild(p);
  };

  destroy() { this.simpleSDK.destroy(); }



  saveNotes() {
      try{
        this.btnLoading3 = true;
      const payload = {

        "lead_id": this.communicationData?.lead_id,
        "client_id": this.communicationData?.client_id,
        "communication_type": "call",
        "note": this.notes,
        "date_of_communication": new Date().toISOString().split('T')[0],
        "time_of_communication": this.timeWhenCallStarted.toTimeString().split(' ')[0],
        "call_extra_details": this.hangUpCallData,
        "email_extra_details": {
        },
        "status": this.hangUpCallData?.channel_id !== null ? "Connected" : "Disconnected"

      }

      this.api.CreateCommunicationHisLog(payload).subscribe({
        next: (res: any) => {
          this.toast.success('Notes saved successfully');
          this.modalService.dismissAll();
        this.btnLoading3 = false;
        },
        error: (err: any) => {
          this.modalService.dismissAll();
          this.btnLoading3 = false;
        }
      });
      

        
      }catch(e){
        this.btnLoading3 = false;
        this.toast.error('Error saving notes');
        console.error(e);
      }
  }
  

}
