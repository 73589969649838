import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EmailEditorComponent } from 'angular-email-editor';
import { ToastrService } from 'ngx-toastr';
import { EmailTemplateService } from 'src/app/private/services/email-tempate.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

declare var unlayer: any;
@Component({
  selector: 'app-create-email-template',
  templateUrl: './create-email-template.component.html',
  styleUrls: ['./create-email-template.component.scss']
})
export class CreateEmailTemplateComponent implements OnInit {

  @Input() type: any;
  @Input() templateDataToEdit: any;
  title = 'angular-email-editor';
  pageTitle = 'Create';
  options = {
    // setting previw to mobile
  }

  @ViewChild(EmailEditorComponent)
  private emailEditor!: EmailEditorComponent;
  htmlData = '';
  design='';

  constructor(
    private toast: ToastrService,
    private api: EmailTemplateService,
    private localStorage: LocalStorageService,


  ) {

  }


  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<any> = new EventEmitter();
  btnLoading = false;

  formData = {
    templateName: '',
    templateCategory: '',
    status: ''
  };

  closeModal() {
    this.close.emit();
  }
  
  ngOnInit() {
    console.log("tabl column : ");
    if (this.type === 'edit') {
      this.pageTitle = 'Edit';
      this.formData.templateName = this.templateDataToEdit.template_name;
      this.formData.templateCategory = this.templateDataToEdit.template_category;
      this.formData.status = this.templateDataToEdit.status;
      this.htmlData = this.templateDataToEdit.template_body;
    }
  }

  async onSubmit(form: any) {
    if (form.valid) {
      

      try{
        this.btnLoading = true;
      console.log('Form Data:', this.formData);

      await this.exportHtml();

      console.log('HTML Data:', this.htmlData);
      const payload = {
      client_id: 1,
      template_body: this.htmlData,
      template_name: this.formData.templateName,
      template_category: this.formData.templateCategory,
      template_design: JSON.stringify(this.design),
      status: this.formData.status
      }

      if(this.type === 'edit'){

        const payload2 = {
          client_id: 1,
          template_id: this.templateDataToEdit.template_id,
          template_body: this.htmlData,
          template_design: JSON.stringify(this.design),
          template_name: this.formData.templateName,
          template_category: this.formData.templateCategory,
          status: this.formData.status
        }

        this.api.updateTemplate(payload2).subscribe(
          (res: any) => {
            console.log('Response:', res);
            this.reload.emit();
          },
          (error: any) => {
            console.log('Error:', error);
            this.btnLoading = false;
            this.toast.error('Error updating template');
          }
        );
      }else{
        this.api.createTemplate(payload).subscribe(
          (res: any) => {
            console.log('Response:', res);
            this.reload.emit();
          },
          (error: any) => {
            console.log('Error:', error);
            this.btnLoading = false;
            this.toast.error('Error creating template');
          }
        );
      }

      }catch(e){
        console.log('Error:', e);
        this.btnLoading = false;
        this.toast.error('Error creating template');
      }
      
    } else {
      // If form is invalid, mark all fields as touched to show validation errors
      Object.keys(form.controls).forEach(field => {
        const control = form.controls[field];
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  
  // called when the editor is created
  editorLoaded() {
  }

 

  // called when the editor has finished loading
  editorReady() {
    console.log('editorReady');
  
    setTimeout(() => {
      if (this.type === 'edit') {
        console.log('htmlData before parsing:', this.htmlData);
    
        try {

          const data = 
          {
            "counters": {
                "u_column": 1,
                "u_row": 1,
                "u_content_text": 2,
                "u_content_image": 1,
                "u_content_heading": 1
            },
            "body": {
                "id": "V14f6OA6SM",
                "rows": [
                    {
                        "id": "_lquTpGTD5",
                        "cells": [
                            1
                        ],
                        "columns": [
                            {
                                "id": "wh0ANhbWGu",
                                "contents": [
                                    {
                                        "id": "wRID5FrgSo",
                                        "type": "image",
                                        "values": {
                                            "containerPadding": "0px",
                                            "anchor": "",
                                            "src": {
                                                "url": "https://assets.unlayer.com/projects/0/1727094882445-logo.png",
                                                "width": 392,
                                                "height": 88,
                                                "contentType": "image/png",
                                                "size": 17464,
                                                "autoWidth": false,
                                                "maxWidth": "35%"
                                            },
                                            "textAlign": "left",
                                            "altText": "",
                                            "action": {
                                                "name": "web",
                                                "values": {
                                                    "href": "",
                                                    "target": "_blank"
                                                }
                                            },
                                            "displayCondition": null,
                                            "_styleGuide": null,
                                            "_meta": {
                                                "htmlID": "u_content_image_1",
                                                "htmlClassNames": "u_content_image"
                                            },
                                            "selectable": true,
                                            "draggable": true,
                                            "duplicatable": true,
                                            "deletable": true,
                                            "hideable": true,
                                            "pending": false
                                        }
                                    },
                                    {
                                        "id": "lahcv63ugg",
                                        "type": "heading",
                                        "values": {
                                            "containerPadding": "10px",
                                            "anchor": "",
                                            "headingType": "h3",
                                            "fontSize": "18px",
                                            "textAlign": "left",
                                            "lineHeight": "140%",
                                            "linkStyle": {
                                                "inherit": true,
                                                "linkColor": "#0000ee",
                                                "linkHoverColor": "#0000ee",
                                                "linkUnderline": true,
                                                "linkHoverUnderline": true
                                            },
                                            "displayCondition": null,
                                            "_styleGuide": null,
                                            "_meta": {
                                                "htmlID": "u_content_heading_1",
                                                "htmlClassNames": "u_content_heading"
                                            },
                                            "selectable": true,
                                            "draggable": true,
                                            "duplicatable": true,
                                            "deletable": true,
                                            "hideable": true,
                                            "text": "<span>Welcome to gravitas AI</span>",
                                            "_languages": {}
                                        }
                                    },
                                    {
                                        "id": "TRLli7-s7p",
                                        "type": "text",
                                        "values": {
                                            "containerPadding": "10px",
                                            "anchor": "",
                                            "fontSize": "14px",
                                            "textAlign": "left",
                                            "lineHeight": "140%",
                                            "linkStyle": {
                                                "inherit": true,
                                                "linkColor": "#0000ee",
                                                "linkHoverColor": "#0000ee",
                                                "linkUnderline": true,
                                                "linkHoverUnderline": true
                                            },
                                            "displayCondition": null,
                                            "_styleGuide": null,
                                            "_meta": {
                                                "htmlID": "u_content_text_2",
                                                "htmlClassNames": "u_content_text"
                                            },
                                            "selectable": true,
                                            "draggable": true,
                                            "duplicatable": true,
                                            "deletable": true,
                                            "hideable": true,
                                            "text": "<p style=\"line-height: 140%;\"> Welome to our platform</p>",
                                            "_languages": {}
                                        }
                                    }
                                ],
                                "values": {
                                    "backgroundColor": "",
                                    "padding": "0px",
                                    "border": {},
                                    "borderRadius": "0px",
                                    "_meta": {
                                        "htmlID": "u_column_1",
                                        "htmlClassNames": "u_column"
                                    }
                                }
                            }
                        ],
                        "values": {
                            "displayCondition": null,
                            "columns": false,
                            "_styleGuide": null,
                            "backgroundColor": "",
                            "columnsBackgroundColor": "",
                            "backgroundImage": {
                                "url": "",
                                "fullWidth": true,
                                "repeat": "no-repeat",
                                "size": "custom",
                                "position": "center",
                                "customPosition": [
                                    "50%",
                                    "50%"
                                ]
                            },
                            "padding": "0px",
                            "anchor": "",
                            "hideDesktop": false,
                            "_meta": {
                                "htmlID": "u_row_1",
                                "htmlClassNames": "u_row"
                            },
                            "selectable": true,
                            "draggable": true,
                            "duplicatable": true,
                            "deletable": true,
                            "hideable": true
                        }
                    }
                ],
                "headers": [],
                "footers": [],
                "values": {
                    "_styleGuide": null,
                    "popupPosition": "center",
                    "popupWidth": "600px",
                    "popupHeight": "auto",
                    "borderRadius": "10px",
                    "contentAlign": "center",
                    "contentVerticalAlign": "center",
                    "contentWidth": "500px",
                    "fontFamily": {
                        "label": "Arial",
                        "value": "arial,helvetica,sans-serif"
                    },
                    "textColor": "#000000",
                    "popupBackgroundColor": "#FFFFFF",
                    "popupBackgroundImage": {
                        "url": "",
                        "fullWidth": true,
                        "repeat": "no-repeat",
                        "size": "cover",
                        "position": "center"
                    },
                    "popupOverlay_backgroundColor": "rgba(0, 0, 0, 0.1)",
                    "popupCloseButton_position": "top-right",
                    "popupCloseButton_backgroundColor": "#DDDDDD",
                    "popupCloseButton_iconColor": "#000000",
                    "popupCloseButton_borderRadius": "0px",
                    "popupCloseButton_margin": "0px",
                    "popupCloseButton_action": {
                        "name": "close_popup",
                        "attrs": {
                            "onClick": "document.querySelector('.u-popup-container').style.display = 'none';"
                        }
                    },
                    "language": {},
                    "backgroundColor": "#F7F8F9",
                    "preheaderText": "",
                    "linkStyle": {
                        "body": true,
                        "linkColor": "#0000ee",
                        "linkHoverColor": "#0000ee",
                        "linkUnderline": true,
                        "linkHoverUnderline": true
                    },
                    "backgroundImage": {
                        "url": "",
                        "fullWidth": true,
                        "repeat": "no-repeat",
                        "size": "custom",
                        "position": "center"
                    },
                    "_meta": {
                        "htmlID": "u_body",
                        "htmlClassNames": "u_body"
                    }
                }
            },
            "schemaVersion": 17
        }
      
          
          this.emailEditor.editor.loadDesign(
            JSON.parse(this.templateDataToEdit.template_design)
          );
          console.log('Design loaded successfully.');
        } catch (error) {
          console.error('Error loading design:', error);
          // Handle the error accordingly, maybe set a default design or notify the user
        }
      }
    }, 500);
  
    // Optionally, setTimeout to ensure timin
  }

  async exportHtml(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.emailEditor.editor.exportHtml((data: any) => {
        if (data && data.html) {
          this.htmlData = data.html;
          this.design = data.design;
          resolve(); // Resolve the promise once data is available
        } else {
          reject('Error exporting HTML');
        }
      });
    });
  }
  

}














// ngAfterViewInit() {
//   this.initializeEditor();
// }

// initializeEditor() {
 
//     setTimeout(() => {

//       console.log("editor", this.emailEditor.editor);
//       console.log("editor", this.emailEditor.editor.addEventListener);

//       this.emailEditor.editor.init({
//         displayMode: 'email',
//         // want to show previw in mobile
//         tools: {
//           rows: {
//             properties: {
//               noStackMobile: {
//                 editor: {
//                   _override: {
//                     mobile: {
//                       defaultValue: true,  // Default value for 'Do Not Stack on Mobile'
//                     },
//                   },
//                 },
//               },
//             },
//           },
//         },

//         appearance: {
//           panels: {
//             tools: {
//               dock: 'left'
//             }
//           }
//         }

//       });
//         // deviceId: 'mobile',


//     this.emailEditor.editor.addEventListener('preview', () => {
//       console.log('Preview button clicked');
//       // Force the preview to open in mobile mode
//       this.emailEditor.editor.showPreview({ device: "mobile" })
//     });
//   }, 6000);


// }