import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { ContactGrouopService } from 'src/app/private/services/contact-groups.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-contact-group-list',
  templateUrl: './contact-group-list.component.html',
  styleUrls: ['./contact-group-list.component.scss']
})
export class ContactGroupListComponent {

 
  
  pageSizeOptions: number[] = [10, 25, 100];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  btnLoading = false;
  initialLoad2 = true;
  // isRefresh = false;
  @Input() isRefresh: any;

  displayedColumns: string[] = [
    "sno",
    "name",
    "source",
    "email",
    "phone",
  ];


  @Input() currentSelectedContactGroupID: any;
  @Input() GroupListData: any;
  @Input() currentPage: any;
  @Input() pageSize: any;
  @Input() totalRows: any;
  @Input() dataSource: any;
  @Input() initialLoad: any;

  constructor(
    private toast: ToastrService,
    private api: ContactGrouopService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
  ) {

  }


  ngAfterViewInit() {
    this.loadData();
  }

  loadData(type?: string) {


    try {
      // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {

    this.isRefresh = true;
            
            console.log("client_id", this.localStorage.getClientId())
          
            const payload = {
              "where": {
                  "client_id": this.localStorage.getClientId(),
                  "contact_group_id": this.currentSelectedContactGroupID
              },
              "filter": null,
              "page": this.paginator.pageIndex * this.paginator.pageSize,
              "limit": this.paginator.pageSize,
              "order": {
                  "col": "\"contact_group_list\".\"updatedAt\"",
                  "dir": "DESC"
              }
          }
        

            return this.api
              .getAllContactGroupList(payload)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            this.isRefresh = false;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
          
            this.initialLoad2 = false;

            if(this.initialLoad === true){
              return;
            }
            this.dataSource = new MatTableDataSource<any>(data);

            this.GroupListData = data;

           this.isRefresh = false;
          },
          error: (e) => {
            this.initialLoad = false;
           this.isRefresh = false;

            console.error(e);
          },
          complete: () => {
          },
        });
    } catch (e) {
      console.error(e);
    }
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = 10; // Set default pageSize or any value you want
    }
  }

}


function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}