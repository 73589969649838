import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, Subject, switchMap, takeUntil, of as observableOf  } from 'rxjs';
import { UsersService } from 'src/app/private/services/users.service';
import { WhatsappTemplateService } from 'src/app/private/services/whatsapp-tempate.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
@Component({
  selector: 'app-whatsapp-template',
  templateUrl: './whatsapp-template.component.html',
  styleUrls: ['./whatsapp-template.component.scss']
})
export class WhatsappTemplateComponent {
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  totalRows = 0;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  btnLoading = false;
  templateIDtoDelete: any;
  template: any;
  currentTime!: string;
  timerId: any;
  processedData: SafeHtml = '';
  quickReplyButtons: any[] = [];
  urlButtons: any[] = [];
  callButtons: any[] = [];
  countQuickReplyButton = 0;
  countCTAButtonForURL = 0;
  templateToEdit: any;
  initialLoad = true;
  templateToDelete: any;
  renderedData: any;
  header: any;
  footer: any;
  templateType: any;
  mediaId: any;
  isRefresh = false;

  showAllVariables = false;
  selectedTemplateForRejection: any;

  displayedColumns: string[] = [
    "Template",
    "Status",
    "Action"
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private toast: ToastrService,
    private api: WhatsappTemplateService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private userApi: UsersService,
    private sanitizer: DomSanitizer

  ) {

  }

  ngOnInit() {
    this.updateTime();
    this.timerId = setInterval(() => this.updateTime(), 60000); // Update every minute
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    this.currentTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  }



  ngAfterViewInit() {
    this.loadData();
  }


  loadData(): void {
    try {
      // Reset paginator page index on sorting changes
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  
      // Merge various observables to listen to changes in search, date filters, sort, and pagination
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}), // Initialize stream on component load
          switchMap(() => {
            this.isRefresh = true; // Show progress bar or loader
  
            // Construct the parameters for API call
            const params = {
              client_id: this.localStorage.getClientId(),
              pageNo: this.paginator.pageIndex + 1,
              pageSize: this.paginator.pageSize,
            };
  
            // Call API to fetch templates, handle errors gracefully
            return this.api.getAllTemplate(params).pipe(
              catchError(() => {
                console.error('Error fetching templates'); 
                return observableOf([]); // Return empty array on error
              })
            );
          }),
          map((response: any) => {
            if (!response || !response.templates) {
              this.totalRows = 0;
              return [];
            }
            this.totalRows = response.totalCount; // Set total row count for pagination
            return response.templates;
          }),
          takeUntil(this.destroy$) // Clean up subscription on component destroy
        )
        .subscribe({
          next: (data) => {
            this.initialLoad = false;
            this.btnLoading = false;
            this.isRefresh = false;
  
            // Assign data to the dataSource for the MatTable
            this.dataSource = new MatTableDataSource<any>(data);
  
            // Dismiss any open modals (if applicable)
            this.modalService.dismissAll();
          },
          error: (e) => {
            this.btnLoading = false;
            this.initialLoad = false;
            this.isRefresh = false;
            console.error('Subscription error:', e);
          },
        });
    } catch (e) {
      console.error('Unexpected error:', e);
    }
  }
  

  handleUpdateData(type?: string) {

    if (type === 'refresh') {
      this.isRefresh = true;
    }

    try {
      let param = {
        client_id: this.localStorage.getClientId(),
        pageNo: this.paginator.pageIndex + 1,
        pageSize: this.paginator.pageSize,
      };
  
      this.api.getAllTemplate(param).subscribe({
        next: (response: any) => {
          if (response) {
            this.totalRows = response.totalCount;
            this.dataSource = new MatTableDataSource<any>(response.templates);

            if (type === 'update') {
              this.modalService.dismissAll();
              this.toast.success('Template updated successfully');
            }

            if (type === 'create') {
              this.modalService.dismissAll();
              this.toast.success('Template created successfully');
            }

            if (type === 'delete') {
              this.modalService.dismissAll();
              this.toast.success('Template deleted successfully');
            }

            if (this.isRefresh) {
              this.isRefresh = false;
            }

            this.btnLoading = false;
            
          } else {
            this.toast.warning('No templates found.');
          }
        },
        error: (error: any) => {
          console.error('Error fetching templates:', error);
          this.toast.error('Failed to update templates. Please try again later.');
        },
        complete: () => {
          console.log('Template fetching completed.');
        }
      });
    } catch (e) {
      console.error('Unexpected error:', e);
      this.toast.error('An unexpected error occurred.');
    }
  }
  

  closeModal() {
    this.modalService.dismissAll();
  }


  open(content: any) {
    this.modalService
      .open(content, { size: 'xl', scrollable: true, centered: true })
  }

  handleDeleteEvent() {

    this.btnLoading = true;
    const payload = {
      client_id: this.localStorage.getClientId(),
      elementName: this.templateToDelete.elementName,
      template_id: this.templateToDelete.id
    }

    this.api.deleteTemplate(payload).subscribe(
      (response: any) => {
        if (response) {
          this.handleUpdateData('delete');
        }
      },
      (error: any) => {
        this.toast.error("Error occurred while deleting template");
      }
    );

  }

  handleOpenDeleteTemplateDialog(content: any, element: any) {
    this.modalService
      .open(content, { size: 'md', centered: true })

    this.templateToDelete = element;
  }

  handleOpenPreviewDialog(content: any, template: any) {
    this.templateType = template.templateType;

    if (template.templateType === 'IMAGE' || template.templateType === 'VIDEO' || template.templateType === 'DOCUMENT') {
      const data = JSON.parse(template.meta);
      this.mediaId = data.mediaId;
    }
    const { header, body, footer } = this.splitString(template.containerMeta);
    this.header = header;
    this.footer = footer;
    const { quickReplyButtons, urlButtons, callButtons } = this.parseButtons(template.containerMeta);

    this.quickReplyButtons = quickReplyButtons.map((button: string) => ({ value: button }));

    this.urlButtons = urlButtons.map((button: any) => ({ title: button.text, urlType: button.urlType, value: button.url }));

    this.callButtons = callButtons.map((button: any) => ({ title: button.text, value: button.url }));

    this.countQuickReplyButton = this.quickReplyButtons.length;

    this.countCTAButtonForURL = this.urlButtons.length;

    this.renderedData = this.formateData(body);

    this.modalService.open(content, { windowClass: 'custom-modal-class', centered: true });
  }

  handleOpenRejectedReasonDialog(content: any, template: any) {
    this.selectedTemplateForRejection = template;
    this.modalService.open(content, { centered: true, size: 'md' });
  }

  formateData(data: any) {
    return data
      .replace(/\n/g, '<br>') // Replace \n with <br>
      .replace(/\*(.*?)\*/g, '<b>$1</b>'); // Replace *text* with <b>text</b>
  }

  splitString(inputString: any) {

    // Parse the input string as JSON
    const inputData = JSON.parse(inputString);

    // Extract the header, body (data), and footer from the JSON object
    const { header, data: body, sampleText, footer } = inputData;

    return {
      header: header,
      body: this.updateSampleText(sampleText),
      footer: footer
    };
  }

  updateSampleText(input: string): string {
    return input.replace(/\[(.*?)\]/g, '$1');
  }


  formatMessage(message: string): string {
    message = message.split('|')[0].trim();
    return message
      .replace(/\n/g, '<br>') // Replace \n with <br>
      .replace(/\*(.*?)\*/g, '<b>$1</b>'); // Replace *text* with <b>text</b>
  }
  formatMessage2(message: string): string {
    message = message.split('|')[0].trim();
    return message
      .replace(/\n/g, '') // Replace \n with <br>
      .replace(/\*(.*?)\*/g, '<b>$1</b>'); // Replace *text* with <b>text</b>
  }

  showAllOptions() {
    this.showAllVariables = !this.showAllVariables;
  }

  parseButtons(input: string): {
    quickReplyButtons: string[], urlButtons: { text: string, urlType: string, url: string }[],
    callButtons: { text: string, url: string }[]
  } {
    const quickReplyButtons: string[] = [];
    const urlButtons: { text: string, urlType: string, url: string }[] = [];
    const callButtons: { text: string, url: string }[] = [];

    // Parse the input string as JSON
    const inputData = JSON.parse(input);

    // Assuming buttons field is present in the input JSON
    const { buttons } = inputData;


    if (buttons) {
      buttons.forEach((button: any) => {
        if (button.type === 'QUICK_REPLY') {
          // Add to quickReplyButtons if type is QUICK_REPLY
          quickReplyButtons.push(button.text.trim());
        } else if (button.type === 'URL') {
          // Add to urlButtons if type is URL
          urlButtons.push({ text: button.text.trim(), urlType: "static", url: button.url.trim() });
        } else if (button.type === 'PHONE_NUMBER') {
          // Add to urlButtons if type is URL
          callButtons.push({ text: button.text, url: button.phone_number });
        }
      });
    }

    return { quickReplyButtons, urlButtons, callButtons };
  }

  handleOpenEditDialog(content: any, template: any) {
    this.templateToEdit = template;
    this.modalService.open(content, { size: 'xl', scrollable: true, centered: true });
  }

}
