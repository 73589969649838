import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(private http: HttpClient) { }

  getLeadsRocord(data:any): Observable<any> {
    return this.http.post(environment.getLeadsRocord,data);
  }

  getAllTemplate(data:any): Observable<any> {
    return this.http.post(environment.getAllEmaiTemplate,data);
  }

  getAppId(data:any): Observable<any> {
    return this.http.post(environment.getAppId,data);
  }

  createTemplate(data:any): Observable<any> {
    return this.http.post(environment.createEmailTemplate,data);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('client_id', '1');
    const url= "https://x3aqazagq4tslb6b3mirjtjmc40rxjql.lambda-url.us-east-1.on.aws"
    return this.http.post(url, formData);
  }


  getAllLanguages(): Observable<any> {
    return this.http.post(environment.getAllLanguages,{});
  }

  updateTemplate(data:any): Observable<any> {
    return this.http.post(environment.updateEmailTemplate,data);
  }

  deleteTemplate(data:any): Observable<any> {
    return this.http.post(environment.deleteEmailTemplate,data);
  }
  
}
