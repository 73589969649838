import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TicketService {

    constructor(private http: HttpClient) { }

    addTicketData(data:any): Observable<any> {
      return this.http.post(environment.createTicket,data);
    }

    updateTicket(data:any): Observable<any> {
      return this.http.post(environment.updateTicket,data);
    }

    getAllTinaTicket(data:any): Observable<any> {
      return this.http.post(environment.getAllTinaTicket,data);
    }

    deleteTicket(data:any): Observable<any> {
      return this.http.post(environment.deleteTicket,data);
    }

    updateBulkTicket(data:any): Observable<any> {
      return this.http.post(environment.updateBulkTicket,data);
    }

    addTicketComment(data:any): Observable<any> {
      return this.http.post(environment.addTicketComment,data);
    }

    getAllTicketComments(data:any): Observable<any> {
      return this.http.post(environment.getAllTicketComments,data);
    }

    updateTicketComment(data:any): Observable<any> {
      return this.http.post(environment.updateTicketComment,data);
    }













  
 
    getTinaTicketHistory(data:any):Observable<any> {
      return this.http.post(environment.getTinaTicketHistory,data);
    }
    getAllZendeskTicket(data:any): Observable<any> {
        return this.http.post(environment.getAllZendeskTicket,data);
      }
    
    getUser(data:any): Observable<any> {
      return this.http.post(environment.getUsers,data);
    }
    getLead(data:any): Observable<any> {
      return this.http.post(environment.getLead,data);
    }

  
    updateTicketHistory(data:any): Observable<any> {
      return this.http.post(environment.updateTicketHistory,data);
    }
    createBulkTicketHistory(data:any): Observable<any> {
      return this.http.post(environment.createBulkTicketHistory,data);
    }
    addLeadFollowUp(data:any): Observable<any> {
      return this.http.post(environment.createleadsfollowup,data);
    }
    
    
    
  }