import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TicketService } from 'src/app/private/services/ticket.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent {

  @ViewChild('chatContainer') chatContainer!: ElementRef;

  @Input() selectedTicketForActivity: any;
  @Output() close = new EventEmitter<void>();
  @Output() refresh = new EventEmitter<void>();
  @Input() source: any;
  @Input() curentUser: any;
  @Input() users: any[] = [];
  initialLoading: boolean = false;
  selectedMessage: any;

  editInputValue: string = '';

  constructor(private toast: ToastrService, private api: TicketService, private modalService: NgbModal, private localStorage: LocalStorageService
  ) {

  }


  closeModal() {
    this.close.emit();
  }

  ngOnInit(): void {
    this.getComments();
    this.scrollToBottom();
  }

  getComments() {

    const payload = {
      ticket_id: this.selectedTicketForActivity.ticket_id,
    }

    this.initialLoading = true;

    this.api.getAllTicketComments(payload).subscribe(
      res => {
        console.log("ress", res);
        this.messages = res;

        setTimeout(() => {
          this.scrollToBottom();

        }, 100);

        this.initialLoading = false;
      },
      err => {
        this.initialLoading = false;
        this.toast.error('Failed to fetch comments');
      }
    );
  }

  btnLoading: boolean = false;

  messages: any[] = [];
  inputValue: string = '';

  onSubmit(): void {
    if (!this.inputValue.trim()) return;

    const newMessage: any = {
      id: Date.now(),
      content: this.inputValue,
      timestamp: new Date().toLocaleString(),
      sender: this.curentUser?.fname + ' ' + this.curentUser?.lname,
    };

    this.messages.push(newMessage);
    this.inputValue = '';

    setTimeout(() => {
      this.scrollToBottom();
    }, 500);

    const paylaod = {
      comment_text: newMessage.content,
      ticket_id: this.selectedTicketForActivity.ticket_id,
      user_id: this.curentUser.user_id,
      client_id: this.localStorage.getClientId(),
      source: this.source,
      priority: this.selectedTicketForActivity.priority,
      ticket_status: this.selectedTicketForActivity.ticket_status,
      title: this.selectedTicketForActivity.title,
      assign_to: this.selectedTicketForActivity.assign_to,
      ticket_desc: this.selectedTicketForActivity.ticket_desc,
      attachments: this.selectedTicketForActivity.attachments,
      assignee_detail: {
        assignee_id: this.selectedTicketForActivity.assign_to || "",
        email: this.users.find(user => user?.user_id === this.selectedTicketForActivity.assign_to)?.email || "",
        name: this.users.find(user => user?.user_id === this.selectedTicketForActivity.assign_to)
          ? `${this.users.find(user => user?.user_id === this.selectedTicketForActivity.assign_to)?.fname || ""} ${this.users.find(user => user?.user_id === this.selectedTicketForActivity.assign_to)?.lname || ""}`.trim()
          : ""
      },
      updated_by: {
        user_id: this.localStorage.getUserId(),
        name: this.localStorage.getProfileName(),
        email: this.localStorage.getEmail()
      },
      created_by: this.selectedTicketForActivity.created_by,

    }

    this.btnLoading = true;

    this.api.addTicketComment(paylaod).subscribe(
      res => {
        this.btnLoading = false;
        // this.refresh.emit();
      },
      err => {
        this.btnLoading = false;
        this.toast.error('Failed to add comment');
      }
    );

  }

  getInitials(name: string): string {
    if (!name) {
      return '';
    }

    // Split the name into parts
    const nameParts = name.trim().split(' ');

    // Get the first letter of the first two parts only
    const initials = nameParts
      .slice(0, 2) // Take only the first two parts
      .map(part => part[0]?.toUpperCase()) // Get the first letter of each part
      .join('');

    return initials;
  }

  isStatusOrPriorityChanged(currentMessage: any, previousMessage: any): boolean {
    const statusChanged = currentMessage.ticket_status && currentMessage.ticket_status !== previousMessage?.ticket_status;
    const priorityChanged = currentMessage.priority && currentMessage.priority !== previousMessage?.priority;
    return statusChanged || priorityChanged;
  }

  handleOpenEditModal(content: any, message: any): void {
    this.modalService.open(content, { centered: true });
    this.selectedMessage = message;
    this.editInputValue = message.comment_text;
  }

  handleUpdateTicketComment(): void {
    if (!this.editInputValue.trim()) return;

    this.selectedMessage.content = this.editInputValue;

    const paylaod = {
      id: this.selectedMessage.id,
      comment_text: this.editInputValue,
      ticket_id: this.selectedTicketForActivity.ticket_id,
      user_id: this.curentUser.user_id,
      client_id: this.localStorage.getClientId(),
      source: this.source,
      priority: this.selectedTicketForActivity.priority,
      ticket_status: this.selectedTicketForActivity.ticket_status,
      title: this.selectedTicketForActivity.title,
      assign_to: this.selectedTicketForActivity.assign_to,
      ticket_desc: this.selectedTicketForActivity.ticket_desc,
      attachments: this.selectedTicketForActivity.attachments,
      assignee_detail: {
        assignee_id: this.selectedTicketForActivity.assign_to || "",
        email: this.users.find(user => user?.user_id === this.selectedTicketForActivity.assign_to)?.email || "",
        name: this.users.find(user => user?.user_id === this.selectedTicketForActivity.assign_to)
          ? `${this.users.find(user => user?.user_id === this.selectedTicketForActivity.assign_to)?.fname || ""} ${this.users.find(user => user?.user_id === this.selectedTicketForActivity.assign_to)?.lname || ""}`.trim()
          : ""
      },
      created_by: this.selectedTicketForActivity.created_by,
      updated_by: {
        user_id: this.localStorage.getUserId(),
        name: this.localStorage.getProfileName(),
        email: this.localStorage.getEmail()
      },

    }

    this.btnLoading = true;

    console.log("paylaod", paylaod);

    this.api.updateTicketComment(paylaod).subscribe(
      res => {
        this.btnLoading = false;
        // this.modalService.dismissAll();
        this.toast.success('Comment updated successfully');
      },
      err => {
        this.btnLoading = false;
        this.toast.error('Failed to update comment');
      }
    );
  }

  hasAttachmentValueChanged(previousMessage: any,
    currentMessage: any
  ): boolean {

    if (!previousMessage.attachments && currentMessage.attachments) {
      return true;
    }

    if (previousMessage.attachments && !currentMessage.attachments) {
      return true;
    }

    if (!previousMessage.attachments && !currentMessage.attachments) {
      return false;
    }

    if (previousMessage.attachments && currentMessage.attachments) {

      if (previousMessage.attachments.length !== currentMessage.attachments.length) {
        return true;
      }

      for (const attachment of previousMessage.attachments) {
        const found = currentMessage.attachments.find((currentAttachment: any) => {
          return currentAttachment === attachment;
        });

        if (!found) {
          return true;
        }
      }


    }


    return false;

  }


  scrollToBottom(): void {
    console.log("scrolling to bottom");
    try {
      this.chatContainer.nativeElement.scrollTo({
        top: this.chatContainer.nativeElement.scrollHeight,
        behavior: 'smooth' // Enables smooth scrolling
      });
    } catch (err) {
      console.error('Scroll to bottom failed:', err);
    }
  }
  

  nothingChanged(currentMessage: any, previousMessage: any): boolean {

    if (previousMessage.comment_text != currentMessage.comment_text) {
      return false;
    }

    if (previousMessage.ticket_status !== currentMessage.ticket_status) {
      return false;
    }

    if (previousMessage.priority !== currentMessage.priority) {
      return false;
    }

    if (previousMessage.assign_to !== currentMessage.assign_to) {
      return false;
    }

    if (previousMessage.ticket_desc !== currentMessage.ticket_desc) {
      return false;
    }

    // title
    if (previousMessage.title !== currentMessage.title) {
      return false;
    }

    // source
    if (previousMessage.source !== currentMessage.source) {
      return false;
    }

    if (this.hasAttachmentValueChanged(previousMessage, currentMessage)) {
      return false;
    }

    return true;


  }


}
