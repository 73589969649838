import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preview-whatsapp-template',
  templateUrl: './preview-whatsapp-template.component.html',
  styleUrls: ['./preview-whatsapp-template.component.scss']
})
export class PreviewWhatsappTemplateComponent {

    @Input() whatsappPreviewTemplate: any;

    templateType: any = '';
    mediaId: any = '';
    header: any = '';
    footer: any = '';
    quickReplyButtons: any = [];
    urlButtons: any = [];
    callButtons: any = [];
    countQuickReplyButton: any = 0;
    countCTAButtonForURL: any = 0;
    renderedData: any = '';
    template: any = '';
    currentTime: any = '';
    timerId: any;
    showAllVariables: any = false;



    ngOnInit(): void {
      this.handleOpenPreviewDialog(this.whatsappPreviewTemplate);
      this.template = this.whatsappPreviewTemplate;
      this.updateTime();
      this.timerId = setInterval(() => this.updateTime(), 60000); // Update every minute
    }

    updateTime() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
      this.currentTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    handleOpenPreviewDialog(template: any) {
      this.templateType = template.templateType;
  
      if (template.templateType === 'IMAGE' || template.templateType === 'VIDEO' || template.templateType === 'DOCUMENT') {
        const data = JSON.parse(template.meta);
        this.mediaId = data.mediaId;
      }
      const { header, body, footer } = this.splitString(template.containerMeta);
      this.header = header;
      this.footer = footer;
      const { quickReplyButtons, urlButtons, callButtons } = this.parseButtons(template.containerMeta);
  
      this.quickReplyButtons = quickReplyButtons.map((button: string) => ({ value: button }));
  
      this.urlButtons = urlButtons.map((button: any) => ({ title: button.text, urlType: button.urlType, value: button.url }));
  
      this.callButtons = callButtons.map((button: any) => ({ title: button.text, value: button.url }));
  
      this.countQuickReplyButton = this.quickReplyButtons.length;
  
      this.countCTAButtonForURL = this.urlButtons.length;
  
      this.renderedData = this.formateData(body);
  
    }

    formateData(data: any) {
      return data
        .replace(/\n/g, '<br>') // Replace \n with <br>
        .replace(/\*(.*?)\*/g, '<b>$1</b>'); // Replace *text* with <b>text</b>
    }
  
    parseButtons(input: string): {
      quickReplyButtons: string[], urlButtons: { text: string, urlType: string, url: string }[],
      callButtons: { text: string, url: string }[]
    } {
      const quickReplyButtons: string[] = [];
      const urlButtons: { text: string, urlType: string, url: string }[] = [];
      const callButtons: { text: string, url: string }[] = [];
  
      // Parse the input string as JSON
      const inputData = JSON.parse(input);
  
      // Assuming buttons field is present in the input JSON
      const { buttons } = inputData;
  
  
      if (buttons) {
        buttons.forEach((button: any) => {
          if (button.type === 'QUICK_REPLY') {
            // Add to quickReplyButtons if type is QUICK_REPLY
            quickReplyButtons.push(button.text.trim());
          } else if (button.type === 'URL') {
            // Add to urlButtons if type is URL
            urlButtons.push({ text: button.text.trim(), urlType: "static", url: button.url.trim() });
          } else if (button.type === 'PHONE_NUMBER') {
            // Add to urlButtons if type is URL
            callButtons.push({ text: button.text, url: button.phone_number });
          }
        });
      }
  
      return { quickReplyButtons, urlButtons, callButtons };
    }

    splitString(inputString: any) {

      // Parse the input string as JSON
      const inputData = JSON.parse(inputString);
  
      // Extract the header, body (data), and footer from the JSON object
      const { header, data: body, sampleText, footer } = inputData;
  
      return {
        header: header,
        body: this.updateSampleText(sampleText),
        footer: footer
      };
    }

    updateSampleText(input: string): string {
      return input.replace(/\[(.*?)\]/g, '$1');
    }

    showAllOptions() {
      this.showAllVariables = !this.showAllVariables;
    }
  
  
}
