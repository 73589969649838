import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { ContactGrouopService } from 'src/app/private/services/contact-groups.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

export interface DataElement {
  id: number;
  name: string;
}


@Component({
  selector: 'app-add-contacts-group',
  templateUrl: './add-contacts-group.component.html',
  styleUrls: ['./add-contacts-group.component.scss']
})


export class AddContactsGroupComponent {


  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  @Input() selectedGroupId: any;


  totalRows = 0;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentData = [];
  currentContactData = [];


  totalContactRows = 0;
  currentContactPage = 0;
  pageContactSize = 10;
  @ViewChild(MatPaginator)
  paginatorContact!: MatPaginator;

  initialLoad = true;
  initialContactLoad = true;

  isSubmitted = false;
  btnLoading = false;
  selectedIds: number[] = [];

  constructor(
    private toast: ToastrService,
    private api: ContactGrouopService,
    private localStorage: LocalStorageService,
    private userApi: UsersService,
    private cdr: ChangeDetectorRef
  ) {

  }

  displayedColumns = ['select', 'name', 'email', 'phone'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  selectedLeadIds: number[] = [];
  isAllLeadsSelected = false;

  dataSource2: MatTableDataSource<any> = new MatTableDataSource();
  selection2 = new SelectionModel<any>(true, []);
  selectedContactIds: number[] = [];
  isAllContactsSelected = false;


  GroupListData: any = [];

  ngOnInit() {
    this.GroupListData = [];
    this.getLeadAndContactId();
  }

  // this function is used to get the lead and contact id from the contact group which are alredy added
  getLeadAndContactId() {
    try {
      const payload = {
        contact_group_id: this.selectedGroupId
      }
      this.api.getLeadAndContactId(payload).subscribe(
        (response) => {
          this.GroupListData = response;
          this.GroupListData.forEach((element: any) => {
            if (element.lead_id) {
              this.selectedLeadIds.push(element.lead_id);
            }
          });
          this.isAllSelected();
        },
        (error) => {
          console.log(error);
          this.toast.error("Error while fetching contact group");
        }
      );

    } catch (e) {
      console.error(e);
    }
  }

  ngAfterViewInit() {
    this.getLeads();
    this.getAllContacts();
  }


  // this function is used to get the contacts from the api which are alredy added to the contact group
  getAllContacts() {
    try {
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.paginatorContact.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            const param = {
              "where": {
                "client_id": this.localStorage.getClientId()
              },
              "page": this.paginatorContact.pageIndex,
              "limit": this.paginatorContact.pageSize,

            }

            return this.api
              .getAllContacts(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalContactRows = response.count;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource2 = new MatTableDataSource<any>(data);
            this.initialContactLoad = false;
            this.isAllSelected2();
            this.currentContactData = data;


          },
          error: (e) => {
            console.error(e);
            this.toast.error("Error while fetching leads");
            this.initialContactLoad = false;
          },
          complete: () => {
          },
        });
    } catch (e) {
      console.error(e);
    }
  }

  // this function is used to get the leads from the api which are alredy added to the leads table
  getLeads() {
    try {
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {

            const param = {
              "where": {
                "client_id": this.localStorage.getClientId(),

              },
              "filter": null,
              "page": this.paginator.pageIndex,
              "limit": this.paginator.pageSize,
              "order": {
                "col": "\"leadsrecord\".\"updatedAt\"",
                "dir": "DESC"
              }
            }

            return this.api
              .getAllLeads(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }
            this.totalRows = response.count;
            return response.rows;
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = new MatTableDataSource<any>(data);
            this.currentData = data;
            this.initialLoad = false;

            this.isAllSelected();
          },
          error: (e) => {
            console.error(e);
            this.toast.error("Error while fetching leads");
            this.initialLoad = false;
          },
          complete: () => {
          },
        });
    } catch (e) {
      console.error(e);
    }
  }


  // this function is used to check if all the leads are selected
  isAllSelected() {

    if (this.initialLoad) {
      return;
    }

    if (this.currentData.length === 0) {
      return
    }

    const result = this.currentData.every((x: any) => this.selectedLeadIds.includes(
      parseInt(x.lead_id, 10)
    ) || this.selection.isSelected(x) || !!this.GroupListData.find((y: any) => y.lead_id == x.lead_id));

    if (result) {
      this.isAllLeadsSelected = true;
    } else {
      this.isAllLeadsSelected = false;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(event: any) {

    if (this.isAllLeadsSelected) {
      this.selection.clear();

      this.dataSource.data.forEach(row => {
        // remove from selectedLeadIds
        this.selectedLeadIds = this.selectedLeadIds.filter((x) => x != row.lead_id);
      }
      );


      this.isAllLeadsSelected = false;
    } else {
      this.dataSource.data.forEach(row => {
        if (this.selectedLeadIds.includes(
          parseInt(row.lead_id, 10)
        )) {
          return;
        }
        this.selection.select(row);
        this.addToLeadsIds(row);
      });
      this.isAllLeadsSelected = true;
    }


  }


  // this function is used to check if all the contacts are selected
  isAllSelected2() {
    if (this.initialContactLoad) {
      return;
    }

    if (this.currentContactData.length === 0) {
      return
    }

    try {
      const result = this.currentContactData.every((x: any) => this.selectedContactIds.includes(
        parseInt(x.contact_id, 10)
      ) || this.selection2.isSelected(x) || !!this.GroupListData.find((y: any) => y.contact_id == x.contact_id));

      if (result) {
        this.isAllContactsSelected = true;
      } else {
        this.isAllContactsSelected = false;
      }
    } catch (e) {
      console.error("isAllContactsSelected", e);
    }

  }


  // Selects all rows if they are not all selected; otherwise clear selection. for the contacts table
  masterToggle2() {
    if (this.isAllContactsSelected) {
      this.selection2.clear();

      this.dataSource2.data.forEach(row => {
        this.selectedContactIds = this.selectedContactIds.filter((x) => x != row.contact_id);
      }
      );

      this.isAllContactsSelected = false;
    } else {
      this.dataSource2.data.forEach(row => {
        if (this.selectedContactIds.includes(
          parseInt(row.contact_id, 10)
        )) {
          return;
        }
        this.selection2.select(row);
        this.addToContactIds(row);
      });
      this.isAllContactsSelected = true;
    }
  }

  closeModal() {
    this.close.emit();
  }

  // this function is used to submit the form and add the selected contacts  and leads to the contact group
  onSubmit() {

    let contacts: any = []

    this.selection.selected.forEach((element) => {

      if (this.GroupListData.find((x: any) => x.lead_id == element.lead_id)) {
        return;
      }

      contacts.push({
        name: element.contact_name,
        source: element.lead_source,
        phone: element.contact_phone,
        email: element.contact_email,
        lead_id: element.lead_id
      })
    })

    this.selection2.selected.forEach((element) => {

      if (this.GroupListData.find((x: any) => x.contact_id == element.contact_id)) {
        return;
      }

      contacts.push({
        name: element.first_name + " " + element.last_name,
        source: element.source,
        phone: element.phone,
        email: element.email,
        contact_id: element.contact_id
      })
    })

    if (contacts.length === 0) {
      this.toast.error("Please select atleast one contact");
      return;
    }

    this.btnLoading = true;
    const payload = {
      client_id: this.localStorage.getClientId(),
      contact_group_id: this.selectedGroupId || 1,
      contacts: contacts
    }


    this.api.createContactGroupList(payload).subscribe(
      (response) => {
        this.formSubmit.emit();
      },
      (error) => {
        console.log(error);
        this.toast.error("Error while creating contact group");
        this.btnLoading = false;
      }
    );
  }


  // checking for the selected row for the leads
  isRowSelected(row: any): boolean {
    const result = this.selectedLeadIds.includes(
      parseInt(row.lead_id, 10)
    ) || this.selection.isSelected(row) || !!this.GroupListData.find((x: any) => x.lead_id == row.lead_id);

    return result;
  }

  // checking for the selected row for the contacts
  isRowSelected2(row: any): boolean {
    const result = this.selectedContactIds.includes(
      parseInt(row.contact_id, 10)
    ) || this.selection2.isSelected(row
    ) || !!this.GroupListData.find((x: any) => x.contact_id == row.contact_id);
    return result;
  }


  // checking for the disabled row for the leads
  isDisabled(row: any): boolean {
    return !!this.GroupListData.find((x: any) => x.lead_id == row.lead_id);
  }


  // checking for the disabled row for the contacts
  isDisabled2(row: any): boolean {
    return !!this.GroupListData.find((x: any) => x.contact_id == row.contact_id);

  }


  // adding the selected row to the selectedLeadIds for the leads table
  addToLeadsIds(row: any) {

    if (this.selectedLeadIds.includes(row.lead_id)) {
      this.selectedLeadIds = this.selectedLeadIds.filter((x) => x !== row.lead_id);
      return;
    } else {
      this.selectedLeadIds.push(parseInt(row.lead_id, 10));
    }

    this.isAllSelected();
  }


  // adding the selected row to the selectedContactIds for the contacts table
  addToContactIds(row: any) {
    if (this.selectedContactIds.includes(row.contact_id)) {
      this.selectedContactIds = this.selectedContactIds.filter((x) => x !== row.contact_id);
      return;
    } else {
      this.selectedContactIds.push(parseInt(row.contact_id, 10));
    }
    this.isAllSelected2();
  }
}



function observableOf(arg0: null): any {
  console.log(arg0);
  throw new Error('Function not implemented.');
}