import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
interface ColumnMapping {
  [key: string]: string;
}
@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.scss']
})

export class ExcelUploadComponent {
  fileOver: boolean = false;
  show_table: boolean = false;
  upload_count: any = 0;
  lead_owners: any = []
  @Input() lead_space_id: any = ""
  selectedSheetName: string = '';
  @Input() importStatus: boolean = false;

  @Input() displayedColumns?: any = [];
  @Output() handleSubmitData = new EventEmitter<any>();
  @Output("sampleFile") sampleFile: EventEmitter<any> = new EventEmitter();
  @Input() allLeadFeilds: any = [];

  sheetNames: string[] = [];
  columns: string[] = [];
  fields: string[] = ['Field1', 'Field2', 'Field3']; // Add your desired fields here
  workbook: XLSX.WorkBook | null = null;

  mappedColumns: { [key: string]: string } = {}; // Mapping between displayedColumns and Excel columns
  isAdmin: boolean = true; // Example admin flag
  currentUserId: any = ""
  csvData: any = null;

  constructor(private localStorage: LocalStorageService, private userApi: UsersService,
    private modalService: NgbModal
  ) {
    this.getAllUsers()
    this.isAdmin = this.localStorage.checkFullAccessForTableList(
      this.localStorage.getRole()
    );
    this.currentUserId = this.localStorage.getUserId()
  }


  ngOnInit() {
    console.log("allLeadFeilds", this.allLeadFeilds)
    this.displayedColumns = this.displayedColumns.filter(
      (column: any) => column !== 'action' && column !== 'info' && column !== 'assign_to'
    );
  }


  private processExcelFile(file: File): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      this.workbook = XLSX.read(bstr, { type: 'binary' });

      // Extract sheet names
      this.sheetNames = this.workbook.SheetNames;
      const worksheet = this.sheetNames[0];
      const jsonData = XLSX.utils.sheet_to_json(this.workbook.Sheets[worksheet], { header: 1 });

      // Extract the first row as column names
      this.columns = (jsonData[0] as string[]) || [];

      this.autoColumnMapping();
    };
    reader.readAsBinaryString(file);
  }

  private processCsvFile(file: File): void {
    Papa.parse(file, {
      complete: (result) => {
        const jsonData = result.data;

        // Extract the first row as column names
        this.columns = (jsonData[0] as string[]) || [];

        // Process the rest of the data as needed
        console.log('CSV Data:', jsonData);

        this.csvData = jsonData;

        this.autoColumnMapping();
      },
      header: false, // Adjust based on whether the CSV file has a header row
    });
  }

  onFileUpload(event: any): void {
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const file: File = target.files[0];
    const fileType = file.name.split('.').pop()?.toLowerCase();

    if (fileType === 'xlsx') {
      this.processExcelFile(file);
    } else if (fileType === 'csv') {
      this.processCsvFile(file);
    } else {
      throw new Error('Unsupported file type');
    }
  }

  // onFileUpload(event: any): void {
  //   const target: DataTransfer = <DataTransfer>event.target;
  //   if (target.files.length !== 1) throw new Error('Cannot use multiple files');

  //   const reader: FileReader = new FileReader();
  //   reader.onload = (e: any) => {
  //     const bstr: string = e.target.result;
  //     this.workbook = XLSX.read(bstr, { type: 'binary' });

  //     // Extract sheet names
  //     this.sheetNames = this.workbook.SheetNames;
  //     const worksheet = this.sheetNames[0];
  //     const jsonData = XLSX.utils.sheet_to_json(this.workbook.Sheets[worksheet], { header: 1 });

  //     // Extract the first row as column names
  //     this.columns = (jsonData[0] as string[]) || [];

  //     this.autoColumnMapping();
  //   };
  //   reader.readAsBinaryString(target.files[0]);
  // }

  autoColumnMapping(): void {
    // Map the first n columns automatically
    this.displayedColumns.slice(0, this.columns.length).forEach((column: any, index: any) => {
      if (this.columns.includes(this.convertColumnName(column))) {
        this.mappedColumns[column] = this.convertColumnName(column)
      }
    });
  }

  onSheetSelect(event: any): void {
    const selectedSheet = event.target.value;
    this.selectedSheetName = selectedSheet;


    if (this.workbook && selectedSheet) {
      const worksheet = this.workbook.Sheets[selectedSheet];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Extract the first row as column names
      this.columns = (jsonData[0] as string[]) || [];
      this.autoColumnMapping();
    }
  }

  convertColumnName(column: string): string {
    // Match snake_case format and convert to Title Case
    if (/^[a-z]+(_[a-z]+)*$/.test(column)) {
      return column
        .split('_') // Split by underscore
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' '); // Join with space
    }
    // Return the column as is if it doesn't match the format
    return column;
  }

  onSubmit(): void {
    // Check if a workbook and sheet are selected
    if (!this.sheetNames?.length && !this.csvData) {
      console.error("No workbook, sheet, or CSV data found");
      return;
    }

    // Get the selected sheet's data
    // const selectedSheetName = this.selectedSheetName || this.sheetNames[0];
    // const worksheet = this.workbook.Sheets[selectedSheetName];

    // if (!worksheet) {
    //   console.error("Worksheet not found");
    //   return;
    // }

    // Convert sheet data to JSON
    // const sheetData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    let sheetData: any[] = [];

    if (this.workbook) {
      // Handle XLSX file
      const selectedSheetName = this.selectedSheetName || this.sheetNames[0];
      const worksheet = this.workbook.Sheets[selectedSheetName];

      if (!worksheet) {
        console.error("Worksheet not found");
        return;
      }

      // Convert XLSX sheet data to JSON
      sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    } else if (this.csvData) {
      // Handle CSV file
      sheetData = this.csvData;
    }

    // Ensure there is valid sheet data
    if (sheetData.length < 2) {
      console.error("Sheet data is empty or invalid");
      return;
    }

    // Get the header and rows
    const headerRow = sheetData[0]; // First row contains headers
    const dataRows = sheetData.slice(1); // Remaining rows contain data

    // Create an array of mapped data objects
    const mappedData = dataRows.map((row) => {
      const mappedRow: any = {};
      Object.keys(this.mappedColumns).forEach((displayedColumn) => {
        const excelColumn = this.mappedColumns[displayedColumn];
        if (excelColumn) {
          // Find the index of the Excel column in the header row
          const columnIndex = headerRow.indexOf(excelColumn);
          if (columnIndex > -1) {
            // Map the Excel column value to the displayed column
            mappedRow[displayedColumn] = row[columnIndex];
            mappedRow['user_id'] = this.currentUserId;
            mappedRow['lead_space_id'] = this.lead_space_id;
            mappedRow['client_id'] = this.localStorage.getClientId();
            mappedRow['center_id'] = this.localStorage.getCenterId();
            mappedRow['lead_time'] = moment().format('HH:mm');
            mappedRow['lead_date'] = moment().format('YYYY-MM-DD');

          } else {
            console.warn(`Column "${excelColumn}" not found in header`);
          }
        }
      });
      return mappedRow;
    });



    let finalData = this.transformLeads(mappedData)

    let removeDataWhichDoNotContainContactName = finalData.filter((data: any) => data.contact_name != undefined && data.contact_name != "")

    this.handleSubmitData.emit(removeDataWhichDoNotContainContactName);
  }

  transformLeads(leads: any[]): any[] {
    // add fixed column septate
    return leads.map(lead => {
      const { contact_name, contact_phone, contact_email, company_name, lead_type, lead_source, lead_date, lead_time, lead_status, center_id, client_id, country_code, user_id, lead_space_id, ...extraFields } = lead;
      let final_object = { contact_name, contact_phone, contact_email, company_name, lead_type, lead_source, lead_date, lead_time, lead_status, center_id, client_id, country_code, user_id, lead_space_id, fields: extraFields };
      // console.log(final_object)
      return final_object;
    });
  }

  getAllUsers() {
    let param = {
      client_id: this.localStorage.getClientId(),
    };
    this.userApi.findAllUsers(param).subscribe({
      next: (res: any) => {
        this.lead_owners = res || [];
      },
      error: (e: any) => {
        console.error(e);
      },
    });
  }

  handleCloseModel() {
    this.modalService.dismissAll();
  }

  downloadSampleExcel() {
    let col = this.displayedColumns.map((column: any) => this.convertColumnName(column));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    console.log(col)
    XLSX.utils.sheet_add_aoa(ws, [col]);
    XLSX.utils.book_append_sheet(wb, ws, 'Sample Lead');

    XLSX.writeFile(
      wb,
      'sample_data.xlsx'
    );
  }

  toggletable(content: any) {
    this.modalService.open(content, {
      size: 'lg',
      centered: true,

    });
  }

  deactiveTable() {

    if (this.show_table) {
      this.show_table = false;
    }

  }



  // onFileUpload(event: any): void {
  //   const target: DataTransfer = <DataTransfer>(event.target);
  //   if (target.files.length !== 1) throw new Error('Cannot use multiple files');

  //   const reader: FileReader = new FileReader();
  //   reader.onload = (e: any) => {
  //     const bstr: string = e.target.result;
  //     this.workbook = XLSX.read(bstr, { type: 'binary' });

  //     // Extract sheet names
  //     this.sheetNames = this.workbook.SheetNames;

  //     // setup colums for default 1 sheet
  //     const worksheet = this.sheetNames[0];
  //     const jsonData = XLSX.utils.sheet_to_json(this.workbook.Sheets[worksheet], { header: 1 });

  //     // Extract the first row as column names
  //     this.columns = (jsonData[0] as string[]) || [];
  //   };
  //   reader.readAsBinaryString(target.files[0]);
  // }

  // onSheetSelect(event: any): void {
  //   const selectedSheet = event.target.value;

  //   if (this.workbook && selectedSheet) {
  //     const worksheet = this.workbook.Sheets[selectedSheet];
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  //     // Extract the first row as column names
  //     this.columns = (jsonData[0] as string[]) || [];
  //   }
  // }



  // possibleDateFormats: string[] = [
  //   'DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD',
  //   'DD/M/YYYY',
  //   'DD MMM YYYY', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'DD MMMM YYYY'
  // ];
  // // Define the column mapping
  // columnMapping: ColumnMapping = {
  //   'Contact Name': 'contact_name',
  //   'Country Code': 'country_code',
  //   'Contact Number': 'contact_phone',
  //   'Email': 'contact_email',
  //   'Organisation': 'company_name',
  //   'Designation': 'designation',
  //   'Sector': 'sector',
  //   'Sub Sector': 'sub_sector',
  //   'Country': 'country',
  //   'State': 'state',
  //   'City': 'city',
  //   'Lead Type': 'lead_type',
  //   'Lead Status': 'lead_status',
  //   'Source': 'lead_source',
  //   'Additional information': 'notes',
  //   'Deal Size': 'deal_size',
  //   'Website Link': 'website_link',
  //   'Linkedin Link': 'linkedin_link',
  //   'Date': 'lead_date',
  //   'Alternate Email': 'alt_email',
  //   'Alternate Contact': 'alt_contact'

  //   // Add more mappings as needed
  // };
  // currentUserId: any = ""
  // @Output("exportDataEmitter") exportDataEmitter: EventEmitter<any> = new EventEmitter();

  // constructor(private localStorage: LocalStorageService, private userApi: UsersService) {
  //   this.getAllUsers()
  //   this.isAdmin = this.localStorage.checkFullAccessForTableList(
  //     this.localStorage.getRole()
  //   );
  //   this.currentUserId = this.localStorage.getUserId()
  // }
  // onFileDropped($event: any) {
  //   this.fileOver = false;
  //   this.handleFile($event[0]);
  // }
  // toggletable() {
  //   this.show_table = (this.show_table) ? false : true
  // }


  // fileBrowseHandler(event: any) {
  //   const file = event.target.files[0];
  //   this.handleFile(file);
  // }
  // getAllUsers() {
  //   let param = {
  //     client_id: this.localStorage.getClientId(),
  //   };
  //   this.userApi.findAllUsers(param).subscribe({
  //     next: (res: any) => {
  //       this.lead_owners = res || [];
  //     },
  //     error: (e: any) => {
  //       console.error(e);
  //     },
  //   });
  // }
  // handleFile(file: File) {
  //   const fileReader = new FileReader();
  //   fileReader.onload = (e: any) => {
  //     const bufferArray = e.target.result;
  //     const workbook = XLSX.read(bufferArray, { type: 'binary' });
  //     const wsname = workbook.SheetNames[0];
  //     const ws = workbook.Sheets[wsname];
  //     let data: any = XLSX.utils.sheet_to_json(ws, { header: 1 });
  //     //data = data.filter((row: any[]) => row.some(cell => cell != null && cell !== ''));
  //     const mappedData = this.mapColumns(data);

  //     let finalData = this.transformLeads(mappedData)
  //     console.log("finalData", finalData)
  //     this.upload_count = finalData.length
  //     this.exportDataEmitter.emit(finalData)
  //   };
  //   fileReader.readAsArrayBuffer(file);
  // }
  // transformLeads(leads: any[]): any[] {
  //   // add fixed column septate
  //   return leads.map(lead => {
  //     const { contact_name, contact_phone, contact_email, company_name, lead_type, lead_source, lead_date, lead_time, lead_status, center_id, client_id, country_code, user_id, lead_space_id, ...extraFields } = lead;
  //     let final_object = { contact_name, contact_phone, contact_email, company_name, lead_type, lead_source, lead_date, lead_time, lead_status, center_id, client_id, country_code, user_id, lead_space_id, fields: extraFields };
  //     // console.log(final_object)
  //     return final_object;
  //   });
  // }
  // mapColumns(data: any[][]): any[] {
  //   const headers = data[0];
  //   const rows = data.slice(1);

  //   // Map the headers
  //   const mappedHeaders = headers.map(header => this.columnMapping[header] || header);

  //   // Map the rows
  //   const mappedRows = rows.map(row => {
  //     const mappedRow: any = {
  //       client_id: this.localStorage.getClientId(),
  //       center_id: this.localStorage.getCenterId(),
  //       user_id: this.currentUserId,
  //       lead_space_id: this.lead_space_id,
  //       lead_time: moment().format('HH:mm'),
  //       lead_date: moment().format('YYYY-MM-DD'),

  //     };

  //     row.forEach((cell, index) => {

  //       console.log(mappedHeaders[index])
  //       if (typeof cell === 'number' && mappedHeaders[index] == 'lead_date') {
  //         cell = this.formatDate(this.excelDateToJSDate(cell));
  //         mappedRow[mappedHeaders[index]] = cell;


  //       }
  //       else if (typeof cell === 'string' && mappedHeaders[index] == 'lead_date') {
  //         const date = this.parseDate(cell);
  //         console.log("date_dd" + date)
  //         //cell =  moment(cell, 'DD/MM/YYYY').format('YYYY-MM-DD');
  //         mappedRow[mappedHeaders[index]] = date != undefined || date != "" ? date : moment().format('YYYY-MM-DD');


  //       }


  //       else {
  //         mappedRow[mappedHeaders[index]] = cell;
  //       }


  //     });
  //     //console.log()
  //     return mappedRow;
  //   });

  //   //console.log("mappedRows ",mappedRows)
  //   return mappedRows;
  // }
  // parseDate(dateString: string): string | null {
  //   for (const format of this.possibleDateFormats) {
  //     const parsedDate = moment(dateString, format, true);
  //     if (parsedDate.isValid()) {
  //       return parsedDate.format('YYYY-MM-DD');
  //     }
  //   }
  //   return null;
  // }
  // onFileOver(event: any) {
  //   this.fileOver = true;
  // }

  // onFileLeave(event: any) {
  //   this.fileOver = false;
  // }
  // formatDate(date: Date): string {
  //   return moment(date).format('YYYY-MM-DD');
  // }
  // excelDateToJSDate(serial: number): Date {
  //   // Excel date system starts on 1900/1/1 and assumes dates are serial numbers
  //   const startDate = new Date(Date.UTC(1899, 11, 30));
  //   return new Date(startDate.getTime() + serial * 86400000);
  // }

  // filterEmptyRows(data: any[][]): any[][] {
  //   return data.filter(row => row.some(cell => cell !== undefined && cell !== null && cell !== ''));
  // }

}