import { Component,ViewChild } from '@angular/core';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { Toast, ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LeadService } from 'src/app/private/services/lead.service';
import { MatSort } from '@angular/material/sort';
import { FormControl, NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { UploadService } from 'src/app/private/services/upload.service';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent {
  active = 1;
  sender_email = "";
  subscriber_id = "";
  subscriber_name = "";
  subscriber_list = [];
  subscriber_lists = [];
  subject = "";
  email_msg= "";
  email_sender = "";
  template_id = "";
  template_list = [];
  campaign_name = "";
  update_campaign:any = {};

  countryCtrl!: FormControl;
  filteredCountry!: Observable<any[]>;
  mes_type = [{type: "text"},{type: "media"}];
  message_type = ""; 
  country_lis: any = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
  ];

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  btnLoading:boolean = false;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentTab = 'tina';
  formAction = "add";
	closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = ['name','subscriber','campaign_type','date','action'];
  dateObject:any = {startDate:"",endDate:""}
  lead: Lead = new Lead();
  @ViewChild("leadForm")
  leadForm!: NgForm;
  @ViewChild("leadFolloupForm")
  leadFolloupForm!: NgForm;
  leadsFollowList:any = [];
  deleteParams:any = {};
  message = "";

  imgUpload:boolean = false;
  btnUpdate:boolean = false;
  imgTitle:any = "Upload Image"
  file:any ;
  url:any = 'assets/img/not-image.png';
  patient:any = {
    logo: ""
  };

  constructor(private uploadService:UploadService, private toast:ToastrService, private api:LeadService,private modalService: NgbModal, private localStorage:LocalStorageService){

    console.log(this.localStorage.getClientId());
    this.countryCtrl = new FormControl();
    this.filteredCountry = this.countryCtrl.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this.filtercountry(country) : this.country_lis.slice()
      )
    );
  }

  filtercountry(name: string) {
    let arr = this.country_lis.filter(
      (country:any) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );

    return arr.length ? arr : [{ name: 'No Item found', code: 'null' }];
  }
  
  ngAfterViewInit() {
    this.url = this.patient.logo;
    this.loadData();
  }
  ngOnInit(): void {}
  
  searchFilter(query:any)
  {
   this.searchQuery.setValue(query);
  }
  ResetDateFilter(event:any)
  {
    this.dateQuery.setValue("");
  }
  ResetFilter(event:any)
  {
    this.searchQuery.setValue("");
  }
  
  // load Initial table data from based on source type
  loadData() {
    let param = {
      client_id: this.localStorage.getClientId()
      // startDate:this.dateQuery.value.startDate,
      // endDate:this.dateQuery.value.endDate,
      // order:{col:'createdAt',dir:"desc"},
    }
    // if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
    this.api.getAllCampaignList(param).subscribe({
      next:(res: any)=>{
        console.log("campaignList data :",res);
        this.lead = res || {};
        this.dataSource = new MatTableDataSource<any>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error(err) {
        console.log("err : ",err);
      },
    })
  }

  runCampaign(element:any){
    console.log("element",element);
    let param = {
      id: element.subscriber_id
      // startDate:this.dateQuery.value.startDate,
      // endDate:this.dateQuery.value.endDate,
      // order:{col:'createdAt',dir:"desc"},
    }
    this.api.getSubsciberList(param).subscribe({
      next:(res: any)=>{
        console.log("campaignList data :",res);
        this.sendMsg(res.subscriber_phones, element.campaign_msg);
      },
      error(err) {
        console.log("err : ",err);
      },
    })


  }

  sendMsg(contacts:any, msg:any) {
    let param = {
        "contacts": contacts,
        "appName": "gravitasWhatsappApp",
        "clientid": "1234",   //this.localStorage.getClientId()
        "source": "917834811114",
        "message": msg
    };
    console.log("parms msg : ",param);
    this.api.sendWhatsappMsg(param).subscribe({
      next:(res: any)=>{
        console.log("campaignList data :",res);
      },
      error(err) {
        console.log("err : ",err);
      },
    })
  }

  sendMediaMsg(contacts:any) {
    let param = {};
    this.api.sendWhatsappMsg(param).subscribe({
      next:(res: any)=>{
        console.log("campaignList data :",res);
      },
      error(err) {
        console.log("err : ",err);
      },
    })
  }

  testMessageTemplate(){
    let param =  {
      "contactInfo":[
        {
          template: '{"id":"d2bf7863-959c-4e32-8bdc-4421728c99ac","params":["Amit","22-11-2023","15:00"]}',
          contacts: "7021831821"
        }
    ],
      "appName": "tinagravitasbot",
      "source": "919289231601"
    };
    this.api.sendTextTemplateMsg(param).subscribe({
      next:(res: any)=>{
        console.log("text template response :",res);
      },
      error(err) {
        console.log("err : ",err);
      },
    })
  }

  
  testMediaTemplate() {
    let param = {
      "contactInfo":[{
        template: '{"id":"d2bf7863-959c-4e32-8bdc-4421728c99ac","params":["hi","sonalika"]}',
        contacts: "9315711929",
        message: '{"type":"image","image":{"link":"https://www.buildquickbots.com/whatsapp/media/sample/jpg/sample01.jpg"}}'
      },
      {
           template: '{"id":"d2bf7863-959c-4e32-8bdc-4421728c99ac","params":["hi","Chetan"]}',
           contacts: "7021831821",
           message: '{"type":"image","image":{"link":"https://www.buildquickbots.com/whatsapp/media/sample/jpg/sample01.jpg"}}'
       }],
       "appName": "tinagravitasbot",
       "source": "919289231601"
    };
    this.api.sendMediaTemplateMsg(param).subscribe({
      next:(res: any)=>{
        console.log("media template response :",res);
      },
      error(err) {
        console.log("err : ",err);
      },
    })
  }

  createCampaign() {
    this.isSubmitted = true
      this.btnLoading = true;
      let params = {
        client_id: this.localStorage.getClientId(),
        campaign_name: this.campaign_name,
        subscriber_id: this.subscriber_id,
        campaign_type: this.message_type,
        campaign_msg: this.message
      }
      console.log("camp params : ",params);
      this.api.addCampaignList(params).subscribe({
        next:(c) =>{
          this.modalService.dismissAll();
          // clear data
          this.campaign_name = "",
          this.subscriber_id = "",
          this.subscriber_name = "";
          this.message = "";
          this.template_id = "";
          this.message_type = "";
          this.loadData();
          this.btnLoading = false;
          this.isSubmitted = false;
          this.toast.success("Campaign List Added Successfully","Success!!");
        },
        error:(e) =>{
          this.btnLoading = false;
          this.isSubmitted = false
        },
      })
  }

  createMediaCampaign(){
    this.isSubmitted = true
      this.btnLoading = true;
      let params = {
        client_id: this.localStorage.getClientId(),
        campaign_name: this.campaign_name,
        subscriber_id: this.subscriber_id,
        campaign_type: this.message_type,
        campaign_msg: this.message,
        campaign_img: "{'type':'image','image':{'link':"+"'"+this.patient.logo+"'}}"
      }
      console.log("camp params : ",params);
      this.api.addCampaignList(params).subscribe({
        next:(c) =>{
          this.modalService.dismissAll();
          // clear data
          this.campaign_name = "",
          this.subscriber_id = "",
          this.subscriber_name = "";
          this.message = "";
          this.template_id = "";
          this.message_type = "";
          this.loadData();
          this.btnLoading = false;
          this.isSubmitted = false;
          this.patient.logo = "";
          this.toast.success("Campaign List Added Successfully","Success!!");
        },
        error:(e) =>{
          this.btnLoading = false;
          this.isSubmitted = false
        },
      })
  }

  createEmailCampaign(){
    this.isSubmitted = true;
    this.btnLoading = true;
    let params = {
      client_id: this.localStorage.getClientId(),
      campaign_name: this.campaign_name,
      subscriber_id: this.subscriber_id,
      campaign_type: "email",
      campaign_msg: this.message,
      campaign_subject: this.subject
    }
    console.log("camp params : ",params);
    this.api.addCampaignList(params).subscribe({
      next:(c) =>{
        this.modalService.dismissAll();
        // clear data
        this.campaign_name = "",
        this.subscriber_id = "",
        this.subscriber_name = "";
        this.message = "";
        this.subject = "";
        this.email_msg = "";
        this.template_id = "";
        this.message_type = "";
        this.loadData();
        this.btnLoading = false;
        this.isSubmitted = false;
        this.patient.logo = "";
        this.toast.success("Campaign List Added Successfully","Success!!");
      },
      error:(e) =>{
        this.btnLoading = false;
        this.isSubmitted = false
      },
    })
  }

  openUpdateCampaign(content:any, element: any) {
    console.log("element : ",element);
    this.update_campaign = element;
    this.campaign_name = element.campaign_name;
    this.subscriber_id = element.subscriber_id;
    this.message_type = element.campaign_type;
    this.message = element.campaign_msg;
    this.modalService.open(content, {  size: 'lg' , scrollable: false });
    this.loadSubscriberList();
  }

  updateCampaign() {
    this.isSubmitted = true
    this.btnLoading = true;
    this.update_campaign.campaign_name = this.campaign_name;
    this.update_campaign.subscriber_id = this.subscriber_id;
    this.update_campaign = this.message_type;
    this.update_campaign = this.message;
    this.api.updateCampaignList(this.update_campaign).subscribe({
      next:(c) =>{
        this.modalService.dismissAll();
        // clear data
        this.campaign_name = "",
        this.subscriber_id = "",
        this.subscriber_name = "";
        this.message = "";
        this.template_id = "";
        this.message_type = "";
        this.loadData();
        this.btnLoading = false;
        this.isSubmitted = false;
        this.toast.success("Campaign List Updated Successfully","Success!!");
      },
      error:(e) =>{
        this.btnLoading = false;
        this.isSubmitted = false
      },
    })
  }
  
  closeCampaignUpdate() {
    this.campaign_name = "";
    this.subscriber_id = "";
    this.message_type = "";
    this.message = "";
    this.modalService.dismissAll();
  }

  dateFilter(event:any)
  {
  this.dateQuery.setValue(event);
  }

  isAllSelected() {
    return this.dataSource.data.every((item) => item.isSelected);
  }

  isAnySelected() {
    return this.dataSource.data.some((item) => item.isSelected);
  }

  selectAll(event:any) {
    this.dataSource.data = this.dataSource.data.map((item) => ({
      ...item,
      isSelected: event.checked,
    }));
  }

  exportSlected() {
    //let users: any = [];
    //let users = this.dataSource.data.filter((u: Lead) => u.isSelected);
    //   if(u.isSelected){
    //     users.push(
    //       {
    //         name: u.fname,
    //         email: u.email
    //       }
    //     ); 
    //   }
    // });
  }

  deleteList() {
    this.btnLoading = true;
    let params = {
      client_id: this.localStorage.getClientId(),
      campaign_name: this.campaign_name,
      subscriber_id: this.subscriber_id,
    }
    this.api.addCampaignList(params).subscribe({
      next:(res:any)=>{
        this.modalService.dismissAll();
        this.btnLoading = false;
        this.loadData();
        this.deleteParams = {};
        this.toast.success("Subscriber list deleted Successfully !!")
      },
      error:(e: any)=>{
        this.btnLoading = false;
        this.isSubmitted = false
      }
    })
  }

  subscriberOpen(content:any) {
    console.log("hello");
    this.modalService.open(content, {  size: 'lg' , scrollable: false });
    this.loadSubscriberList();
    // this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
    //   if (result === 'yes') {  
    //     let param = {
    //       id: item.id,
    //       client_id: item.client_id
    //     }
    //     this.api.deleteSubscriberList(param).subscribe({
    //       next:(res:any)=>{
    //         this.modalService.dismissAll();
    //         this.loadData()
    //         this.toast.success("Subscriber list deleted Successfully !!")
    //       },
    //       error:(e: any)=>{
    
    //       }
    //     })
    //   }  
    // }, (reason) => {  

    // });  
  }
  open(a:any) {

  }
  openList(b:any){

  }

  loadSubscriberList() {
    let param = {
      where:{client_id: this.localStorage.getClientId()},
      // startDate:this.dateQuery.value.startDate,
      // endDate:this.dateQuery.value.endDate,
      order:{col:'createdAt',dir:"desc"},
    }
    // if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
    this.api.getAllSubscriberList(param).subscribe({
      next:(res: any)=>{
        console.log("customer data :",res);
        this.subscriber_list = res.rows || {};
        const list = res.rows.reduce((res: any, item: any) => {
          res.push({ subscriber_name: item.subscriber_name, id: item.id });
          return res;
        }, []);
        this.subscriber_lists = list || {};
        console.log('ssdsds : ',this.subscriber_lists);
        this.loadTemplate();
      },
      error(err) {
        console.log("err : ",err);
      },
    })
  }

  loadTemplate() {
    let param = 
    {
        "appName": "gravitasWhatsappApp"
    }
    console.log("paara templa : ",param);
    // if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
    this.api.getAllTemplates(param).subscribe({
      next:(res: any)=>{
        console.log("template data :",res);
        this.template_list = res.body.templates;
        // this.subscriber_list = res.rows || {};
        // const list = res.rows.reduce((res: any, item: any) => {
        //   res.push({ subscriber_name: item.subscriber_name, id: item.id });
        //   return res;
        // }, []);
        // this.subscriber_lists = list || {};
        // console.log('ssdsds : ',this.subscriber_lists);
      },
      error(err) {
        console.log("err : ",err);
      },
    })
  }

  onFileSelected(event:any)
  {

    const files = event.target.files;
    if (files.length === 0)
        return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
        this.toast.error('Only images are supported.');
        return;
    }

    const reader = new FileReader();
    this.file = files[0];
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
        this.patient.logo = reader.result; 
    }
  
  }


}

export class Lead {
  name: string = "";
  platform: string = "";
  client_id:string = "";
  total: string = "";
  date: string = "";
  status:string = "";
}